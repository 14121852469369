import SockJS from 'sockjs-client';
import { Client, Message, StompSubscription } from '@stomp/stompjs';

interface Topics {
  name: string;
  subscriber?: StompSubscription; // object returned when you subscribe
  callback: (message: Message) => void; // custom function to allow you to tap into the subscriber callback
}

/**
 * Class to manage sockets
 */
export class SocketConnection {
  private baseUrl: string;

  public client: Client;

  /**
   * Keep a list of topics we are subscribed to. Helps with auto reconnecting
   * and allows you to subscribe outside the onConnect callback.
   * The subscribe and unsubscribe functions handle adding and removing topics.
   */
  public topics: Topics[] = [];

  public stompError: any;

  /**
   * Set base url and base websocket connection
   * SockJS is a type of websocket that works with old browsers
   * @param baseUrl
   */
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;

    this.client = new Client({
      webSocketFactory: () => {
        return new SockJS(this.baseUrl);
      },
    });
  }

  public connect(id: string | undefined, jwt: string, userId?: number) {
    const sessionId = id || '';
    this.client.connectHeaders = { 'clab-session-id': sessionId, 'clab-session-token': `${jwt}`, 'clab-user-id': `${userId}` };
    this.client.onConnect = () => {
      // On initial connection and auto reconnecting, subscribe to all `Topics[]` available
      this.subscribeAll();
    };

    this.client.debug = () => {
      // this.client.debug = (str: string) => {
      // eslint-disable-next-line no-console
      // console.log('Debugging: ', str);
    };

    this.client.onStompError = (error: any) => {
      this.stompError = error;
      throw error;
    };

    // Start the actual connection, nothing is called until this runs.
    this.client.activate();
  }

  private subscribeAll() {
    this.topics.forEach((topic, index) => {
      const subscriber = this.client.subscribe(topic.name, (message: Message) => topic.callback(message));
      this.topics[index].subscriber = subscriber;
    });
  }

  public subscribe(name: string, callback: (message: Message) => void) {
    const foundTopic = this.topics.find((topic) => topic.name === name);
    const { name: topicName } = foundTopic || {};

    if (!topicName && this.isConnected()) {
      const subscriber = this.client.subscribe(name, (message: any) => callback(message));
      this.topics.push({ name, subscriber, callback });
    } else if (!topicName) {
      this.topics.push({ name, callback });
    }
  }

  public unsubscribe(name: string) {
    const foundTopic = this.topics.find((topic) => topic.name === name);
    // remove topic from topics array
    this.topics = this.topics.filter((topic) => topic.name !== name);

    if (foundTopic?.subscriber?.id && this.isConnected()) {
      foundTopic?.subscriber?.unsubscribe();
    }
  }

  /**
   * Disconnect from socket and clear topics
   */
  public disconnect() {
    console.log(new Date(), 'INSIDE SOCKET CONNECTION DISCONNECT');
    this.client.deactivate();
    this.topics = [];
  }

  /**
   * Check if socket is connected
   * @returns boolean
   */
  public isConnected(): boolean {
    return this.client.connected;
  }
}

const clabSocket = new SocketConnection(`${process.env.REACT_APP_CLAB_API_URL}/clab`);

/**
 * Add new global sockets with different connection url here and export them.
 * If it's not global, just import SocketConnection and use it within your component as shown above.
 */

export default clabSocket;
