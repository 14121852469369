/* This example requires Tailwind CSS v2.0+ */
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';

interface LeftSlideOverProps {
  children: ReactNode | ReactNode[];
  close: () => void;
  isOpen: boolean;
  large?: boolean;
  darkBg?: boolean;
}

const LeftSlideOver = ({ children, close, isOpen, large = false, darkBg = false }: LeftSlideOverProps) => {
  const widthClasses = large ? 'max-w-2xl' : 'max-w-md';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" style={{ zIndex: 1000 }} onClose={close}>
        <div className="absolute inset-0 overflow-hidden">
          {!darkBg && <Dialog.Overlay className="absolute inset-0" />}
          {!!darkBg && (
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-900/50 transition-opacity" />
            </Transition.Child>
          )}

          <div className="fixed inset-y-0 left-0 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className={`w-screen ${widthClasses}`}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity duration-1000"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <button type="button" className="text-white absolute top-14 -right-8 w-12 h-12 bg-neutral-900 rounded focus:outline-none" onClick={close}>
                    <span className="sr-only">Close</span>
                    <ArrowCircleLeftIcon className="h-8 w-8 ml-2" />
                  </button>
                </Transition.Child>
                <div className="flex h-full flex-col overflow-y-scroll bg-neutral-900 pb-6 shadow-xl scrollbar-hide">
                  <div className="relative flex-1 px-6">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LeftSlideOver;
