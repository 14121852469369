const SmallButton = ({ onClick, children, href, danger = false }) => {
  const colorClasses = danger ? 'bg-red-600 hover:bg-red-500 text-red-50' : 'bg-gray-100 hover:bg-gray-200 text-gray-900';
  const classes = `${colorClasses} inline-flex rounded-md border px-2 py-1 text-xs font-medium shadow-sm`;
  if (href) {
    return (
      <a href={href} className={classes}>
        {children}
      </a>
    );
  }
  return (
    <button type="button" onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

export default SmallButton;
