import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { JSONTree } from 'react-json-tree';
import ChevronLeft from '../../components/Icons/ChevronLeft';
import useLabs from '../../hooks/useLabs';
import { SocketConnection } from '../../services/SocketConnection';
import LabCard from '../../components/Labs';
import ResourceCards from '../../components/Resources';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import { LoadingType } from '../../types/labs';
import TerminalList from '../../components/Terminal';
import Notify from '../../utils/notifications';
import { useAuth } from '../../context/auth';
import { LabDefinition } from '../../agents';
import Form from '../../components/Form/form';
import { findIndexById } from '../../utils/helpers';

const Labs = () => {
  const params = useParams();
  const { labId, sessionId, definitionId } = params;
  const [loading, setLoading] = useState(true);
  const [firstMessage, setFirstMessage] = useState(false);
  const { activeLab, getLab, connect, disconnect, deleteLab, events, trackEvent } = useLabs();
  const [loadingState, setLoadingState] = useState<LoadingType>({ loading: true, error: null });
  const [labDefinition, setLabDefinition] = useState<any | null>(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = auth;
  const { status, resources } = activeLab || {};

  const loadLabDefinition = async (id: string | number | undefined) => {
    if (!id) {
      setLabDefinition(null);
      return;
    }
    try {
      const result = await LabDefinition.getDefinition(id);
      setLabDefinition(result);
    } catch (err) {
      console.log(err, 'error loading lab definition');
      setLabDefinition(null);
    }
  };

  useEffect(() => {
    const fetchLab = async () => {
      if (!auth.getJwt() || !auth.account) {
        return;
      }
      try {
        await getLab(labId, sessionId);
        await loadLabDefinition(definitionId);
        setLoadingState({ loading: false, error: null });
      } catch (error) {
        Notify.handleErrorResponse(error);
        setLoadingState({ loading: false, error });
      }
    };

    fetchLab();
  }, [labId, user]);

  // React to the lab being loaded for the first time
  useEffect(() => {
    if (activeLab && loading) {
      setLoading(false);
      if (activeLab.resources && activeLab.resources.length) {
        setFirstMessage(true);
      }
    }
  }, [activeLab]);

  // Initialize the socket connection when we are done loading
  useEffect(() => {
    if (loading || !auth.account) {
      return () => null;
    }
    const socket = new SocketConnection(`${process.env.REACT_APP_CLAB_API_URL}/clab`);
    // start socket
    socket.subscribe('/user/topic/lab', (message) => {
      setFirstMessage(true);
      try {
        const msg = JSON.parse(message.body);
        trackEvent(msg, 20);
        const { payload } = msg || {};
        if (!payload) {
          return;
        }
        const { newState, terminate } = payload;
        // Handle the terminated message by displaying an error.
        if ((newState && newState === 'terminated') || terminate) {
          Notify.error('Your session has expired.');
          navigate('/labs');
        }
      } catch (e) {
        console.log(e, 'error parsing message');
      }
    });
    socket.connect(sessionId, auth.getJwt(), auth.account.id);
    return () => {
      socket.disconnect();
    };
  }, [loading]);

  // Grab the default view type for our form
  const attributes = labDefinition?.attributes || [];
  const viewTypeIndex = findIndexById(attributes, 'viewType', 'key');
  const viewType = viewTypeIndex === -1 ? 'learner' : attributes[viewTypeIndex].value;

  const labDefinitionForm = {
    initialValues: {
      viewType,
    },
    validationSchema: Yup.object({
      viewType: Yup.string().required('You must select a view type.'),
    }),
    fields: [
      {
        type: 'select',
        label: 'View Type',
        name: 'viewType',
        options: [
          {
            value: 'learner',
            label: 'Learner View',
          },
          {
            value: 'simple',
            label: 'Simple View',
          },
        ],
        helpText: 'Choose the type of view used to display this lab.  Learner is the default, which has all controls including resources. Simple is a slimmed down view.',
      },
    ],
    noContainer: false,
    centerButton: false,
    onSubmit: async (values: any) => {
      const { viewType } = values;
      const attributes = labDefinition?.attributes || [];
      const viewTypeIndex = findIndexById(attributes, 'viewType', 'key');
      if (viewTypeIndex === -1) {
        attributes.push({
          key: 'viewType',
          value: viewType,
        });
      } else {
        attributes[viewTypeIndex].value = viewType;
      }
      const toPost = {
        ...labDefinition,
        attributes,
      };
      try {
        const result = await LabDefinition.saveDefinition(toPost);
        setLabDefinition(result);
        Notify.success('The lab definition was saved.');
      } catch (err) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Save Lab Definition',
    },
  };

  return (
    <div className="w-full md:min-h-[calc(100vh-56px)]">
      <div className="relative hidden w-full flex-col md:fixed md:flex md:flex-row">
        <div className="w-full overflow-y-scroll border p-8 md:max-h-[calc(100vh-56px)] md:min-h-[calc(100vh-56px)] md:w-2/5 lg:w-2/5 ">
          <Link to="/labs" className="flex items-center hover:text-black hover:underline">
            <ChevronLeft className="mr-2 h-4 w-4" /> View Templates and Labs
          </Link>

          <div className="my-4">
            <h1 className="text-3xl font-bold">Lab Session and Resources</h1>
          </div>

          <LoadingOrError loading={loadingState.loading} error={loadingState.error} className=" pt-3">
            <>
              <div className="flex flex-col gap-4 py-5">
                <p className="text-lg font-medium">Lab Definition</p>
                <div className="mb-3">
                  <Form {...labDefinitionForm} />
                </div>
                <p className="text-lg font-medium">Lab Session</p>
                {activeLab ? <LabCard deleteLab={deleteLab} lab={activeLab} small withControls withSnapshot /> : null}

                <div className="py-4">
                  <p className="mb-3 text-lg font-medium">Resources</p>
                  {!firstMessage && <p className="text-sm">Connecting to resources...</p>}
                  {firstMessage && <ResourceCards labId={labId || ''} labStatus={status || ''} resources={resources || []} connect={connect} disconnect={disconnect} />}
                </div>
              </div>
              <div>
                <h2 className="mt-8 mb-1">Clab events</h2>
                <JSONTree data={events} hideRoot />
              </div>
            </>
          </LoadingOrError>
        </div>

        <div className="relative grid w-full grid-cols-1 gap-1 bg-gray-800 md:w-3/5 lg:w-3/5">
          <TerminalList activeLab={activeLab} />
        </div>
      </div>
      <div className="mt-16 block p-8 md:hidden">
        <p className="text-center text-lg font-bold">The lab experience is not available for mobile.</p>
      </div>
    </div>
  );
};

export default Labs;
