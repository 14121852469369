import Notify from '../../utils/notifications';
import { Session, Infra } from '../../agents';
import { useAuth } from '../../context/auth';

const AdminTools = () => {
  const { logout } = useAuth();
  const resetUserSessions = async () => {
    try {
      await Session.clearUserSessions();
      Notify.success('User sessions have been reset.');
    } catch (error: any) {
      Notify.handleErrorResponse(error);
    }
  };

  const clearLabDefinitions = async () => {
    try {
      await Infra.clearLabDefinitions();
      Notify.success('Lab definitions have been cleared.');
    } catch (error: any) {
      Notify.handleErrorResponse(error);
    }
  };

  const reseedLabDefinitions = async () => {
    try {
      await Infra.reseedLabDefinitions();
      Notify.success('Lab definitions have been re-seeded.');
    } catch (error: any) {
      Notify.handleErrorResponse(error);
    }
  };

  const btnClass =
    'shadow-sm rounded-md items-center px-2 py-1 border border-gray-300 text-xs font-medium ' +
    'text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500';

  return (
    <div>
      <div className="mt-10">
        <h1 className="text-3xl font-bold">Admin</h1>
      </div>
      <div className="pb-12 pt-5">
        <div className="mb-2">
          <p className="mb-1 text-lg font-medium">Development Controls</p>
          <p className="text-xs text-gray-400">A few handy tools to use while we are developing C-Lab.</p>
        </div>
        <div className="mt-6 flex w-full flex-row items-center justify-between">
          <div>
            <button type="button" className={btnClass} onClick={resetUserSessions}>
              Clear User Lab Sessions
            </button>
          </div>
          <div>
            <button type="button" className={btnClass} onClick={clearLabDefinitions}>
              Clear Lab Definitions
            </button>
          </div>

          <div>
            <button type="button" className={btnClass} onClick={reseedLabDefinitions}>
              Re-Seed Lab Definitions
            </button>
          </div>

          <div>
            <button type="button" className={btnClass} onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTools;
