import Lab, { Resource } from '../types/labs';
import { Infra } from '../agents';
import { statusNames } from './constants/status';

export const getConnected = (resources: Resource[] | undefined): Resource[] => {
  return resources ? resources.filter((resource) => resource.connected) : [];
};

// is this needed anymore?
export const addResourceStatus = async (lab: Lab) => {
  const updatedLab = { ...lab } as Lab;
  if (lab.status === statusNames.STOPPED || lab.status === statusNames.READY) {
    const resourceStatus = await Infra.getResourceStatus(lab.labId);
    if (!resourceStatus.compute) {
      return updatedLab;
    }
    const zone = Object.keys(resourceStatus.compute)[0];

    const updatedResources = lab?.resources?.map((resource) => {
      const statusData = resourceStatus.compute[zone].find((status: any) => resource.name === status.name);
      return { ...resource, status: statusData.status };
    });
    updatedLab.resources = updatedResources;
  }
  return updatedLab;
};

export default getConnected;
