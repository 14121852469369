/* eslint-disable react/jsx-filename-extension */
import { ClockIcon } from '@heroicons/react/outline';
import { findIndexById } from '../../utils/helpers';
import Box from './box.js';
import FlagBox from './flag-box.js';

const GhostFlag = ({ name }) => {
  return (
    <Box key={name}>
      <div className="flex justify-between w-full">
        <div>
          <p className="text-xl font-semibold">{name}</p>
        </div>
        <div className="">
          <p className="text-xl">
            Score:
            <span className="ml-4">*/*</span>
          </p>
          <p className="text-xl">
            <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
            --:--:--
          </p>
        </div>
      </div>
    </Box>
  );
};

const FlagsTab = ({ flags = {}, completedFlags = [] }) => {
  const flagIds = Object.keys(flags);
  const flagsCount = flagIds.length;
  const hasFlags = !!flagsCount;

  // Show completed flags first, then ghost flags (incomplete flags)
  const ghostFlags = [];
  flagIds.forEach((flagId) => {
    if (findIndexById(completedFlags, parseInt(flagId, 10), 'activity-id') === -1) {
      ghostFlags.push(flags[flagId]);
    }
  });
  return (
    <div>
      <div className="space-y-4">
        {!hasFlags && !flagsCount && <p className="text-base">There are no activities to display.</p>}
        {hasFlags && !!completedFlags.length && completedFlags.map((flag) => <FlagBox key={flag['activity-id']} {...flag} />)}
        {hasFlags && !!ghostFlags.length && ghostFlags.map((flag, i) => <GhostFlag key={flag['activity-id']} name={`Activity ${i + completedFlags.length + 1}`} />)}
      </div>
    </div>
  );
};

export default FlagsTab;
