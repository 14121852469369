/* eslint-disable react/jsx-filename-extension */
const LearnerTabs = ({ current, switchTab, flagsCount, resources, simple = false }) => {
  const resourcesCount = resources ? resources.filter((r) => !r.hidden).length : 0;
  const tabs = [
    {
      name: 'Information',
      count: 0,
    },
    {
      name: 'Resources',
      count: resourcesCount,
    },
  ];
  if (!simple) {
    tabs.push({
      name: 'Activities',
      count: flagsCount,
    });
  }

  return (
    <div>
      <div className="border-b border-transparent">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab, i) => {
            const isCurrent = current === i;
            const tabClasses = isCurrent ? 'border-pink-700 text-white' : 'border-transparent text-gray-400 hover:text-gray-50';
            const tabNumberClasses = isCurrent ? 'bg-pink-700 text-white' : 'bg-gray-300 text-gray-900';
            return (
              <button
                key={tab.name}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  switchTab(i);
                }}
                className={`${tabClasses} whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm`}
              >
                {tab.name}
                {tab.count ? <span className={`${tabNumberClasses} hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`}>{tab.count}</span> : null}
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default LearnerTabs;
