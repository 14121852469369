/* eslint-disable react/jsx-filename-extension */
import Modal from '../Modal/modal.js';

const ExitModal = ({ open, close, handleExit, simple = false }) => {
  const title = simple ? 'Exit lab?' : 'Exit assessment?';
  const body = simple
    ? 'If you exit the lab, your lab session will end and you will lose any progress.'
    : 'If you exit the assessment, your lab session will end and you will lose any assessment results.';
  return (
    <Modal open={open} onClose={close} className="max-w-sm">
      <h3 className="text-gray-200 text-2xl font-bold mb-8">{title}</h3>
      <p className="text-gray-200 mb-6">{body}</p>
      <div className="flex justify-between">
        <button className="bg-white h-10 px-8 text-xs rounded font-semibold" type="button" onClick={close}>
          Nevermind
        </button>
        <button className="text-white h-10 px-8 text-xs bg-pink-700 rounded font-semibold" type="button" onClick={handleExit}>
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default ExitModal;
