import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { PencilIcon, EyeIcon, BeakerIcon, XIcon } from '@heroicons/react/solid';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { deleteApiRequest, getApiRequest } from '../../agents';
import SimplePaginator from '../../components/Common/simple-paginator.js';
import SmallButton from '../../components/Common/small-button.js';
import Confirm from '../../components/Overlays/confirm';
import Search from '../../components/Common/search.js';

const AdminDefinitions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [definitions, setDefinitions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const limit = 10;

  const getDefinitions = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getApiRequest(`/lab?page=${page}&size=${limit}`);
      setDefinitions(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  const searchDefinitions = async (q) => {
    if (!q) {
      await getDefinitions();
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const searchString = queryString.stringify({ q, page, size: limit });
      const result = await getApiRequest(`/lab/search?${searchString}`);
      setDefinitions(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the definitions
   */
  useEffect(() => {
    getDefinitions();
  }, [page]);

  const from = page * limit + 1;
  const to = from + definitions.length - 1;

  const deleteDefinition = async () => {
    if (!toDelete) {
      return;
    }
    try {
      setLoading(true);
      await deleteApiRequest(`/lab/${toDelete}`, {});
      Notify.success('The definition was deleted.');
      await getDefinitions();
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setShowConfirm(false);
    setToDelete(null);
    setLoading(false);
  };

  const showDelete = (id) => {
    setToDelete(id);
    setShowConfirm(true);
  };
  const cancelDelete = () => {
    setToDelete(null);
    setShowConfirm(false);
  };

  return (
    <div className="px-4 lg:px-8">
      <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
        <div className="mt-10 flex justify-between">
          <div>
            <h1 className="text-3xl font-bold mb-1">Definitions</h1>
            <p className="text-sm text-gray-400">A list of available lab definitions.</p>
          </div>
          <div>
            <a href="/admin/definition" className="bg-indigo-600 hover:bg-indigo-700 text-white inline-flex rounded-md border px-4 py-2 text-sm font-medium shadow-sm">
              Create New Definition
            </a>
          </div>
        </div>

        <div className="py-5 w-96">
          <Search placeholder="Search lab definitions..." handleSearch={searchDefinitions} />
        </div>
        <LoadingOrError loading={loading} error={error}>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              {!definitions.length && <p className="text-sm text-gray-700">There are results to display.</p>}
              {!!definitions.length &&
                definitions.map((definition) => (
                  <div key={definition.identifier} className="my-1 w-full rounded border border-gray-300 py-2 px-5 relative">
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
                      <div className="flex items-center text-sm">
                        <p className="mr-2 text-gray-500">Name:</p>
                        <p className="mr-2 font-medium">{definition.name}</p>
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="mr-2 text-gray-500">Description:</p>
                        <p className="font-medium">{definition.description}</p>
                      </div>
                      {!!definition.configuration?.protocol && (
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">Protocol:</p>
                          <p className="font-medium">{definition.configuration.protocol}</p>
                        </div>
                      )}
                      {!!definition.configuration?.ami && (
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">AMI:</p>
                          <p className="font-medium">{definition.configuration.ami}</p>
                        </div>
                      )}
                      {!!definition.status && (
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">Status:</p>
                          <p className="font-medium">{definition.status}</p>
                        </div>
                      )}
                    </div>
                    <div className="absolute top-2 right-2 space-x-2">
                      <SmallButton href={`/admin/definition/${definition.labId}/show`}>
                        <EyeIcon className="h-4" />
                      </SmallButton>
                      <SmallButton href={`/admin/definition/${definition.labId}/sessions`}>
                        <BeakerIcon className="h-4" />
                      </SmallButton>
                      <SmallButton href={`/admin/definition/${definition.labId}`}>
                        <PencilIcon className="h-4" />
                      </SmallButton>
                      <SmallButton danger onClick={() => showDelete(definition.labId)}>
                        <XIcon className="h-4" />
                      </SmallButton>
                    </div>
                  </div>
                ))}
            </div>
            <SimplePaginator
              from={from}
              to={to}
              previousUrl={page === 0 ? null : () => setPage(page - 1)}
              nextUrl={definitions.length < limit ? null : () => setPage(page + 1)}
              useButtons
            />
          </div>
          <Confirm
            open={showConfirm}
            cancel={cancelDelete}
            action={deleteDefinition}
            title="Confirm delete"
            body="Are you sure you want to delete this Lab Definition? This cannot be undone."
            actionText="Delete"
          />
        </LoadingOrError>
      </div>
    </div>
  );
};

export default AdminDefinitions;
