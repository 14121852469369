import { useState } from 'react';
import Report from '../../components/NewLearner/report.js';

const LearnerReport = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full h-screen bg-neutral-600">
      <button type="button" className="text-lg text-pink-200 font-bold m-10" onClick={() => setOpen(true)}>
        OPEN
      </button>
      <Report open={open} close={() => setOpen(false)} />
    </div>
  );
};

export default LearnerReport;
