import { useState, Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import queryString from 'query-string';
import { ArrowCircleRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import useLearnerLab from '../../hooks/useLearnerLab';
import LeftSlideOver from '../../components/Overlays/left-slide-over';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import LearnerContent from '../../components/NewLearner/learner-content.js';
import LearnerTabs from '../../components/NewLearner/learner-tabs.js';
import TerminalList from '../../components/Terminal';
import Report from '../../components/NewLearner/report.js';
import GlobalTimer from '../../components/NewLearner/global-timer.js';
import CountdownTimer from '../../components/NewLearner/countdown-timer.js';
import StartTimer from '../../components/NewLearner/start-timer.js';
import FlagNotification from '../../components/NewLearner/flag-notification.js';
import DebugControls from '../../components/Labs/DebugControls';
import AuditModal from '../../components/NewLearner/audit-modal.js';
import ExitModal from '../../components/NewLearner/exit-modal.js';
import { useAuth } from '../../context/auth';
import ConnectionError from '../../components/NewLearner/connection-error.js';
import useNetwork from '../../hooks/useNetwork.js';

interface QueryType {
  labInstanceIdentifier?: string;
  token?: string;
  session?: string;
  c?: string;
  s?: string;
}

interface NewLearnerViewContainerProps {
  accountId: number;
  labInstanceIdentifier?: string;
  token?: string;
  session?: string;
}

const NewLearnerViewContainer = ({ accountId, labInstanceIdentifier, session, token }: NewLearnerViewContainerProps) => {
  // Load query string params
  const [showTest, setShowTest] = useState(true);
  const [showAuditLogs, setShowAuditLogs] = useState(false);
  const [hasDebugControls, setHasDebugControls] = useState(false);
  const [simpleView, setSimpleView] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [showConnectionErrorModal, setShowConnectionErrorModal] = useState(false);
  const query = queryString.parse(window.location.search);
  const { c, s }: QueryType = query || {};

  const {
    loadLab,
    lab,
    loading,
    error,
    connect,
    disconnect,
    beginLab,
    flags,
    completedFlags,
    flagNotification,
    playbook,
    resources,
    activeResource,
    startTimer,
    expires,
    labComplete,
    // handleLabComplete,
    setDebugParams,
    auditLogs,
    summary,
    endLab,
    loadUserSession,
    userSession,
  } = useLearnerLab(session, token, accountId);
  const [navOpen, setNavOpen] = useState(true);
  const [navVisible, setNavVisible] = useState(true);
  const [init, setInit] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [showReport, setShowReport] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [leftEarly, setLeftEarly] = useState(false);
  const { isOnline } = useNetwork();

  const handleFlagClick = () => {
    setCurrentTab(2);
    setNavOpen(true);
  };

  const openSidebar = () => {
    setNavOpen(true);
  };

  const closeSidebar = () => {
    setNavOpen(false);
  };

  const hideSidebar = () => {
    closeSidebar();
    setNavVisible(false);
  };

  const toggleShowTest = () => {
    if (hasDebugControls) {
      setShowTest(!showTest);
    }
  };

  const { info, status } = lab || {};
  const labRunning = status === 'running';

  // If a flag message received and set, show notification
  useEffect(() => {
    if (flagNotification) {
      FlagNotification({ ...flagNotification, onClick: handleFlagClick });
    }
  }, [flagNotification]);

  useEffect(() => {
    if (c === 'true') {
      setHasDebugControls(true);
    }
  }, [c]);
  useEffect(() => {
    if (s === 'true') {
      setSimpleView(true);
    }
  }, [s]);

  useEffect(() => {
    if (!session || !token || !labInstanceIdentifier || !accountId) {
      return;
    }
    // Load the lab info
    loadLab(`${labInstanceIdentifier}`, `${session}`, s === 'true');
    loadUserSession(accountId, `${labInstanceIdentifier}`);
  }, [labInstanceIdentifier, token, session, accountId]);

  useEffect(() => {
    // We only do this on initial load, we attempt to connect to the first resource
    if (!resources.length || init || !labRunning) {
      return;
    }

    setInit(true);
    // console.log(resources[0], 'connecting');
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].connectable) {
        connect(resources[i]);
        break;
      }
    }
  }, [resources, labRunning]);

  // If the expires time has cleared and lab is marked complete, show the report
  useEffect(() => {
    if (labComplete && !expires) {
      closeSidebar();
      setShowReport(true);
    }
  }, [labComplete, expires]);

  useEffect(() => {
    if ((!isOnline || connectionError) && !showConnectionErrorModal) {
      hideSidebar();
      setShowConnectionErrorModal(true);
    }
  }, [connectionError, isOnline]);

  // Send request to server to start lab
  const startLab = async () => {
    if (lab && lab.labInstanceIdentifier) {
      try {
        await beginLab(lab.labInstanceIdentifier);
        startTimer();
      } catch (e) {
        console.log(e);
      }
    }
  };

  // Handle exiting a session
  const handleExit = async () => {
    setShowExitModal(false);
    setLeftEarly(true);
    if (!lab || !lab.labInstanceIdentifier) {
      return;
    }
    try {
      await endLab(lab.labInstanceIdentifier);
      hideSidebar();
    } catch (e) {
      setLeftEarly(false);
      console.log(e);
    }
  };

  const handleLabEnd = async () => {
    setShowExitModal(false);
    hideSidebar();
    try {
      if (lab && lab.labInstanceIdentifier) {
        await endLab(lab.labInstanceIdentifier);
      }
    } catch (e) {
      setLeftEarly(false);
      console.log(e);
    }
  };

  const guacConnectionErrorHandler = (msg = '') => {
    // console.log(msg, 'we have an error in the leaner view');
    setConnectionError(msg);
  };

  const { ttl, expires: labExpires } = lab || {};
  const { expires: sessionExpires } = userSession || {};

  const preferredExpires = expires || labExpires || sessionExpires;

  if (loading) {
    return (
      <div className="w-full h-screen items-center bg-neutral-900 text-gray-100">
        <LoadingOrError loading={loading} error={!!error} errorText={error || ''} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  const flagsCount = Object.keys(flags).length;
  const connectableResources = resources.filter((res) => res.connectable && res['connect-string']);

  return (
    <>
      <div className="w-full">
        <div className="relative hidden w-full flex-col md:fixed md:flex md:flex-row">
          <div className="w-12 bg-neutral-900 relative">
            {navVisible && (
              <Transition.Root show={!navOpen} as={Fragment}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity duration-700"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-700"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <button className="text-white absolute top-14 left-0 w-12 h-12 bg-neutral-900 rounded" type="button" onClick={openSidebar}>
                    <ArrowCircleRightIcon className="h-8 w-8 ml-2" />
                  </button>
                </Transition.Child>
              </Transition.Root>
            )}
          </div>
          {/* <div className="w-12 bg-neutral-600" /> */}
          <div className="relative grid h-screen flex-1 grid-cols-1 gap-1 bg-neutral-900">
            {labRunning && !error && !showConnectionErrorModal && connectableResources.length && (
              <TerminalList activeLab={lab} resources={resources} errorHandler={guacConnectionErrorHandler} />
            )}
            {!labRunning && !error && !showConnectionErrorModal && (
              <LoadingOrError
                loading
                loadingText={leftEarly ? 'Connecting...' : 'Building your lab environment...'}
                error={false}
                className="flex h-full w-full items-center pt-10 text-gray-100"
                spinnerClass="h-14 w-14 text-gray-100"
                textClass="text-center text-gray-100"
              />
            )}
            {!!error && !showConnectionErrorModal && <LoadingOrError loading={false} error={!!error} errorText={error || ''} textClass="text-center text-gray-100" />}
            {showConnectionErrorModal && <ConnectionError msg={connectionError} />}
            <Report title={playbook?.name} subtitle={playbook?.module?.name} open={showReport && !leftEarly && !simpleView} close={() => setShowReport(false)} summary={summary} />
          </div>
        </div>
      </div>
      {navVisible && (
        <LeftSlideOver darkBg close={closeSidebar} isOpen={navOpen}>
          <div className="sticky top-0 z-10 bg-neutral-900">
            <div className="pb-2 pt-2">
              {hasDebugControls && lab && showTest && (
                <DebugControls
                  labId={lab.labId}
                  activities={flags}
                  onChange={setDebugParams}
                  containerClassName="text-white text-xs mb-2"
                  showAuditLogs={() => setShowAuditLogs(true)}
                />
              )}
              <div className="flex justify-between items-center h-10 mt-2">
                <div className="flex items-center">
                  <ChevronLeftIcon className="h-6 w-6 text-white mr-2 cursor-pointer" onClick={() => setShowExitModal(true)} />
                  <img
                    alt="Cybrary Logo White"
                    src="https://images.ctfassets.net/kvf8rpi09wgk/5mvqed7vGZQqynYaTiO6dX/141877a61e540b2a211b848f419dc2ae/cybrary_logo_white.svg"
                    className="h-8"
                    onClick={toggleShowTest}
                  />
                </div>
                {!expires && !labComplete && !simpleView && <StartTimer handleStart={startLab} minutes={playbook ? playbook['time-limit-minutes'] : null} />}
                {expires && !labComplete && !simpleView && <CountdownTimer expires={expires} />}
              </div>
            </div>
            <div className="">
              <LearnerTabs current={currentTab} resources={resources} flagsCount={flagsCount} switchTab={setCurrentTab} simple={simpleView} />
            </div>
          </div>
          <div className="text-gray-200 mt-6">
            <LearnerContent
              info={info || ''}
              resources={resources}
              activeResource={activeResource}
              current={currentTab}
              flags={flags}
              completedFlags={completedFlags}
              simple={simpleView}
              // @ts-ignore
              connect={connect}
              // @ts-ignore
              disconnect={disconnect}
            />
          </div>
        </LeftSlideOver>
      )}
      {!!preferredExpires && !!lab && <GlobalTimer expires={preferredExpires} ttl={ttl} onEnd={handleLabEnd} />}
      <AuditModal open={showAuditLogs} close={() => setShowAuditLogs(false)} auditLogs={auditLogs} />
      <ExitModal simple={simpleView} open={showExitModal} close={() => setShowExitModal(false)} handleExit={handleExit} />
    </>
  );
};

const NewLearnerView = () => {
  const { account, getAccount } = useAuth();
  // Load query string param
  const query = queryString.parse(window.location.search);
  const { labInstanceIdentifier, token, session }: QueryType = query || {};
  useEffect(() => {
    if (!session || !token || !labInstanceIdentifier) {
      return;
    }
    // Set the token so that way it is used by the agents file
    window.localStorage.setItem('jwt', `${token}`);
    // Load the account
    getAccount();
  }, [labInstanceIdentifier, token, session]);

  if (!account) {
    return (
      <div className="w-full h-screen items-center bg-neutral-900 text-gray-100">
        <LoadingOrError loading={!!account} error={false} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  return <NewLearnerViewContainer accountId={account.id} labInstanceIdentifier={labInstanceIdentifier} session={session} token={token} />;
};

export default NewLearnerView;
