import { useEffect, useState } from 'react';
import { Message } from '@stomp/stompjs';
import { getApiRequest, Infra } from '../agents';
import MessageConstant from '../utils/constants/message';
import { LabResponse, Lab, Resource, EventType } from '../types/labs';
import { formatLabDetails, getLabFromSocketEvent, getMessageType, getStatusFromSocketEvent } from '../utils/labUtil';
import { addResourceStatus } from '../utils/resourceUtil';
import { statusNames } from '../utils/constants/status';
import { findIndexById } from '../utils/helpers';
import Notify from '../utils/notifications';

const useLabs = () => {
  const [userLabs, setUserLabs] = useState<Lab[]>([]);
  const [activeLab, setActiveLab] = useState<Lab | null>(null);
  const [events, setEvents] = useState<EventType>({});
  const { status, resources } = activeLab || {};

  // Update resource status if it is not set
  useEffect(() => {
    (async () => {
      const hasResourceStatus = resources && resources.length && resources[0].status;
      if (activeLab && status === statusNames.READY && !hasResourceStatus) {
        const lab = await addResourceStatus(activeLab);
        setActiveLab(lab);
      }
    })();
  }, [status, resources]);

  /**
   * Get All users Labs
   */
  const fetchUserLabs = async () => {
    const labs: LabResponse[] = await Infra.getLabs();
    const fetchedLabs = labs.map((lab) => {
      const { identifier, name, sessionId, status, labId, attributes, labInstanceIdentifier } = lab;
      return {
        id: labId,
        labId,
        name,
        labUuid: identifier || labInstanceIdentifier || '',
        identifier: identifier || labInstanceIdentifier || '',
        sessionId: sessionId || '',
        status: status || 'ready',
        resources: [],
        attributes,
      };
    });

    // setUserLabs([...fetchedLabs, ...userLabs]);
    setUserLabs(fetchedLabs);
  };

  /**
   * Update the CLIENT TRACKED STATUS for a given lab
   * @param id
   * @param status
   */
  const updateLabClientStatus = (id: number | string, status: string) => {
    // Find the template in our array of templates
    const index = findIndexById(userLabs, id, 'labUuid');
    if (index === -1) {
      return;
    }
    const newLabs = [...userLabs];
    newLabs[index].clientStatus = status;
    setUserLabs(newLabs);
  };

  /**
   * Delete a user lab instance
   * @param id
   */
  const deleteLab = async (labId: string | number, successCallback?: () => void) => {
    try {
      await Infra.deleteLab(labId);
      Notify.success('The lab instance has been deleted.');
      updateLabClientStatus(labId, 'deleted');
      if (successCallback) {
        successCallback();
      }
    } catch (err: any) {
      Notify.handleErrorResponse(err);
    }
  };

  /**
   * Either update a resource within an active lab (if it exists), or add a new one, and return the active lab
   */
  const updateResource = (message: Message) => {
    if (!activeLab) {
      return null;
    }
    const resourceStatus = getStatusFromSocketEvent(message);
    // Either update the resource, or add it to the list
    const { resources: existingResources } = activeLab;
    const existingResourceIndex = findIndexById(existingResources || [], resourceStatus.name, 'name');
    const newResources = existingResources ? [...existingResources] : [];
    // Just append the full status message to the end of the array if we don't already have this resource
    if (existingResourceIndex === -1) {
      return {
        ...activeLab,
        resources: [...newResources, resourceStatus],
      };
    }
    // Update status otherwise
    newResources[existingResourceIndex].status = resourceStatus.status;
    return {
      ...activeLab,
      resources: newResources,
    };
  };

  /**
   * Set current working Lab from socket events
   */
  const addActiveLab = (message: Message) => {
    const messageType = getMessageType(message);
    // handle lab events
    if (messageType === MessageConstant.LAB) {
      const lab: Lab = getLabFromSocketEvent(message);
      setActiveLab(lab);
      // handle resource events
    } else if (messageType === MessageConstant.RESOURCE) {
      if (!activeLab) {
        return;
      }
      const lab: Lab | null = updateResource(message);
      if (lab) {
        setActiveLab(lab);
      }
    }
  };

  /** util
   * Get and Add Resource Status to resources
   * @param labId
   * @returns
   */

  const getLab = async (labId: string | number | undefined, sessionId: string | undefined) => {
    if (!labId) {
      return null;
    }
    const labDetails = await Infra.getLabById(labId);
    const { labId: definitionId } = labDetails.labInstance;
    // Grab the definition as well
    const definition = await getApiRequest(`/lab/${definitionId}`);
    const formattedLab = formatLabDetails(labDetails, definition);
    formattedLab.sessionId = sessionId;
    setActiveLab(formattedLab);
    return formattedLab;
  };

  /**
   * Connect to available resource within the active lab
   * @param resource
   * @returns
   */
  const connect = (resource: Resource) => {
    if (!activeLab) {
      return;
    }
    const updatedActiveLab = { ...activeLab };

    updatedActiveLab.resources = activeLab.resources.map((rscr) => {
      return rscr.ip === resource.ip && !resource.connected ? { ...rscr, connected: true, activeTab: true } : rscr;
    });

    setActiveLab(updatedActiveLab);
  };

  /**
   * Disconnect from resource
   * @param resource
   * @returns
   */
  const disconnect = (resource: Resource) => {
    if (!activeLab) {
      return;
    }
    const updatedActiveLab = { ...activeLab };

    updatedActiveLab.resources = activeLab.resources.map((rscr) => {
      return rscr.ip === resource.ip && rscr.connected ? { ...resource, connected: false, activeTab: false } : rscr;
    });

    setActiveLab(updatedActiveLab);
  };

  /** Jump between resources, if it is not already connected, connect it */
  const switchResource = (resource: Resource) => {
    const updatedLab = { ...activeLab } as Lab;

    updatedLab.resources = updatedLab.resources.map((rscr) => {
      return rscr.ip === resource.ip ? { ...resource, connected: true, activeTab: true } : { ...rscr, activeTab: false };
    });

    setActiveLab(updatedLab);
  };

  const trackEvent = (msg: EventType, limit: number) => {
    setEvents((currentEvents) => {
      let newEvents: EventType = {};
      const newMessage: Record<string, object> = {};
      newMessage[`${msg.type}_${Date.now()}`] = msg.payload;
      newEvents = { ...newMessage, ...currentEvents };
      const messagesKeys = Object.keys(newEvents);
      // If we have more than the defined limit, truncate from the end
      if (limit && messagesKeys.length > limit) {
        delete newEvents[messagesKeys[limit]];
      }
      return newEvents;
    });
  };

  return { fetchUserLabs, userLabs, addActiveLab, activeLab, getLab, setActiveLab, connect, disconnect, switchResource, updateLabClientStatus, deleteLab, events, trackEvent };
};

export default useLabs;
