import { useState, Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowCircleRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import useLearnerLab from '../../hooks/useLearnerLab';
import LeftSlideOver from '../../components/Overlays/left-slide-over';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import LearnerContent from '../../components/NewLearner/learner-content.js';
import LearnerTabs from '../../components/NewLearner/learner-tabs.js';
import TerminalList from '../../components/Terminal';
import Report from '../../components/NewLearner/report.js';
import GlobalTimer from '../../components/NewLearner/global-timer.js';
import FlagNotification from '../../components/NewLearner/flag-notification.js';
import DebugControls from '../../components/Labs/DebugControls';
import AuditModal from '../../components/NewLearner/audit-modal.js';
import CreatorExitModal from '../../components/NewCreator/creator-exit-modal.js';
import { useAuth } from '../../context/auth';
import FormModal from '../../components/Overlays/form-modal';
import Notify from '../../utils/notifications';
import { Infra } from '../../agents';
import ConnectionError from '../../components/NewLearner/connection-error.js';

const AdminCreator = () => {
  // Load query string params
  const [showTest, setShowTest] = useState(true);
  const [showNameModal, setShowNameModal] = useState(false);
  const [showAuditLogs, setShowAuditLogs] = useState(false);
  const [hasDebugControls, setHasDebugControls] = useState(false);
  const [simpleView, setSimpleView] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [showConnectionErrorModal, setShowConnectionErrorModal] = useState(false);
  const query = queryString.parse(window.location.search);
  const { c, s } = query || {};
  const auth = useAuth();

  const { identifier, sessionId, labId } = useParams();

  const {
    loadLab,
    lab,
    loading,
    error,
    connect,
    disconnect,
    switchResource,
    flags,
    completedFlags,
    flagNotification,
    playbook,
    resources,
    activeResource,
    expires,
    // handleLabComplete,
    setDebugParams,
    auditLogs,
    summary,
    endLab,
  } = useLearnerLab(sessionId, auth.getJwt(), auth.account?.id);
  const [navOpen, setNavOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [showReport, setShowReport] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [leftEarly, setLeftEarly] = useState(false);

  const navigate = useNavigate();

  const handleFlagClick = () => {
    setCurrentTab(2);
    setNavOpen(true);
  };

  const openSidebar = () => {
    setNavOpen(true);
  };

  const closeSidebar = () => {
    setNavOpen(false);
  };

  const toggleShowTest = () => {
    if (hasDebugControls) {
      setShowTest(!showTest);
    }
  };

  const { info, status } = lab || {};
  const labRunning = status === 'running';

  // If a flag message received and set, show notification
  useEffect(() => {
    if (flagNotification) {
      FlagNotification({ ...flagNotification, onClick: handleFlagClick });
    }
  }, [flagNotification]);

  useEffect(() => {
    if (c === 'true') {
      setHasDebugControls(true);
    }
  }, [c]);
  useEffect(() => {
    if (s === 'true') {
      setSimpleView(true);
    }
  }, [s]);

  useEffect(() => {
    if (!sessionId || !identifier) {
      return;
    }
    // Load the lab info
    loadLab(`${identifier}`, `${sessionId}`, true);
  }, [identifier, sessionId]);

  useEffect(() => {
    if (connectionError && !showConnectionErrorModal) {
      closeSidebar();
      setShowConnectionErrorModal(true);
    }
  }, [connectionError]);

  // Handle exiting a session
  const handleExit = async () => {
    setShowExitModal(false);
    setLeftEarly(true);
    if (!lab) {
      return;
    }
    try {
      await endLab(lab.labInstanceIdentifier);
      closeSidebar();
      navigate(`/admin/definition/${labId}/show`);
    } catch (e) {
      console.log(e);
    }
  };

  const guacConnectionErrorHandler = (msg = '') => {
    setConnectionError(msg);
  };

  if (loading) {
    return (
      <div className="w-full h-screen items-center bg-neutral-900 text-gray-100">
        <LoadingOrError loading={loading} error={!!error} errorText={error || ''} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  const flagsCount = Object.keys(flags).length;
  const connectableResources = resources.filter((res) => res['connect-string']);

  const showSnapshotForm = () => {
    closeSidebar();
    setShowNameModal(true);
  };

  const handleExitAndSave = () => {
    setShowExitModal(false);
    showSnapshotForm();
  };

  const nameForm = {
    initialValues: {
      snapshotName: '',
    },
    validationSchema: Yup.object({
      snapshotName: Yup.string().required('You must provide a name for the snapshot.'),
    }),
    fields: [
      {
        type: 'slug',
        name: 'snapshotName',
        placeholder: 'Enter a unique name',
        helpText: 'You will need to use characters, dashes, and numbers only.',
      },
    ],
    noContainer: true,
    centerButton: true,
    onSubmit: async (values) => {
      const { snapshotName } = values;
      try {
        setLeftEarly(true);
        await Infra.snapshot(identifier, snapshotName, true);
        Notify.success('The snapshot is being created.');
        setShowNameModal(false);
        navigate(`/admin/definition/${labId}/show`);
      } catch (err) {
        setLeftEarly(false);
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Create Snapshot And Exit',
    },
  };

  return (
    <>
      <div className="w-full">
        <div className="relative hidden w-full flex-col md:fixed md:flex md:flex-row">
          <div className="w-12 bg-neutral-900 relative">
            <Transition.Root show={!navOpen} as={Fragment}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity duration-700"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-700"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <button className="text-white absolute top-14 left-0 w-12 h-12 bg-neutral-900 rounded" type="button" onClick={openSidebar}>
                  <ArrowCircleRightIcon className="h-8 w-8 ml-2" />
                </button>
              </Transition.Child>
            </Transition.Root>
          </div>
          {/* <div className="w-12 bg-neutral-600" /> */}
          <div className="relative grid h-screen flex-1 grid-cols-1 gap-1 bg-neutral-900">
            {labRunning && !error && !connectionError && connectableResources.length && (
              <TerminalList errorHandler={guacConnectionErrorHandler} activeLab={lab} resources={resources} disconnect={disconnect} switchResource={switchResource} />
            )}
            {!labRunning && !error && !connectionError && (
              <LoadingOrError
                loading
                loadingText={leftEarly ? 'Connecting...' : 'Building your lab environment...'}
                error={false}
                className="flex h-full w-full items-center pt-10 text-gray-100"
                spinnerClass="h-14 w-14 text-gray-100"
                textClass="text-center text-gray-100"
              />
            )}
            {!!error && !connectionError && <LoadingOrError loading={loading} error={!!error} errorText={error || ''} textClass="text-center text-gray-100" />}
            {!!connectionError && <ConnectionError msg={connectionError} />}
            <Report title={playbook?.name} subtitle={playbook?.module?.name} open={showReport && !leftEarly && !simpleView} close={() => setShowReport(false)} summary={summary} />
          </div>
        </div>
      </div>
      <LeftSlideOver darkBg close={closeSidebar} isOpen={navOpen}>
        <div className="sticky top-0 z-10 bg-neutral-900">
          <div className="pb-2 pt-2">
            {hasDebugControls && lab && showTest && (
              <DebugControls
                labId={lab.labId}
                activities={flags}
                onChange={setDebugParams}
                containerClassName="text-white text-xs mb-2"
                showAuditLogs={() => setShowAuditLogs(true)}
              />
            )}
            <div className="flex justify-between items-center h-10 mt-2">
              <div className="flex items-center">
                <ChevronLeftIcon className="h-6 w-6 text-white mr-2 cursor-pointer" onClick={() => setShowExitModal(true)} />
                <img
                  alt="Cybrary Logo White"
                  src="https://images.ctfassets.net/kvf8rpi09wgk/5mvqed7vGZQqynYaTiO6dX/141877a61e540b2a211b848f419dc2ae/cybrary_logo_white.svg"
                  className="h-8"
                  onClick={toggleShowTest}
                />
              </div>
            </div>
          </div>
          <div className="">
            <LearnerTabs current={currentTab} resources={resources} flagsCount={flagsCount} switchTab={setCurrentTab} simple={simpleView} />
          </div>
        </div>
        <div className="text-gray-200 mt-6">
          <LearnerContent
            resources={resources}
            info={info || ''}
            activeResource={activeResource}
            current={currentTab}
            flags={flags}
            completedFlags={completedFlags}
            simple={simpleView}
            connect={connect}
            disconnect={disconnect}
            alwaysConnect
          />
        </div>
      </LeftSlideOver>
      {expires && <GlobalTimer expires={expires} onEnd={() => null} />}
      <AuditModal open={showAuditLogs} close={() => setShowAuditLogs(false)} auditLogs={auditLogs} />
      <CreatorExitModal handleExitAndSave={handleExitAndSave} open={showExitModal} close={() => setShowExitModal(false)} handleExit={handleExit} />
      <FormModal
        open={!!showNameModal}
        form={nameForm}
        autoForm={false}
        title="Create Snapshot"
        body="Add a name for your new lab snapshot to make it easier to identify in lists."
        cancel={() => setShowNameModal(false)}
      />
    </>
  );
};

export default AdminCreator;
