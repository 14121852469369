import { Link } from 'react-router-dom';

const PreviousOrNextLink = ({ url, label, extraClasses = null, useButtons = false }) => {
  if (!url) {
    return (
      <span
        className={`${extraClasses || ''}
          relative inline-flex items-center px-4 py-2 border border-gray-200 text-sm rounded-md text-gray-400 bg-white hover:bg-gray-50 cursor-not-allowed`}
      >
        {label}
      </span>
    );
  }

  const linkClasses = `${extraClasses || ''}
    relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-bold rounded-md text-gray-700 bg-white hover:bg-gray-50`;
  if (useButtons) {
    // If we are using buttons, url will actually be a function instead of a string
    return (
      <button type="button" onClick={url} className={linkClasses}>
        {label}
      </button>
    );
  }
  return (
    <Link to={url} className={linkClasses}>
      {label}
    </Link>
  );
};

const SimplePaginator = ({ from, to, previousUrl, nextUrl, total = null, useButtons = false }) => {
  return (
    <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
      <div className="hidden sm:block">
        {!total && (
          <p className="text-sm text-gray-700">
            Showing result{to - from >= 1 ? 's' : ''} <span className="font-bold">{from}</span> thru <span className="font-bold">{to}</span>
          </p>
        )}
        {!!total && (
          <p className="text-sm text-gray-700">
            Showing <span className="font-bold">{from}</span> to <span className="font-bold">{to}</span> of <span className="font-bold">{total}</span> result{total > 1 ? 's' : ''}
          </p>
        )}
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <PreviousOrNextLink url={previousUrl} label="Previous" useButtons={useButtons} />
        <PreviousOrNextLink url={nextUrl} label="Next" extraClasses="ml-3" useButtons={useButtons} />
      </div>
    </nav>
  );
};

export default SimplePaginator;
