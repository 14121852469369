import { Link } from 'react-router-dom';

const PrimaryNav = () => {
  return (
    <nav className="flex h-14 items-center border-b border-gray-200 px-4 lg:px-8">
      <img src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg" alt="Cybrary" width="120" height="31" />
      <ul className="ml-auto flex list-none gap-4">
        <li className="font-semibold text-gray-500">
          <Link to="/">Lab Creator</Link>
        </li>
        <li className="font-semibold text-gray-500">
          <Link to="/dashboard/sessions">Dashboard</Link>
        </li>
        <li className="font-semibold text-gray-500">
          <Link to="/learner/new">Learner UI</Link>
        </li>
      </ul>
    </nav>
  );
};

export default PrimaryNav;
