/**
 * A handful of helper functions used throughout the app.
 */

// Fix the index of an item by it's id
export function findIndexById(items: any[], id: string | number, idKey = 'id') {
  if (!items || !items.length || !id) {
    return -1;
  }
  let ret = -1;
  items.forEach((item, i) => {
    if (item && item[idKey] === id) {
      ret = i;
    }
  });
  return ret;
}

export function findById(items: any[], id: string | number, idKey = 'id', defaultValue = null) {
  const index = findIndexById(items, id, idKey);
  if (index === -1) {
    return defaultValue;
  }
  return items[index];
}

export function slugify(str: string) {
  if (!str) {
    return '';
  }
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-/]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '');
  // .replace(/-+$/, '');
}

export function cleanSessionName(name: string) {
  if (!name) {
    return '';
  }
  const nameParts = name.split('(');
  if (nameParts.length === 2) {
    return nameParts[1].replace(')', '');
  }
  return name;
}

// Prettier is forcing me to do a default export, I don't have one for this file, so...
export default {};
