import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import GuacConnection from '../../services/GuacConnection';
import { Resource, Lab } from '../../types/labs';
import getConnected from '../../utils/resourceUtil';
import { useAuth } from '../../context/auth';

interface TerminalListProps {
  activeLab: Lab | null;
  resources?: Resource[];
  errorHandler?: (error: string) => void;
}
interface GuacTerminalProps {
  resource: Resource;
  index: string;
  totalIps: number;
  errorHandler?: (error: string) => void;
}

// We are going to create some custom events to control the remote copy/paste process
const subscribeToRemoteCopy = (listener: (e: any) => void) => {
  window.addEventListener('copyToRemote', listener);
};
const unsubscribeFromRemoteCopy = (listener: (e: any) => void) => {
  window.addEventListener('copyToRemote', listener);
};

const guac: any = {};

const Terminal = ({ resource, index, totalIps, errorHandler }: GuacTerminalProps) => {
  // Hide the terminal on load to resize it
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const terminalRef = useRef<HTMLDivElement>(null);
  const keyboardRef = useRef<HTMLInputElement>(null);
  const auth = useAuth();
  const { ip, activeTab } = resource;

  const jwt = auth.getJwt();

  useEffect(() => {
    if (jwt) {
      guac[index] = new GuacConnection(`${process.env.REACT_APP_CLAB_API_URL?.replace('http', 'ws')}/tunnel-ws`, {});
    }
  }, [jwt]);

  useEffect(() => {
    const display = guac[index].client.getDisplay();
    const hasTerminalElement = terminalRef && terminalRef.current;
    const containerElement = containerRef.current;

    if (display && hasTerminalElement && ip && containerElement && jwt) {
      const width = Math.round(containerElement.clientWidth);
      const height = Math.round(containerElement.clientHeight);

      // const instanceId = resource['instance-id'] || resource.instanceId || null;
      const connectStringWithDimensions = `${resource['connect-string']}&width=${width}&height=${height}&token=${jwt}`;
      guac[index].connect(connectStringWithDimensions, ip, errorHandler);
      // guac[index].connect({ ip, width, height, sessionId: sessionId || '0', protocol, loginPassword: password, instanceId });
      terminalRef.current.appendChild(display.getElement());

      guac[index].startKeyboardListener();
      guac[index].startMouseListener();
    }
    return () => {
      console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE TERMINAL', index, ip);
      return guac[index].client.disconnect();
    };
  }, [ip, jwt]);

  const remoteCopyListener = (e: any) => {
    const { detail } = e || {};
    const { str } = detail || {};
    if (index && str && guac && guac[index]) {
      guac[index].sendToClipboard(str);
    }
  };

  /**
   * Resize onload and when more terminals start
   */
  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      setTimeout(() => {
        guac[index].resizeTerminal(containerElement);
        setShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      window.addEventListener(
        'resize',
        throttle(() => guac[index].resizeTerminal(containerRef.current), 3000, { leading: false, trailing: true })
      );
    }
    return () => window.removeEventListener('resize', guac[index].resizeTerminal);
  }, []);

  useEffect(() => {
    if (resource.activeTab && resource.connected) {
      subscribeToRemoteCopy(remoteCopyListener);
      return () => unsubscribeFromRemoteCopy(remoteCopyListener);
    }
    return () => null;
  }, []);

  return (
    <div className={`absolute top-0 left-0 h-full w-full ${activeTab || totalIps === 1 ? '' : 'hidden'}`}>
      {/* <div className="z-30 flex h-8 items-center bg-gray-800 px-3 text-white">
        <p>
          {name} {ip}
        </p>
        <div className="ml-auto flex items-center gap-4">
          <Tippy
            trigger="click"
            placement="bottom"
            interactive
            offset={[0, 0]}
            content={
              <div className="mt-2 flex max-w-[200px] flex-col rounded bg-gray-800">
                {allResources.map((item) => (
                  <button
                    key={item.ip}
                    type="button"
                    onClick={() => switchResource(item)}
                    className={`flex cursor-pointer items-center py-1 pl-8 pr-6 text-left text-white hover:bg-gray-600 ${item.activeTab ? 'bg-gray-600' : ''}`}
                  >
                    <div className={`-ml-5 mr-2 h-2 w-2 rounded-full ${item.connected ? 'bg-green-600' : 'bg-gray-900'} `} /> {item.name}
                  </button>
                ))}
              </div>
            }
          >
            <div className="flex cursor-pointer items-center">
              <p className="text-xs">Resources</p>
              <ChevronDown className="ml-1 h-4 w-4" />
            </div>
          </Tippy>
          <button type="button" className="text-xs" onClick={() => disconnect(resource)}>
            Disconnect
          </button>
        </div>
      </div> */}
      <div
        // className="relative h-[500px] w-full border border-gray-500 md:h-full md:max-h-[calc(100vh-44px)] md:min-h-[calc(100vh-44px)]"
        className="relative min-h-[500px] w-full h-full"
        ref={containerRef}
        onClick={() => keyboardRef.current?.focus()}
      >
        <input id={ip} ref={keyboardRef} value="" onChange={(e) => console.log(e)} className="absolute top-10 left-10 border-0 bg-gray-800  outline-none focus:outline-none" />
        <div className={`relative z-50 h-full w-full ${ip && show ? 'visible' : 'invisible'}`} ref={terminalRef} />
      </div>
    </div>
  );
};

const TerminalList = ({ activeLab, resources, errorHandler }: TerminalListProps) => {
  const connectedResources = getConnected(resources || activeLab?.resources);
  const connectedTotal = getConnected(resources || activeLab?.resources).length;
  return (
    <>
      {!connectedTotal ? <p className="flex h-full items-center justify-center text-white">Connect to a resource to get started!</p> : null}

      {connectedResources.map((rscr) => (
        <Terminal key={rscr.ip} resource={rscr} index={rscr.ip} totalIps={connectedTotal} errorHandler={errorHandler} />
      ))}
    </>
  );
};

export default TerminalList;
