interface TextAreaProps {
  formik: any;
  name: string;
  id: string;
  hasError?: boolean;
  placeholder?: string;
  rows?: number;
}

const Textarea = ({ formik, name, id, placeholder, hasError, rows = 3 }: TextAreaProps) => {
  const classes = hasError
    ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    : 'focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby': `${id}-error` } : { 'aria-describedby': `${id}-description` };

  return (
    <textarea
      {...formik.getFieldProps(name)}
      {...ariaProps}
      className={`block w-full rounded-md border shadow-sm sm:text-sm ${classes}`}
      id={id}
      name={name}
      rows={rows}
      placeholder={placeholder}
    />
  );
};

export default Textarea;
