import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest, postApiRequest } from '../../agents';
import Form from '../../components/Form/form';

const AdminSetting = () => {
  const [loading, setLoading] = useState(true);
  const [setting, setSetting] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const loadSetting = async () => {
    setLoading(true);
    if (id) {
      try {
        const result = await getApiRequest(`/config?configurationId=${id}`);
        setSetting(result);
      } catch (error) {
        Notify.handleErrorResponse(error);
      }
    }
    setLoading(false);
  };

  /**
   * Load the setting
   */
  useEffect(() => {
    loadSetting();
  }, [id]);

  // Grab the default values for our form
  const name = setting?.name || '';
  const value = setting?.value || '';
  const description = setting?.description || '';

  const settingForm = {
    initialValues: {
      name,
      description,
      value,
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable(),
      description: Yup.string().nullable(),
      value: Yup.string().required('You must provide a value.'),
    }),
    fields: [
      {
        type: 'text',
        label: 'Name',
        name: 'name',
        disabled: true,
      },
      {
        type: 'text',
        label: 'Value',
        name: 'value',
        helpText: 'For boolean values, just type true or false (lowercase).  Otherwise, enter the value that should be saved for this setting.',
      },
      {
        type: 'text',
        label: 'Description',
        name: 'description',
        placeholder: 'Provide a short description.',
      },
    ],
    noContainer: false,
    centerButton: false,
    observeChanges: true,
    onSubmit: async (values) => {
      const { description, value } = values;
      // Prepare our values
      const toPost = {
        ...setting,
        description,
        value,
      };

      try {
        // console.log(toPost, 'would save');
        await postApiRequest(`/config/`, toPost);
        // console.log(result, 'this is the result');
        navigate(`/admin/settings`);
        Notify.success('The setting was saved.');
      } catch (err) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Save Setting',
    },
  };

  return (
    <LoadingOrError loading={loading} error={null}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">Update Setting</h1>
              <p className="text-sm text-gray-400">Update this global setting.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              <div className="mb-3">
                <Form {...settingForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminSetting;
