import { useEffect } from 'react';
import { useAuth } from '../context/auth';

const AdminLogout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 font-inter text-sm leading-loose text-gray-600 transition-all duration-500 ease-linear">
      <div className="px-20 pt-16">
        <div className="m-auto mb-2 max-w-xl">
          <h2 className="mb-8 text-center text-2xl text-gray-700">Signing Out...</h2>
        </div>
      </div>
    </div>
  );
};

export default AdminLogout;
