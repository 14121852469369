/* eslint-disable react/jsx-filename-extension */
import { ClockIcon } from '@heroicons/react/outline';
import Box from './box.js';
import BonusFlag from './bonus-flag.js';
import { getColorFromScore } from '../../utils/labUtil';

const FlagBox = ({ title, 'points-available': pointsAvailable, bonus, time, className, onClick, 'points-earned': pointsEarned = 0 }) => {
  const bgColor = getColorFromScore(pointsAvailable, pointsEarned);
  return (
    <Box key={title} className={className} onClick={onClick}>
      <div className="flex justify-between w-full">
        <div className="flex gap-x-2">
          <div className={`w-7 h-7 rounded-full ${bgColor}`} />
          <div className="text-left">
            <p className="text-xl font-semibold">{title}</p>
            {!!bonus && <BonusFlag />}
          </div>
        </div>
        <div className="shrink-0">
          <p className="text-xl text-right">
            Score:
            <span className="font-bold ml-2">
              {pointsEarned}/{pointsAvailable}
            </span>
          </p>
          <p className="text-xl text-right">
            <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
            {time}
          </p>
        </div>
      </div>
    </Box>
  );
};

export default FlagBox;
