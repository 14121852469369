import { Link, useParams, useNavigate } from 'react-router-dom';
import KeyEvent from '../../components/DashboardTables/KeyBoardHistory';
import LabState from '../../components/DashboardTables/LabState';
import ResourceStatus from '../../components/DashboardTables/ResourceStatus';
import ChevronLeft from '../../components/Icons/ChevronLeft';

const Dashboard = () => {
  const params = useParams();
  const { sessionId } = params;
  const navigate = useNavigate();

  if (!sessionId) {
    navigate('/dashboard/sessions');
  }

  return (
    <div className="py-5 px-8">
      <Link to="/dashboard/sessions" className="mb-8 -ml-1 flex items-center hover:text-black hover:underline">
        <ChevronLeft className="mr-2 h-4 w-4" /> View User Sessions
      </Link>

      <h1 className="mb-5 text-2xl">Dashboard Page</h1>

      <LabState sessionId={sessionId} />
      <KeyEvent sessionId={sessionId} />
      <ResourceStatus sessionId={sessionId} />
    </div>
  );
};
export default Dashboard;
