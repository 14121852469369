import { useState, useEffect } from 'react';

interface TimeInputProps {
  formik: any;
  name: string;
  hasError?: boolean;
}

const TimeInput = ({ formik, name, hasError }: TimeInputProps) => {
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [ampm, setAmpm] = useState('');
  const [changed, setChanged] = useState(false);
  const classes = hasError ? 'border-red-300 text-red-900' : 'border-gray-300';
  const defaultValue = formik.initialValues[name];

  useEffect(() => {
    // Separate into the parts
    if (defaultValue) {
      const timeParts = defaultValue.split(' ');
      const minuteHourParts = timeParts[0].split(':');
      const newHour = minuteHourParts[0];
      const newMinute = minuteHourParts[1];
      const newAmpm = timeParts[1];
      setHour(newHour);
      setMinute(newMinute);
      setAmpm(newAmpm);
    }
  }, [defaultValue]);

  // Set the value IF we have all three elements selected
  useEffect(() => {
    if (changed) {
      if (hour !== '' && minute !== '' && ampm !== '') {
        formik.setFieldValue(name, `${hour}:${minute} ${ampm}`);
      } else {
        formik.setFieldValue(name, '');
      }
    }
  }, [hour, minute, ampm, changed]);

  const changeHour = (e: any) => {
    setHour(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };
  const changeMinute = (e: any) => {
    setMinute(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };
  const changeAmpm = (e: any) => {
    setAmpm(e.target.value);
    if (!changed) {
      setChanged(true);
    }
  };

  const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minuteOptions = ['00', '15', '30', '45'];
  const ampmOptions = ['AM', 'PM'];

  return (
    <div className="flex justify-start">
      <div>
        <div className="flex">
          <select name={`${name}_hours`} className={`appearance-none border bg-transparent text-sm outline-none ${classes} rounded`} onChange={changeHour}>
            <option value="">--</option>
            {hourOptions.map((opt) => {
              const val = `${opt}`;
              const selectedProp = hour === val ? { selected: true } : {};
              return (
                <option key={val} value={val} {...selectedProp}>
                  {val}
                </option>
              );
            })}
          </select>
          <span className="mx-3 mt-1 text-xl">:</span>
          <select name={`${name}_minutes`} className={`appearance-none border bg-transparent text-sm outline-none ${classes} mr-4 rounded`} onChange={changeMinute}>
            <option value="">--</option>
            {minuteOptions.map((val) => {
              const selectedProp = minute === val ? { selected: true } : {};
              return (
                <option key={val} value={val} {...selectedProp}>
                  {val}
                </option>
              );
            })}
          </select>
          <select name={`${name}_ampm`} className={`appearance-none border bg-transparent text-sm outline-none ${classes} rounded`} onChange={changeAmpm}>
            <option value="">--</option>
            {ampmOptions.map((val) => {
              const selectedProp = ampm === val ? { selected: true } : {};
              return (
                <option key={val} value={val} {...selectedProp}>
                  {val}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default TimeInput;
