import { useState, useEffect } from 'react';
import { getApiRequest } from '../../../agents';
import AddButton from '../../Buttons/add-button';
import FormModal from '../../Overlays/form-modal';
import { ChoiceOption } from '../../../types';

interface CheckboxesProps {
  formik: any;
  name: string;
  id: string;
  hasError?: boolean;
  title: string;
  subtitle?: string;
  options?: ChoiceOption[];
  optionsUrl?: string;
  addForm?: any;
  addButtonText?: string;
  addTitle?: string;
  addBody?: string;
  getOptionsUrl?: (formik: any) => string;
  dynamicOptions?: (formik: any, localOptions: ChoiceOption[], setLocalOptions: React.Dispatch<React.SetStateAction<ChoiceOption[]>>) => ChoiceOption[];
}

interface AddHandlerProps {
  addForm?: any;
  addButtonText?: string;
  addTitle: string;
  addBody: string;
  loadOptions: (url?: string) => void;
}

const AddHandler = ({ addButtonText, addForm, loadOptions, addTitle, addBody }: AddHandlerProps) => {
  const [showModal, setShowModal] = useState(false);
  if (!addForm) {
    return null;
  }

  const addFormWithSuccess = {
    ...addForm,
    successHandler: (_values: any, actions: any) => {
      actions.resetForm();
      setShowModal(false);
      // Reload the options
      loadOptions();
    },
  };

  return (
    <>
      <AddButton onClick={() => setShowModal(true)} buttonText={addButtonText} />
      <FormModal form={addFormWithSuccess} open={showModal} cancel={() => setShowModal(false)} title={addTitle} body={addBody} />
    </>
  );
};

const Checkboxes = ({ formik, name, id, hasError, options, optionsUrl, addForm, addButtonText, addTitle, addBody, getOptionsUrl, dynamicOptions }: CheckboxesProps) => {
  const [localOptions, setLocalOptions] = useState<ChoiceOption[]>([]);
  const [lastOptionsUrl, setLastOptionsUrl] = useState<string | null>(null);

  const remoteLoadOptions = async (url?: string) => {
    setLastOptionsUrl(url || null);
    if (!url) {
      setLocalOptions([]);
      return;
    }
    try {
      const results = await getApiRequest(url);
      if (results.options) {
        setLocalOptions(results.options.map((option: ChoiceOption) => ({ value: `${option.value}`, label: option.label })));
      }
    } catch (err) {}
  };

  useEffect(() => {
    // If the options url is derived, we want to derive it, skip this step
    if (getOptionsUrl) {
      return;
    }
    if (options) {
      setLocalOptions(options);
    } else if (optionsUrl) {
      remoteLoadOptions(optionsUrl);
    }
  }, [optionsUrl, options, setLocalOptions, getOptionsUrl]);

  // In case we have dynamic options, we need to potentially re-render them each time.  Pass existing options and form values to the function
  useEffect(() => {
    if (dynamicOptions) {
      dynamicOptions(formik, localOptions, setLocalOptions);
    }
  });

  // If the local options url has changed, it means that we are deriving it.  If it's new, load options
  const localOptionsUrl = getOptionsUrl && getOptionsUrl(formik);
  useEffect(() => {
    if (getOptionsUrl && lastOptionsUrl !== localOptionsUrl) {
      remoteLoadOptions(localOptionsUrl);
    }
  }, [localOptionsUrl, getOptionsUrl]);

  const titleClasses = hasError ? 'font-medium text-red-600' : 'font-medium text-gray-700';
  const currentFieldValue = formik.values[name];
  const hasAdd = !!addForm && !!addTitle && !!addBody;
  return (
    <fieldset className="mt-1 space-y-2">
      {localOptions.map(({ value, label, subtitle }, i) => {
        const optionId = `${id}_${i}`;
        const ariaProps = subtitle ? { 'aria-describedby': `${optionId}-subtitle` } : { 'aria-describedby': id };
        const isChecked = currentFieldValue && currentFieldValue.length && currentFieldValue.indexOf(value) !== -1;
        return (
          <div key={optionId} className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                type="checkbox"
                {...formik.getFieldProps(name)}
                {...ariaProps}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                id={optionId}
                name={name}
                value={value}
                checked={isChecked}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={optionId} className={titleClasses}>
                {label}
              </label>
              {!!subtitle && (
                <p id={`${optionId}-subtitle`} className="text-gray-500">
                  {subtitle}
                </p>
              )}
            </div>
          </div>
        );
      })}
      {hasAdd && <AddHandler loadOptions={remoteLoadOptions} addForm={addForm} addButtonText={addButtonText} addTitle={addTitle} addBody={addBody} />}
    </fieldset>
  );
};

export default Checkboxes;
