import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest, postApiRequest } from '../../agents';
import Form from '../../components/Form/form';
import { findIndexById, findById } from '../../utils/helpers';

const AdminResource = () => {
  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const findAttributeValue = (attributes, name, defaultValue = '') => {
    const val = findById(attributes, name, 'key', defaultValue);
    return val?.value || defaultValue;
  };

  const loadResource = async () => {
    setLoading(true);
    if (id) {
      try {
        const result = await getApiRequest(`/lab/resource/${id}`);
        setResource(result);
      } catch (error) {
        Notify.handleErrorResponse(error);
      }
    }
    setLoading(false);
  };

  /**
   * Load the resource
   */
  useEffect(() => {
    loadResource();
  }, [id]);

  // Grab the default values for our form
  const name = resource?.name || '';
  const resourceType = resource?.resourceType || 't3.small';
  const resourceProvider = resource?.resourceProvider || 'AWS';
  const description = resource?.description || '';

  const configuration = resource?.configuration || {};
  const protocol = configuration?.protocol || 'vnc';
  const keyPair = configuration?.keyPair || '';
  const ami = configuration?.ami || '';
  const loginUser = configuration?.loginUser || '';
  const loginPassword = configuration?.loginPassword || '';
  const configAttributes = configuration?.attributes || [];

  const attributes = resource?.attributes || [];
  const standby = findAttributeValue(attributes, 'qty-standby', '0');
  const port = findAttributeValue(configAttributes, 'port', '');
  const security = findAttributeValue(configAttributes, 'security', '');

  // const defaultResources = [];
  // // We are tracking resource IDs due to a temporary backend issue that is spamming duplicate resources on the GET route...
  // const defaultResourceIds = [];
  // for (let i = 0; i < resources.length; i++) {
  //   const r = resources[i];
  //   if (defaultResourceIds.indexOf(r.labResourceId) === -1) {
  //     defaultResourceIds.push(r.labResourceId);
  //     const { configuration } = r;
  //     defaultResources.push({
  //       id: uuidv4(),
  //       value: {
  //         name: r.name,
  //         description: r.description,
  //         resource: r.labResourceId,
  //         configuration: configuration.labResourceConfigurationId,
  //       },
  //     });
  //   }
  // }

  const resourceForm = {
    initialValues: {
      name,
      description,
      standby,
      resourceType,
      resourceProvider,
      ami,
      keyPair,
      protocol,
      port,
      security,
      loginUser,
      loginPassword,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('You must name this resource.'),
      description: Yup.string().required('Please provide a brief description.'),
      standby: Yup.string().required('Please choose a standby value.'),
      resourceType: Yup.string().required('You must select a resource type.'),
      resourceProvider: Yup.string().required('Please choose a provider.'),
      ami: Yup.string().required('You must enter an AMI.'),
      keyPair: Yup.string().nullable(),
      protocol: Yup.string().required('Please choose a protocol.'),
      port: Yup.string().nullable(),
      security: Yup.string().nullable(),
      loginUser: Yup.string().nullable(),
      loginPassword: Yup.string().nullable(),
    }),
    fields: [
      {
        type: 'text',
        label: 'Name',
        name: 'name',
        placeholder: 'Provide a unique name to help identify this resource in lists.',
      },
      {
        type: 'text',
        label: 'Description',
        name: 'description',
        placeholder: 'Provide a short description.',
      },
      {
        type: 'select',
        label: 'Resource Provider',
        name: 'resourceProvider',
        options: [
          {
            value: 'AWS',
            label: 'AWS',
          },
          {
            value: 'GCP',
            label: 'GCP',
          },
        ],
      },
      {
        type: 'select',
        label: 'Resource Type',
        name: 'resourceType',
        options: [
          {
            value: 't2.small',
            label: 't2.small',
          },
          {
            value: 't2.large',
            label: 't2.large',
          },
          {
            value: 't2.xlarge',
            label: 't2.xlarge',
          },
          {
            value: 't3.small',
            label: 't3.small',
          },
          {
            value: 't3.large',
            label: 't3.large',
          },
          {
            value: 't3.xlarge',
            label: 't3.xlarge',
          },
        ],
        helpText: 'To create a lab, but keep it private while it is in development, choose DISABLED.',
      },
      {
        type: 'select',
        label: 'Minimum Standby',
        name: 'standby',
        options: [
          {
            value: '0',
            label: '0',
          },
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
        ],
        helpText: 'This value determines how many instances of this lab resource to keep on standby at all times (auto created).',
      },
      {
        type: 'select',
        label: 'Protocol',
        name: 'protocol',
        options: [
          {
            value: 'vnc',
            label: 'vnc',
          },
          {
            value: 'rdp',
            label: 'rdp',
          },
          {
            value: 'ssh',
            label: 'ssh',
          },
        ],
      },
      {
        type: 'text',
        label: 'Port',
        name: 'port',
        placeholder: 'Leave blank for default.',
      },
      {
        type: 'select',
        label: 'Security',
        name: 'security',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'any',
            label: 'any (default)',
          },
          {
            value: 'rdp',
            label: 'rdp',
          },
          {
            value: 'nla',
            label: 'nla',
          },
          {
            value: 'tls',
            label: 'tls',
          },
        ],
        showIf: ({ values }) => values?.protocol === 'rdp',
        helpText: 'This should only be set if you are configuring a resource using the rdp protocol. Leave blank for default.',
      },
      {
        type: 'text',
        label: 'AMI',
        name: 'ami',
      },
      {
        type: 'text',
        label: 'Key Pair',
        name: 'keyPair',
        helpText: 'This is only required if you are configuring a resource using the ssh protocol.',
      },
      {
        type: 'text',
        label: 'Login User',
        name: 'loginUser',
        helpText: 'Optional, only provide if required to access this resource.',
      },
      {
        type: 'text',
        label: 'Login Password',
        name: 'loginPassword',
        helpText: 'Optional, only provide if required to access this resource.',
      },
    ],
    noContainer: false,
    centerButton: false,
    observeChanges: true,
    onSubmit: async (values) => {
      const { name, description, standby, resourceType, resourceProvider, ami, keyPair, protocol, loginUser, loginPassword, port, security } = values;
      // Check to see that we have a key pair if protocol is ssh
      if (protocol === 'ssh' && !keyPair) {
        Notify.error('You must enter a Key Pair if you are selecting ssh as the protocol.');
        return;
      }

      // Prepare our attributes
      const attributes = resource?.attributes || [];
      const standbyIndex = findIndexById(attributes, 'qty-standby', 'key');
      if (standbyIndex === -1) {
        attributes.push({
          key: 'qty-standby',
          value: standby,
        });
      } else {
        attributes[standbyIndex].value = standby;
      }
      const portIndex = findIndexById(configAttributes, 'port', 'key');
      if (port && portIndex === -1) {
        configAttributes.push({
          key: 'port',
          value: port,
          identifier: uuidv4(),
        });
      } else if (portIndex !== -1) {
        configAttributes[portIndex].value = port;
      }
      const securityIndex = findIndexById(configAttributes, 'security', 'key');
      if (security && securityIndex === -1) {
        configAttributes.push({
          key: 'security',
          value: security,
          identifier: uuidv4(),
        });
      } else if (securityIndex !== -1) {
        configAttributes[securityIndex].value = security;
      }

      const newConfiguration = {
        ...configuration,
        ami,
        keyPair,
        protocol,
        loginUser,
        loginPassword,
        attributes: configAttributes,
      };
      // If this is a new configuration, give it an identifier
      if (!newConfiguration.identifier) {
        newConfiguration.identifier = uuidv4();
      }

      const toPost = {
        ...resource,
        name,
        description,
        resourceType,
        resourceProvider,
        attributes,
        configuration: newConfiguration,
      };

      if (!toPost.identifier) {
        toPost.identifier = uuidv4();
      }
      try {
        // console.log(toPost, 'would save');
        const result = await postApiRequest(`/lab/resource`, toPost);
        // console.log(result, 'this is the result');
        if (!id) {
          navigate(`/admin/resource/${result.labResourceId}`);
        } else {
          loadResource();
        }
        Notify.success('The resource was saved.');
      } catch (err) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Save Resource',
    },
  };

  const isCreating = !id;

  return (
    <LoadingOrError loading={loading} error={null}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">{isCreating ? 'Create' : 'Update'} Resource</h1>
              <p className="text-sm text-gray-400">{isCreating ? 'Configure and create a new' : 'Update this existing'} lab resource.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              <div className="mb-3">
                <Form {...resourceForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminResource;
