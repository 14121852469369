import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { BeakerIcon, PencilIcon } from '@heroicons/react/solid';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest } from '../../agents';
import SimplePaginator from '../../components/Common/simple-paginator.js';
import SmallButton from '../../components/Common/small-button.js';
import Search from '../../components/Common/search.js';

const AdminUsers = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);

  const limit = 20;

  const getUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getApiRequest(`/admin/account/all?page=${page}&size=${limit}`);
      setUsers(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  const searchUsers = async (q) => {
    if (!q) {
      await getUsers();
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const searchString = queryString.stringify({ q, page, size: limit });
      const result = await getApiRequest(`/admin/account/search?${searchString}`);
      setUsers(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the users
   */
  useEffect(() => {
    getUsers();
  }, [page]);

  const from = page * limit + 1;
  const to = from + users.length - 1;

  return (
    <div className="px-4 lg:px-8">
      <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
        <div className="mt-10 flex justify-between">
          <div>
            <h1 className="text-3xl font-bold mb-1">Users</h1>
            <p className="text-sm text-gray-400">All system users.</p>
          </div>
          <div>
            <a href="/admin/user" className="bg-indigo-600 hover:bg-indigo-700 text-white inline-flex rounded-md border px-4 py-2 text-sm font-medium shadow-sm">
              Create New User
            </a>
          </div>
        </div>
        <div className="py-5 w-96">
          <Search placeholder="Search users..." handleSearch={searchUsers} />
        </div>
        <LoadingOrError loading={loading} error={error}>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              {!users.length && <p className="text-sm text-gray-700">There are results to display.</p>}
              {!!users.length &&
                users.map((user) => (
                  <div key={user.accountGuid} className="my-1 w-full rounded border border-gray-300 py-2 px-5 relative">
                    <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
                      <div className="flex items-center text-sm">
                        <p className="mr-2 text-gray-500">Email:</p>
                        <p className="mr-2 font-medium">{user.email}</p>
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="mr-2 text-gray-500">Name:</p>
                        <p className="font-medium">{user.accountName}</p>
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="mr-2 text-gray-500">Role(s):</p>
                        <p className="font-medium">{user?.accountSubscriptions?.join(', ')}</p>
                      </div>
                    </div>
                    <div className="absolute top-2 right-2 space-x-2">
                      <SmallButton href={`/admin/user/${user.accountId}/sessions`}>
                        <BeakerIcon className="h-4" />
                      </SmallButton>
                      <SmallButton href={`/admin/user/${user.accountId}`}>
                        <PencilIcon className="h-4" />
                      </SmallButton>
                    </div>
                  </div>
                ))}
            </div>
            <SimplePaginator
              from={from}
              to={to}
              previousUrl={page === 0 ? null : () => setPage(page - 1)}
              nextUrl={users.length < limit ? null : () => setPage(page + 1)}
              useButtons
            />
          </div>
        </LoadingOrError>
      </div>
    </div>
  );
};

export default AdminUsers;
