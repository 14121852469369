import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DashboardEvent } from '../../agents';

interface ResourceStatusProps {
  sessionId: string | undefined;
}

const columns = [
  {
    name: 'Id',
    selector: (row: any) => row.labResourceId,
  },
  {
    name: 'Uuid',
    selector: (row: any) => row.identifier,
  },
  {
    name: 'Session Id',
    selector: (row: any) => row.sessionId,
  },
  {
    name: 'Project Name',
    selector: (row: any) => row.projectName,
  },
  {
    name: 'Instance Name',
    selector: (row: any) => row.instanceName,
  },
  {
    name: 'Desired State',
    selector: (row: any) => row.desiredState,
  },
  {
    name: 'Current State',
    selector: (row: any) => row.currentState,
  },
  {
    name: 'Time',
    selector: (row: any) => row.timestamp,
  },
];

const ResourceStatus = ({ sessionId }: ResourceStatusProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchResourceStatusResponse = async () => {
      const resourceStatusResponse = await DashboardEvent.getResourceStatusHistory(sessionId || '');
      setData(resourceStatusResponse);
    };
    fetchResourceStatusResponse();
  }, [sessionId]);

  return (
    <div className="mt-12">
      <div className="rounded-sm border">
        <DataTable title="Resource Status" columns={columns} data={data} />
      </div>
    </div>
  );
};

export default ResourceStatus;
