import { useState, useEffect } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import queryString from 'query-string';
import { Infra } from '../../agents';
import { FlagsMapType } from '../../types/labs';

interface DebugOptionsType {
  activityId?: number;
  testMode?: boolean;
  auditMode?: boolean;
}

interface DebugControlsProps {
  labId: string | number;
  activities: FlagsMapType;
  containerClassName?: string;
  onChange: (query: string) => void;
  showAuditLogs: () => void;
}

const DebugControls = ({ labId, activities, containerClassName = '', onChange, showAuditLogs }: DebugControlsProps) => {
  const [debugOpts, setDebugOpts] = useState<DebugOptionsType>({});

  useEffect(() => {
    const params = queryString.stringify(debugOpts);
    const query = params.length ? `?${params}` : '';
    onChange(query);
  }, [debugOpts]);

  return (
    <div className={containerClassName}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <label htmlFor="activity">Activity:</label>
          <select
            id="activity"
            className="text-xs ml-1 text-gray-600"
            placeholder="Test a single activity"
            value={debugOpts.activityId}
            onChange={(e) =>
              setDebugOpts((currOpts) => {
                return { ...currOpts, activityId: parseInt(e.target.value, 10) };
              })
            }
          >
            <option value={0}>All Activities</option>
            {Object.keys(activities).map((activityId: string) => {
              const activity = activities[activityId];
              const id = activity['activity-id'];
              return (
                <option value={id} key={id}>
                  {`${activity.name} (id: ${id})`}
                </option>
              );
            })}
          </select>
        </div>
        <button
          className="bg-red-500 text-white py-2 px-4 text-right rounded"
          type="button"
          onClick={() => {
            Infra.restartLab(labId).then(() => {
              window.location.reload();
            });
          }}
        >
          <RefreshIcon className="inline w-3 h-3" />
        </button>
      </div>
      <div className="flex items-center justify-between pt-4 pb-2">
        <div className="flex items-center">
          <label htmlFor="test-mode">Demo Mode:</label>
          <input
            id="test-mode"
            type="checkbox"
            className="h-4 w-4 ml-2 rounded border-gray-300"
            checked={!!debugOpts.testMode}
            onChange={() =>
              setDebugOpts((currOpts) => {
                return { ...currOpts, testMode: !debugOpts.testMode };
              })
            }
          />
        </div>
        <div className="flex items-center">
          <label htmlFor="test-mode">Audit Mode:</label>
          <input
            id="audit-mode"
            type="checkbox"
            className="h-4 w-4 ml-2 rounded border-gray-300"
            checked={!!debugOpts.auditMode}
            onChange={() =>
              setDebugOpts((currOpts) => {
                return { ...currOpts, auditMode: !debugOpts.auditMode };
              })
            }
          />
        </div>
        <button className="bg-teal-600 text-white py-2 px-4 text-right rounded" type="button" onClick={showAuditLogs}>
          Show Audit
        </button>
      </div>
    </div>
  );
};

export default DebugControls;
