import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';

interface AddButtonProps {
  buttonText?: string;
  onClick: () => void;
}

const AddButton = ({ onClick, buttonText = 'Add' }: AddButtonProps) => {
  return (
    <button type="button" onClick={onClick} className="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500">
      <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
        <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
      </span>
      <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">{buttonText}</span>
    </button>
  );
};

export default AddButton;
