import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import AppRoutes from './AppRoutes';
import { AuthProvider } from './context/auth';

const App = () => {
  return (
    <div>
      <ReactNotifications />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </div>
  );
};

export default App;
