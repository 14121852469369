import { ClockIcon } from '@heroicons/react/outline';
import { useTimer } from 'react-timer-hook';
import { padZeros } from '../../utils/labUtil';

const CountdownTimer = ({ expires }) => {
  const { isRunning, hours, minutes, seconds } = useTimer({ expiryTimestamp: expires, autoStart: true });

  if (!isRunning) {
    return null;
  }

  const time = `${padZeros(hours)}:${padZeros(minutes)}:${padZeros(seconds)}`;
  return (
    <p className="text-lg text-gray-100 font-mono">
      <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
      {time}
    </p>
  );
};

export default CountdownTimer;
