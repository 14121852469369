import { DEFAULT_COLOR } from '../../utils/constants/status';

interface StatusBadgeProps {
  text: string | undefined;
  colors: { text: string; ball: string; bg: string };
}

export const StatusBadge = ({ text, colors }: StatusBadgeProps) => {
  const { bg, ball, text: textColor } = colors || DEFAULT_COLOR;
  return (
    <div className={`flex rounded-full items-center ${bg} py-1 px-2`}>
      <div className={`mr-1 h-3 w-3 rounded-full ${ball}`} />
      <p className={`text-xs font-medium capitalize ${textColor}`}>{text || 'Pending'}</p>
    </div>
  );
};

export default StatusBadge;
