import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { findIndexById } from '../../utils/helpers';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const SkillsSummary = ({ skills, graphContainerClassName = '' }) => {
  const labels = skills.map((skill) => [skill.name, `${Math.round(skill.percent)}%`]);
  const data = skills.map((skill) => Math.round(skill.percent));

  const dataForGraph = {
    // labels: [
    //   ['SIEM', '45%'],
    //   ['Threat Intelligence', '90%'],
    //   ['Incident Management', '80%'],
    //   ['Network Traffic Analysis', '50%'],
    //   ['Malware Analysis', '50%'],
    //   ['Email Analysis', '60%'],
    // ],
    labels,
    datasets: [
      {
        label: 'Score',
        // data: [45, 90, 80, 50, 50, 60],
        data,
        // backgroundColor: 'rgba(255, 99, 132, 0.2)',
        backgroundColor: 'rgba(223, 0, 115, 0.1)',
        borderColor: 'rgba(223, 0, 115, 1)',
        borderWidth: 1,
        tension: 0,
      },
      // {
      //   label: 'Bonus',
      //   data: [4, 6, 3, 6, 8, 7],
      //   // backgroundColor: 'rgba(255, 99, 132, 0.2)',
      //   backgroundColor: 'rgba(59, 176, 25, 0.1)',
      //   borderColor: 'rgba(59, 176, 25, 1)',
      //   borderWidth: 1,
      //   tension: 0.1,
      //   // pointBackgroundColor: 'rgba(59, 176, 25, 1)',
      // },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (!context) {
              return '';
            }
            const label = context.dataset?.label || '';
            const skillIndex = findIndexById(skills, context.label[0], 'name');
            if (skillIndex === -1) {
              return label;
            }
            const skill = skills[skillIndex];
            const { totalCountEarned, totalCountAvailable } = skill;

            return `${label}: ${totalCountEarned}/${totalCountAvailable}`;
          },
        },
      },
    },
    scales: {
      r: {
        pointLabels: {
          color: 'rgba(245, 245, 245)',
          font: {
            size: 14,
          },
        },
        beginAtZero: true,
        max: 100,
        ticks: {
          display: false, // Hides the labels on the graph ticks
          stepSize: 20,
        },
        angleLines: {
          color: 'rgb(82, 82, 82)',
        },
        grid: {
          color: 'rgb(82, 82, 82)',
        },
      },
    },
  };

  return (
    <div>
      <p className="text-lg mb-4">Skills Summary</p>
      <div className={graphContainerClassName}>
        <Radar data={dataForGraph} options={options} />
      </div>
    </div>
  );
};

export default SkillsSummary;
