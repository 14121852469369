import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Lab, Resource, FlagType, PlaybookType, PlaybookResourcesType, FlagsMapType, AuditLogType, LabStatusEventPayload } from '../types/labs';
import { SocketConnection } from '../services/SocketConnection';
import { getApiRequest, Infra, Session } from '../agents';
import { findById, findIndexById } from '../utils/helpers';
import formatLabDetails, { convertSecsToHHMMSS } from '../utils/labUtil';

const useLearnerLab = (session?: string, token?: string, accountId?: number) => {
  const [lab, setLab] = useState<Lab | null>(null);
  const [userSession, setUserSession] = useState<any>(null);
  const [labSocket, setLabSocket] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [playbook, setPlaybook] = useState<PlaybookType | null>(null);
  const [summary, setSummary] = useState<object | null>(null);
  const [auditLogs, setAuditLogs] = useState<AuditLogType[] | null>(null);
  const [expires, setExpires] = useState<Date | null>(null);
  const [labComplete, setLabComplete] = useState<boolean>(false);
  const [resources, setResources] = useState<PlaybookResourcesType[] | []>([]);
  const [activeResource, setActiveResource] = useState<string | null>(null);
  const [flags, setFlags] = useState<FlagsMapType>({});
  const [completedFlags, setCompletedFlags] = useState<FlagType[]>([]);
  const [flagNotification, setFlagNotification] = useState<FlagType | null>(null);
  const [debugParams, setDebugParams] = useState('');

  // Connect to a given resource, based on ip
  const connect = (resource: PlaybookResourcesType) => {
    if (!resource) {
      return;
    }

    const indexName = resource.customResourceName || resource.name;

    let index = findIndexById(resources, indexName, 'customResourceName');
    if (index === -1) {
      index = findIndexById(resources, indexName, 'name');
    }
    if (index === -1) {
      return;
    }
    const newResources = [...resources];
    newResources.forEach((res) => {
      const comparisonName = res.customResourceName || res.name;
      if (indexName === comparisonName) {
        res.activeTab = true;
        setActiveResource(indexName);
        res.connected = true;
      } else {
        res.activeTab = false;
      }
    });
    setResources(newResources);
  };

  // Disconnect from a given resource, based on ip
  // Not currently being used -- Is this needed?
  const disconnect = (resource: Resource) => {
    if (!lab) {
      return;
    }
    const { resources } = lab;
    const indexName = resource.customResourceName || resource.name;
    let index = findIndexById(resources, indexName, 'customResourceName');
    if (index === -1) {
      index = findIndexById(resources, indexName, 'name');
    }
    if (index === -1) {
      return;
    }
    const newResources = [...resources];
    newResources[index].connected = false;
    newResources[index].activeTab = false;
    // setLab({
    //   ...lab,
    //   resources: newResources,
    // });
    setActiveResource(null);
    // @ts-ignore
    setResources(newResources);
  };

  // Switch to a new resource (marking others as not active)
  // Not currently being used -- Is this needed?
  const switchResource = (resource: Resource) => {
    if (!lab) {
      return;
    }
    const updatedLab = { ...lab } as Lab;

    updatedLab.resources = updatedLab.resources.map((rscr) => {
      return rscr.ip === resource.ip ? { ...resource, connected: true, activeTab: true } : { ...rscr, activeTab: false };
    });

    setLab(updatedLab);
  };

  const loadLab = async (uuid: string, session: string, forceLoading = false) => {
    setLoading(true);
    try {
      // Load our lab, and format it
      const labDetails = await Infra.getLabById(uuid);
      const { labId } = labDetails.labInstance;
      // Grab the definition as well
      const definition = await getApiRequest(`/lab/${labId}`);
      const formattedLab = formatLabDetails(labDetails, definition);
      formattedLab.sessionId = session;
      setLab(formattedLab);
      if (forceLoading) {
        // console.log(formattedLab, 'formatted lab, needs resources set', labDetails);
        // @ts-ignore
        setResources(formattedLab.resources || []);
        setLoading(false);
      }
    } catch (err: any) {
      console.log(err, 'error loading lab');
      setLoading(false);
      setError(err?.message || 'There was an unknown error loading the lab.');
    }
  };

  // const loadUserSession = async (session: string) => {
  const loadUserSession = async (uid: number, labInstanceIdentifier: string) => {
    try {
      // Load our lab, and format it
      const sessionResult = await Session.getSessionByUserAndLabId(uid, labInstanceIdentifier);
      if (sessionResult && sessionResult.userSessionId) {
        const sessionDetails = await Session.getSession(sessionResult.userSessionId);
        setUserSession(sessionDetails);
      }
    } catch (err: any) {
      console.log(err, 'error loading session');
    }
  };

  const beginLab = (id: string | number) => {
    // Reset our audit logs and summary
    setAuditLogs([]);
    setSummary(null);
    return Infra.beginLab(id, debugParams);
  };

  const endLab = async (id: string | number) => {
    // Reset our audit logs and summary, set error message to display
    setAuditLogs([]);
    setSummary(null);
    setLab(null);
    try {
      await Infra.deleteLab(id);
    } catch (err: any) {
      console.log(err, 'error ending lab');
    }
    setError('Your lab session has ended. Please close this window to return to Cybrary.');
  };

  const addFlag = (flag: FlagType) => {
    const flagCopy = { ...flag };
    flagCopy.time = convertSecsToHHMMSS(flagCopy['time-seconds-elapsed']);
    setFlags((currentFlags: FlagsMapType) => {
      const flagsCopy = { ...currentFlags };
      flagsCopy[flag['activity-id']] = flagCopy;
      return flagsCopy;
    });
    // This function is only ever called from a completed flag, so always add it to the completed flags array
    setCompletedFlags((currentCompletedFlags: FlagType[]) => {
      const newCompletedFlags = [...currentCompletedFlags];
      newCompletedFlags.push(flagCopy);
      return newCompletedFlags;
    });
    setFlagNotification(() => flagCopy);
  };

  const formatResources = (sources: PlaybookResourcesType[] = []) => {
    return sources.map((source) => {
      const connectData = source['connect-string'] ? queryString.parse(source['connect-string']) : {};
      return { ...source, ...connectData };
    });
  };

  const addPlaybook = (data: PlaybookType) => {
    // Only set these state vals if there isn't a value already -- Prevents un-needed set states if playbook is sent multiple times
    setPlaybook((currentPlaybook: PlaybookType | null) => (!currentPlaybook ? data : currentPlaybook));
    if (data?.module?.activities) {
      setFlags((currentFlags) => (!Object.keys(currentFlags).length ? data.module.activities : currentFlags));
    }
    setLoading(() => false);
    if (data?.resources) {
      const connectableResources = data.resources.filter((resource) => resource.connectable && resource['connect-string']);
      if (connectableResources && connectableResources.length) {
        const formattedResources = formatResources([...data.resources]);
        setResources((currentResources) => (!currentResources.length ? formattedResources : currentResources));
      }
    }
  };

  const addAuditLog = (data: any) => {
    const { payload, timestamp } = data;
    const { user, description, 'log-level': level } = payload;
    const { email, 'random-name': randomName } = user;

    const newLog = {
      timestamp,
      description,
      email,
      randomName,
      level,
    };

    setAuditLogs((currentAuditLogs: AuditLogType[] | null) => {
      const auditLogsCopy = currentAuditLogs ? [...currentAuditLogs] : [];
      auditLogsCopy.push(newLog);
      return auditLogsCopy;
    });
    // auditLogArray.push(newLog);
    // console.error(auditLogArray, 'the array of audit logs wtf is happening');
    // setAuditLogs(() => auditLogArray);
  };

  // Control our timer
  const startTimer = () => {
    const durationSeconds = playbook && playbook['time-limit-minutes'] ? playbook['time-limit-minutes'] * 60 : 30 * 60;
    // Going to hardcode a countdown from a few minutes
    const time = new Date();
    time.setSeconds(time.getSeconds() + durationSeconds);
    setExpires(time);
  };

  const handleLabComplete = (summaryData: object) => {
    setSummary(() => summaryData);
    setLabComplete(() => true);
    setExpires(() => null);
  };

  const handleResourceUpdate = (resources: PlaybookResourcesType[], eventPayload: LabStatusEventPayload) => {
    const { outputs, status: labStatus } = eventPayload;
    if (!resources || !resources.length || !outputs || !outputs.length) {
      return resources;
    }
    const isLabRunning = labStatus === 'running';
    // Ok, we have new status fields to update, let's create a new resources array and handle that
    const newResources = [...resources];
    for (let i = 0; i < newResources.length; i++) {
      const { labResourceIdentifier, status } = newResources[i];
      if (labResourceIdentifier) {
        const foundResource = findById(outputs, labResourceIdentifier, 'resourceIdentifier', null);
        if (foundResource && foundResource.status !== status) {
          // If the lab is NOT running, but our resource is, set the resource status to booting
          newResources[i].status = foundResource.status === 'running' && !isLabRunning ? 'booting' : foundResource.status;
        }
      }
    }
    return newResources;
  };

  const openLabSocket = () => {
    const socket = new SocketConnection(`${process.env.REACT_APP_CLAB_API_URL}/clab`);
    setLabSocket(socket);
    // start socket
    socket.subscribe('/user/topic/lab', (message) => {
      try {
        const msg = JSON.parse(message.body);
        // console.log('message type - ', msg.type);
        // console.log(msg);
        const { payload, type } = msg || {};
        // console.log(payload, type, 'payload and type');
        if (!payload) {
          return;
        }

        // Handle different message types received via WS
        switch (type) {
          case 'c2_playbook':
            addPlaybook(payload);
            break;
          case 'c2_flag':
            addFlag(payload);
            break;
          case 'c2_summary':
            if (payload.completed) {
              handleLabComplete(payload);
            }
            break;
          case 'c2_audit':
            // console.log('************ AUDIT LOG **************', payload, '(SHOW TIMESTAMP, LEVEL, DESCRIPTION, email, random-name)');
            addAuditLog(msg);
            break;
          case 'lab_status':
            // Update resource and lab statuses
            setResources((resources: PlaybookResourcesType[]) => {
              return handleResourceUpdate(resources, payload);
            });
            setLab((lab: Lab | null) => {
              return lab
                ? {
                    ...lab,
                    status: payload.status,
                  }
                : null;
            });
            break;
          default:
            break;
        }
        // Check for the terminate status
        if (payload.terminate) {
          setError(() => 'Your lab session has ended. Please close this window to return to Cybrary.');
        }
      } catch (e) {
        console.log(e, 'error parsing message');
      }
    });
    socket.connect(`${session}`, `${token}`, accountId);
  };

  useEffect(() => {
    if (lab && !labSocket) {
      openLabSocket();
    }
    // Disconnect if we have lost the lab and we still have a socket open
    if (!lab && labSocket) {
      console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE USE EFFECT', labSocket);
      labSocket.disconnect();
    }
  }, [lab]);

  useEffect(() => {
    return () => {
      if (labSocket) {
        console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE USE EFFECT EXIT FUNCTION', lab, labSocket);
        labSocket.disconnect();
      }
    };
  }, [labSocket]);

  return {
    loadLab,
    lab,
    loading,
    error,
    connect,
    disconnect,
    switchResource,
    beginLab,
    flags,
    completedFlags,
    flagNotification,
    playbook,
    resources,
    activeResource,
    expires,
    labComplete,
    startTimer,
    handleLabComplete,
    debugParams,
    setDebugParams,
    auditLogs,
    summary,
    endLab,
    loadUserSession,
    userSession,
  };
};

export default useLearnerLab;
