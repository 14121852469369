import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { slugify } from '../../../utils/helpers';

interface SlugProps {
  formik: any;
  type: string;
  name: string;
  id: string;
  hasError?: boolean;
  placeholder?: string;
}

const Slug = ({ formik, name, id, placeholder, hasError }: SlugProps) => {
  const classes = hasError
    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
    : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby': `${id}-error` } : { 'aria-describedby': `${id}-description` };

  const changeHandler = (e: any) => {
    const value = slugify(e.target.value);
    formik.setFieldValue(name, value);
  };

  return (
    <div className={wrapperClasses}>
      <input
        {...formik.getFieldProps(name)}
        {...ariaProps}
        className={`block w-full rounded-md sm:text-sm ${classes}`}
        id={id}
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={changeHandler}
      />
      {hasError && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export default Slug;
