interface LabelProps {
  id?: string;
  label?: string;
  hidden?: boolean;
}

const Label = ({ id, label, hidden }: LabelProps) => {
  if (!label) {
    return null;
  }
  const className = hidden ? 'sr-only' : 'block text-sm font-medium text-gray-700 mb-1';
  return (
    <label htmlFor={id} className={className}>
      {label}
    </label>
  );
};

export default Label;
