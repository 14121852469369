import NumberFormat from 'react-number-format';

interface DateProps {
  formik: any;
  name: string;
  id: string;
  hasError?: boolean;
}

interface ChangeType {
  value?: string;
  formattedValue?: string;
}

const DateInput = ({ formik, name, id, hasError }: DateProps) => {
  const classes = hasError
    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
    : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const valueChange = (val: ChangeType) => {
    const { formattedValue, value } = val;
    if (value === '') {
      formik.setFieldValue(name, null);
    } else {
      formik.setFieldValue(name, formattedValue);
    }
  };
  const defaultValue = formik.initialValues[name];
  return (
    <NumberFormat
      className={`block w-full rounded-md sm:text-sm ${classes}`}
      id={id}
      name={name}
      defaultValue={defaultValue || null}
      format="##/##/####"
      mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
      allowEmptyFormatting
      onValueChange={valueChange}
    />
  );
};

export default DateInput;
