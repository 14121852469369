import { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { NewLoginType, NewUserDetails, AccountDetails } from '../types';
import { onWhitelist } from '../utils/auth-whitelist';
import { getApiRequest } from '../agents';

const loginRequest = (data: NewLoginType) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_CLAB_API_URL}/admin/account/login`,
    data,
  });
};

const navigateLogin = (): void => {
  if (onWhitelist()) {
    return;
  }
  const loginPath = `${process.env.REACT_APP_BASE_PATH ? process.env.REACT_APP_BASE_PATH : ''}/new-login`;
  if (window.location.pathname !== loginPath) {
    window.location.href = loginPath;
  }
};

const isTokenValid = (user: NewUserDetails): boolean => {
  const expiresAt = moment.unix(user.expires).diff(moment());
  return expiresAt > 0;
};

const useNewProvider = () => {
  const [user, setUser] = useState<NewUserDetails | null>(null);
  const [account, setAccount] = useState<AccountDetails | null>(null);

  const getAccount = async () => {
    const result = await getApiRequest('/admin/account');
    const { accountId, accountName, email } = result;
    setAccount({
      id: accountId,
      name: accountName,
      email,
    });
  };

  const logout = () => {
    if (onWhitelist()) {
      return;
    }
    localStorage.removeItem('u');
    localStorage.removeItem('jwt');
    setUser(null);
    navigateLogin();
  };

  const login = async (data: NewLoginType) => {
    try {
      const result = await loginRequest(data);
      const response = result.data;
      const { accountGuid, token, expires } = response;
      if (accountGuid && token && expires) {
        const u = {
          id: accountGuid,
          token,
          expires,
        };
        localStorage.setItem('u', JSON.stringify(u));
        localStorage.setItem('jwt', token);
        await getAccount();
        setUser(u);
      } else {
        throw new Error('There was an error logging in.');
      }
    } catch (error) {
      logout();
      throw error;
    }
  };
  const getJwt = (): string => localStorage.getItem('jwt') || '';

  const loginFromLocal = async () => {
    if (onWhitelist()) {
      return;
    }

    const storedUser = localStorage.getItem('u') || '';
    if (!storedUser) {
      logout();
      return;
    }

    const u = JSON.parse(storedUser);

    if (isTokenValid(u)) {
      localStorage.setItem('jwt', u.token);
      await getAccount();
      setUser(u);
      return;
    }

    logout();
  };

  useEffect(() => {
    loginFromLocal();
  }, []);

  return { user, login, getJwt, logout, getAccount, account };
};

export default useNewProvider;
