import { useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';
import Notify from '../utils/notifications';
import Form from '../components/Form/form';
import { NewLoginType } from '../types';

const AdminLogin = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const loginForm = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('You must supply a valid email.').required('Email is required.'),
      password: Yup.string().required('Password is required'),
    }),
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
      },
    ],
    submit: {
      label: 'Login',
    },
    onSubmit: async (values: NewLoginType) => {
      try {
        await auth.login(values);
      } catch (err: any) {
        Notify.handleErrorResponse(err);
      }
    },
  };

  useEffect(() => {
    if (auth.user?.id) {
      navigate('/admin/dashboard');
    }
  }, [auth.user]);

  return (
    <div className="min-h-screen bg-gray-100 font-inter text-sm leading-loose text-gray-600 transition-all duration-500 ease-linear">
      <div className="px-20 pt-16">
        <div className="m-auto mb-2 max-w-xl">
          <h2 className="mb-8 text-center text-2xl text-gray-700">Login</h2>
          <Form {...loginForm} />
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
