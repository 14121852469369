import { useState } from 'react';
import { Infra } from '../agents';
import { TemplateType, TemplateResponse } from '../types/labs';
import { findIndexById } from '../utils/helpers';

const useTemplate = () => {
  const [templates, setTemplates] = useState<TemplateType[]>([]);

  /**
   * Get Lab Templates
   */
  const fetchTemplates = async () => {
    const templates = await Infra.getTemplates();
    setTemplates(templates);
  };

  /**
   * Handle Template Selected
   * @param option
   */
  const handleTemplateSelect = async (id: string | number, sessionName?: string): Promise<TemplateResponse | null> => {
    let templateResponse: TemplateResponse | null = null;
    templateResponse = await Infra.startTemplate(id, sessionName);
    return templateResponse;
  };

  /**
   * Update the CLIENT TRACKED STATUS for a given lab template
   * @param id
   * @param status
   */
  const updateTemplateClientStatus = (id: number | string, status: string) => {
    // Find the template in our array of templates
    const index = findIndexById(templates, id);
    if (index === -1) {
      return;
    }
    const newTemplates = [...templates];
    newTemplates[index].clientStatus = status;
    setTemplates(newTemplates);
  };

  return { fetchTemplates, templates, handleTemplateSelect, updateTemplateClientStatus };
};

export default useTemplate;
