import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest, postApiRequest, putApiRequest } from '../../agents';
import Form from '../../components/Form/form';

const AdminUser = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const loadUser = async () => {
    setLoading(true);
    if (id) {
      try {
        const result = await getApiRequest(`/admin/account/${id}`);
        setUser(result);
      } catch (error) {
        Notify.handleErrorResponse(error);
      }
    }
    setLoading(false);
  };

  /**
   * Load the definitions
   */
  useEffect(() => {
    loadUser();
  }, [id]);

  // Grab the default values for our form
  const accountName = user?.accountName || '';
  const email = user?.email || '';
  const roles = user?.accountSubscriptions?.length ? user.accountSubscriptions[0] : 'ADMIN';
  const password = '';

  const userForm = {
    initialValues: {
      accountName,
      email,
      roles,
      password,
    },
    validationSchema: Yup.object({
      accountName: Yup.string().required('Provide a name for the user.'),
      email: Yup.string().email('Please provide a valid email.').required('Email is required.'),
      // roles: Yup.array().min(1, 'You must select at least one role.'),
      roles: Yup.string().required('You must select a role.'),
      password: Yup.string().nullable(),
    }),
    fields: [
      {
        type: 'text',
        label: 'Name',
        name: 'accountName',
      },
      {
        type: 'email',
        label: 'Email',
        name: 'email',
      },
      {
        type: 'select',
        label: 'Roles',
        name: 'roles',
        options: [
          {
            value: 'ADMIN',
            label: 'ADMIN',
          },
          {
            value: 'CREATOR',
            label: 'CREATOR',
          },
          {
            value: 'CONTENTFUL_ADMIN',
            label: 'CONTENTFUL_ADMIN',
          },
          {
            value: 'SUPPORT',
            label: 'SUPPORT',
          },
          {
            value: 'LEARNER',
            label: 'LEARNER',
          },
        ],
      },
      {
        type: 'password',
        label: 'Password',
        name: 'password',
        placeholder: 'Leave blank to not change.',
      },
    ],
    noContainer: false,
    centerButton: false,
    observeChanges: true,
    onSubmit: async (values) => {
      const { accountName, email, roles, password } = values;

      const toPost = {
        ...user,
        accountName,
        email,
        accountSubscriptions: [roles],
      };
      if (password) {
        toPost.password = password;
      }
      if (!toPost.identifier) {
        toPost.identifier = uuidv4();
      }
      if (!toPost.accountGuid) {
        toPost.accountGuid = uuidv4();
      }
      if (!toPost.accountProvisions) {
        toPost.accountProvisions = ['CLAB'];
      }
      if (!toPost.accountProvisions.length) {
        toPost.accountProvisions.push('CLAB');
      }
      try {
        // console.log(toPost, 'would save');
        if (!id) {
          await postApiRequest(`/admin/account/register`, toPost);
        } else {
          await putApiRequest(`/admin/account`, toPost);
        }
        // console.log(result, 'this is the result');
        if (!id) {
          navigate(`/admin/users`);
        }
        Notify.success('The user was saved.');
      } catch (err) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Save User',
    },
  };

  const isCreating = !id;

  return (
    <LoadingOrError loading={loading} error={null}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">{isCreating ? 'Create' : 'Update'} User</h1>
              <p className="text-sm text-gray-400">{isCreating ? 'Create a new' : 'Update this existing'} user.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              <div className="mb-3">
                <Form {...userForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminUser;
