import React, { useState } from 'react';
import { CameraIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Trash from '../Icons/Trash';
import { Infra } from '../../agents/index';
import StartStopButtons, { StartStopTypes } from '../Buttons/StartStopButtons';
import Notify from '../../utils/notifications';
import FormModal from '../Overlays/form-modal';
import Confirm from '../Overlays/confirm';

interface ControlsProps {
  labId: string | number;
  identifier: string;
  status: string;
  withSnapshot?: boolean;
  deleteLab: (labId: string | number, successCallback?: () => void) => void;
}

/**
 * Controls for starting, stop and deleting a single lab
 */
const Controls = ({ labId, identifier, status, withSnapshot, deleteLab }: ControlsProps) => {
  const [showNameModal, setShowNameModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteLab(labId, () => {
      // If we are displaying the snapshot, we are on the lab session page and should leave
      if (withSnapshot) {
        navigate('/labs');
      }
      setShowConfirm(false);
    });
  };

  const runAction = async (type: StartStopTypes) => {
    const labActions = {
      begin: () => Infra.beginLab(labId),
      start: () => Infra.startLab(labId),
      stop: () => Infra.stopLab(labId),
      delete: () => {
        setShowConfirm(true);
      },
      snapshot: () => setShowNameModal(true),
    };
    try {
      await labActions[type]();
    } catch (error) {
      // console.log('LAB action failed ', error);
    }
  };

  const nameForm = {
    initialValues: {
      snapshotName: '',
    },
    validationSchema: Yup.object({
      snapshotName: Yup.string().required('You must provide a name for the snapshot.'),
    }),
    fields: [
      {
        type: 'slug',
        name: 'snapshotName',
        placeholder: 'Enter a unique name',
        helpText: 'You will need to use characters, dashes, and numbers only.',
      },
    ],
    noContainer: true,
    centerButton: true,
    onSubmit: async (values: any) => {
      const { snapshotName } = values;
      try {
        await Infra.snapshot(identifier, snapshotName);
        Notify.success('The snapshot is being created.');
        setShowNameModal(false);
      } catch (err: any) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Create Snapshot',
    },
  };

  return (
    <>
      <div className="my-1 flex items-center justify-end rounded-full">
        {/* @TODO: Remove 'Begin' button/config when done testing */}
        <button
          type="button"
          className="border bg-gray-100 hover:bg-gray-200"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            runAction('begin');
          }}
        >
          <div className="flex items-center rounded-full px-3 py-[3px]">
            <PaperAirplaneIcon className="mr-1 h-3 w-3 text-gray-500" /> <p className="text-xs">Begin</p>
          </div>
        </button>

        <StartStopButtons status={status} runAction={runAction} />

        {!!withSnapshot && (
          <button
            type="button"
            className="border bg-gray-100 hover:bg-gray-200"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              runAction('snapshot');
            }}
          >
            <div className="flex items-center rounded-full px-3 py-[3px]">
              <CameraIcon className="mr-1 h-3 w-3 text-gray-500" /> <p className="text-xs">Snapshot</p>
            </div>
          </button>
        )}

        <button
          type="button"
          className="rounded-r border bg-gray-100 hover:bg-gray-200"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            runAction('delete');
          }}
        >
          <div className="flex items-center rounded-full px-3 py-[3px]">
            <Trash className="mr-1 h-3 w-3 text-gray-500" /> <p className="text-xs">Delete</p>
          </div>
        </button>
      </div>
      <FormModal
        open={!!showNameModal}
        form={nameForm}
        autoForm={false}
        title="Create Snapshot"
        body="Add a name for your new lab snapshot to make it easier to identify in lists."
        cancel={() => setShowNameModal(false)}
      />
      <Confirm
        open={showConfirm}
        cancel={() => setShowConfirm(false)}
        action={handleDelete}
        title="Confirm delete"
        body="Are you sure you want to delete this Lab? This cannot be undone."
        actionText="Delete"
      />
    </>
  );
};

export default Controls;
