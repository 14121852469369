import { PencilIcon, XIcon } from '@heroicons/react/solid';
import SmallButton from '../Common/small-button.js';

const ResourceCard = ({ resource, showDelete = null, showLinks = false, customName = null }) => {
  return (
    <div className="my-1 w-full rounded border border-gray-300 py-2 px-5 relative">
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
        <div className="flex items-center text-sm">
          <p className="mr-2 text-gray-500">Name:</p>
          <p className="mr-2 font-medium">{resource.name}</p>
        </div>
        {!!customName && (
          <div className="flex items-center text-sm">
            <p className="mr-2 text-gray-500">Custom Name:</p>
            <p className="mr-2 font-medium">{customName}</p>
          </div>
        )}
        <div className="flex items-center text-sm">
          <p className="mr-2 text-gray-500">Description:</p>
          <p className="font-medium">{resource.description}</p>
        </div>
        {!!resource.configuration?.protocol && (
          <div className="flex items-center text-sm">
            <p className="mr-2 text-gray-500">Protocol:</p>
            <p className="font-medium">{resource.configuration.protocol}</p>
          </div>
        )}
        {!!resource.configuration?.ami && (
          <div className="flex items-center text-sm">
            <p className="mr-2 text-gray-500">AMI:</p>
            <p className="font-medium">{resource.configuration.ami}</p>
          </div>
        )}
      </div>
      {showLinks && (
        <div className="absolute top-2 right-2 space-x-2">
          <SmallButton href={`/admin/resource/${resource.labResourceId}`}>
            <PencilIcon className="h-4" />
          </SmallButton>
          <SmallButton danger onClick={showDelete}>
            <XIcon className="h-4" />
          </SmallButton>
        </div>
      )}
    </div>
  );
};

export default ResourceCard;
