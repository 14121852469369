import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { deleteApiRequest, getApiRequest, Infra, putApiRequest } from '../../agents';
import { findById } from '../../utils/helpers';
import SmallButton from '../../components/Common/small-button.js';
import Tabs from '../../components/Common/tabs.js';
import ResourceCard from '../../components/Resources/resource-card.js';
import DataCard from '../../components/Common/data-card.js';
import Badge from '../../components/Badges/badge';

const AdminShowDefinition = () => {
  const [loading, setLoading] = useState(true);
  const [definition, setDefinition] = useState(null);
  const [versions, setVersions] = useState(null);
  const [currentTab, setCurrentTab] = useState('Definition Info');
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const tabs = ['Definition Info', 'Version Management', 'Sessions'];

  const findAttributeValue = (attributes, name, defaultValue = '') => {
    const val = findById(attributes, name, 'key', defaultValue);
    return val?.value || defaultValue;
  };

  const loadDefinition = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/lab/${id}`);
      setDefinition(result);
      const versionsResult = await getApiRequest(`/infra/lab-definition/${id}/history`);
      setVersions(versionsResult);
      // console.log(result, 'definition');
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  // Make a snapshot active/inactive
  const toggleSnapshotActive = async (version, active) => {
    setLoading(true);
    try {
      const payload = {
        ...version,
        active,
      };
      await putApiRequest(`/infra/lab-definition/snapshot`, payload);
      loadDefinition();
      // console.log(result, 'definition');
    } catch (error) {
      Notify.handleErrorResponse(error);
      setLoading(false);
    }
  };
  const makeSnapshotActive = (version) => {
    toggleSnapshotActive(version, true);
  };
  const makeSnapshotInactive = (version) => {
    toggleSnapshotActive(version, false);
  };

  /**
   * Load the definition
   */
  useEffect(() => {
    loadDefinition();
  }, [id]);

  // Grab the default values for our form
  const attributes = definition?.attributes || [];
  const name = definition?.name || '';
  const type = definition?.type || 'SOLO';
  const status = definition?.status || 'DISABLED';
  const description = definition?.description || '';
  const resources = definition?.resourcesNew || [];
  const viewType = findAttributeValue(attributes, 'viewType', 'simple');
  const standby = findAttributeValue(attributes, 'min-standby', '0');
  const ttl = findAttributeValue(attributes, 'ttl', '180');

  const launch = async (snapshotId = null) => {
    // Launch with the default name to start
    const { labId, name } = definition;
    const result = await Infra.startTemplate(labId, name, snapshotId);
    const { sessionId, labInstanceIdentifier } = result;
    const simpleViewToggle = viewType === 'simple' ? '?s=true' : '';
    navigate(`/admin/creator/${labInstanceIdentifier}/session/${sessionId}/${labId}${simpleViewToggle}`);
  };

  const handleLearnerLaunch = async (snapshotId = null) => {
    const { labId, name } = definition;
    const result = await Infra.startTemplate(labId, name, snapshotId);
    const { sessionId, labInstanceIdentifier } = result;
    const simpleViewToggle = viewType === 'simple' ? '&s=true' : '';
    // navigate(`/admin/creator/${labInstanceIdentifier}/session/${sessionId}/${labId}${simpleViewToggle}`);
    navigate(`/learner/new?labInstanceIdentifier=${labInstanceIdentifier}&session=${sessionId}&token=${localStorage.getItem('jwt')}&c=true${simpleViewToggle}`);
  };

  const deleteSnapshot = async (snapshotId) => {
    try {
      await deleteApiRequest(`/infra/lab-definition/snapshot/${snapshotId}`);
      loadDefinition();
    } catch (err) {
      Notify.handleErrorResponse(err);
    }
  };

  const hasVersions = !!versions && !!versions.length;

  const hasActiveFirstVersion = hasVersions && versions.length === 3 && versions[0].active;

  const definitionItems = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Description',
      value: description,
    },
    {
      label: 'ID',
      value: definition?.identifier,
    },
    {
      label: 'Type',
      value: type,
    },
    {
      label: 'Status',
      value: status,
    },
    {
      label: 'View Type',
      value: viewType,
    },
    {
      label: 'Standby',
      value: standby,
    },
    {
      label: 'TTL',
      value: ttl,
    },
    {
      label: 'Resources',
      value: (
        <div className="flex flex-col gap-3">
          {resources.map((r) => (
            <ResourceCard resource={r.labResource} customName={r.customResourceName} key={r.labDefinitionResourceId} />
          ))}
        </div>
      ),
    },
  ];

  return (
    <LoadingOrError loading={loading} error={null}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">Definition Summary</h1>
              <p className="text-sm text-gray-400">Comprehensive information about this lab definition.</p>
            </div>
          </div>
          <div className="py-5">
            <Tabs tabs={tabs} current={currentTab} changeTab={setCurrentTab} />
            {currentTab === tabs[0] && (
              <div className="my-6 relative">
                <DataCard title="General Info" items={definitionItems} />
                <div className="absolute right-3 top-3">
                  <SmallButton href={`/admin/definition/${id}`}>Edit Definition</SmallButton>
                </div>
              </div>
            )}
            {currentTab === tabs[1] && (
              <div className="my-3">
                {!hasVersions && (
                  <div>
                    <p className="text-sm text-gray-700">There are no versions to display.</p>
                  </div>
                )}
                <div className="space-x-1 my-3">
                  <SmallButton onClick={() => handleLearnerLaunch()}>Launch Original Version as Learner</SmallButton>
                  {!hasActiveFirstVersion && <SmallButton onClick={() => launch()}>Launch Original Version as Creator</SmallButton>}
                </div>
                {hasVersions &&
                  versions.map((version) => {
                    const { created, resources: snapshotResources, labSnapshotId, active } = version;
                    // const snapshotName = snapshotResources && snapshotResources.length ? snapshotResources[0].shortName : '';
                    const createdAsSeconds = created / 1000;
                    const formattedDate = moment.unix(createdAsSeconds).format('lll');
                    return (
                      <div key={labSnapshotId} className="my-1 w-full rounded border border-gray-300 py-2 px-5">
                        <div className="grid grid-cols-1 gap-2 lg:grid-cols-6">
                          <div className="col-span-4 relative">
                            {!!active && (
                              <div className="absolute top-0 right-2">
                                <Badge color="green" text="Active" />
                              </div>
                            )}
                            {/* {!!snapshotName && <p className="text-sm text-gray-700">Name: {snapshotName}</p>} */}
                            <p className="text-sm text-gray-700">Created: {formattedDate}</p>
                            <p className="text-sm text-gray-700">Resources:</p>
                            <ul className="list-disc ml-6">
                              {snapshotResources.map((r) => {
                                const { amiId, shortName, labResourceSnapshotId } = r;
                                return (
                                  <li className="pl-2 text-gray-700 text-sm" key={labResourceSnapshotId}>
                                    Name: {shortName}
                                    <br />
                                    AMI ID: {amiId}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="col-span-2 space-x-1 space-y-2">
                            <div className="space-x-1">
                              <SmallButton onClick={() => handleLearnerLaunch(labSnapshotId)}>Launch as Learner</SmallButton>
                              {!hasActiveFirstVersion && <SmallButton onClick={() => launch(labSnapshotId)}>Launch as Creator</SmallButton>}
                            </div>
                            <div className="space-x-1">
                              {!active && (
                                <>
                                  <SmallButton onClick={() => makeSnapshotActive(version)}>Make Active</SmallButton>
                                  <SmallButton onClick={() => deleteSnapshot(labSnapshotId)}>Delete</SmallButton>
                                </>
                              )}
                              {!!active && <SmallButton onClick={() => makeSnapshotInactive(version)}>Make Inactive</SmallButton>}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            {currentTab === tabs[2] && (
              <div className="my-3">
                <p className="mb-2">
                  <SmallButton href={`/admin/definition/${id}/sessions`}>View Sessions</SmallButton>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminShowDefinition;
