import { createContext, useContext, ReactNode } from 'react';
import { UserDetails, JWT, NewUserDetails, NewLoginType, AccountDetails } from '../types';
import useNewProvider from './NewLoginProvider';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  user: UserDetails | NewUserDetails | null;
  getJwt: () => JWT | string;
  login: (data: NewLoginType) => Promise<void>;
  logout: () => void;
  getAccount: () => void;
  account: AccountDetails | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useNewProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
