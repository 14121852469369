import Play from '../Icons/Play';
import Stop from '../Icons/Stop';
import { statusNames } from '../../utils/constants/status';

export type StartStopTypes = 'begin' | 'start' | 'stop' | 'delete' | 'snapshot';

interface StartStopProps {
  status: string;
  runAction: (actionType: any) => void;
}

const StartStopButtons = ({ status, runAction }: StartStopProps) => {
  const stopped = status === statusNames.TERMINATED || status === statusNames.STOPPED;
  const running = status === statusNames.RUNNING || status === statusNames.READY;

  if (running) {
    return (
      <button type="button" className="rounded-l border bg-gray-100 hover:bg-gray-200" onClick={() => runAction('stop')}>
        <div className="flex items-center rounded-full px-3 py-[3px]">
          <Stop className="mr-1 h-3 w-3 text-gray-500" /> <p className="text-xs">Stop</p>
        </div>
      </button>
    );
  }

  if (stopped) {
    return (
      <button type="button" className="rounded-l border bg-gray-100 hover:bg-gray-200" onClick={() => runAction('start')}>
        <div className="flex items-center rounded-full px-3 py-[3px]">
          <Play className="mr-1 h-3 w-3 text-gray-500" /> <p className="text-xs">Start</p>
        </div>
      </button>
    );
  }
  return null;
};

export default StartStopButtons;
