import ReactMarkdown from 'react-markdown';
import ResourcesTab from './resources-tab.js';
import FlagsTab from './flags-tab.js';

const CustomAnchor = ({ href, title }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer nofollow">
      {title || href}
    </a>
  );
};

const LearnerContent = ({ current, flags, resources, activeResource, completedFlags, simple = false, connect = null, disconnect = null, info = '', alwaysConnect = false }) => {
  let markdown = `
  ## Scenario
  
  You have recently been hired as a Level 1 SOC Analyst on the Infosec team at Tessier Corporation, a large manufacturing company headquartered in College Park,
  Maryland. After several days of onboarding, you have been assigned your first independent shift at the SOC, where you will monitor Tessier's ticketing system for
  potential security incidents. Upon receipt of each ticket, you are expected to process the ticket in conformance with Tessier's standard operating procedures.

  ## Instructions

  1. In the Lab Sidebar, **review** the **Resources tab**.  
  &nbsp;  
  The Resources tab contains information about the virtual machines and applications in this lab environment.

  2. In the Lab Sidebar, **review** the **Activities tab**.  
  &nbsp;  
  The Activities tab will display details about completed activities, including your score and time on task. For the purposes of this assessment, activities are completed by 
  processing security tickets in Tessier's ticketing system.

  3. On the Windows workstation, **review** the **SOC Standard Operating Procedures document**.  
  &nbsp;  
  The SOC SOP document can be accessed within the lab environment at https://clab.cybrarysoc.com/docs/soc1_wiki.pdf (you may need to refresh the page). This document contains the
  Tessier SOC's standard operating procedures for processing security tickets, as well as information about accessing Tessier's ticketing system and other tools.  Be sure to
  review this document carefully and log in to each tool before beginning the assessment.

  4. Once you have thoroughly reviewed the SOC SOP document, **click** the **Start button** to begin the assessment.  
  &nbsp;  
  You will receive your first ticket in the ticket queue within a few seconds. At this stage of the Assessment Beta, you will have 60 minutes to complete 4 tickets. Upon completing
  each ticket, you will receive a notification with your score for the activity. When the assessment is complete, you will receive a results report with additional detail about
  your performance.
  `;

  if (simple) {
    markdown = `Welcome to the Cybrary virtual lab interface. Be sure to explore the Resources tab. When you're done, click the Exit button to end your lab session.`;
  }

  if (info) {
    markdown = info;
  }

  if (current === 0) {
    return (
      <div className="has-markdown-inside">
        <ReactMarkdown
          components={{
            a: ({ href, children }) => <CustomAnchor href={href} title={children[0]} />,
          }}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    );
  }
  if (current === 1) {
    return (
      <div>
        <ResourcesTab resources={resources} activeResource={activeResource} connect={connect} disconnect={disconnect} alwaysConnect={alwaysConnect} />
      </div>
    );
  }
  if (!simple) {
    return (
      <div>
        <FlagsTab flags={flags} completedFlags={completedFlags} />
      </div>
    );
  }
  return null;
};

export default LearnerContent;
