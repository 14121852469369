import { Store } from 'react-notifications-component';

const displayError = (message?: string, title = 'Error') => {
  Store.addNotification({
    title,
    message: message || 'Something went wrong. Try again.',
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

const displaySuccess = (message: string) => {
  Store.addNotification({
    title: 'Success',
    message: message || 'Good Job',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

const handleErrorResponse = (error: any, defaultReason = 'Failed to load.', defaultTitle = 'Error') => {
  displayError(error.response?.data?.reason || defaultReason, error.response?.data?.message || defaultTitle);
};

const Notify = { error: displayError, success: displaySuccess, handleErrorResponse };

Notify.handleErrorResponse = handleErrorResponse;

export default Notify;
