import { Store } from 'react-notifications-component';
import FlagBox from './flag-box.js';
import NotificationConfig from './notifications-config.js';

const FlagNotification = (flag) => {
  Store.addNotification({
    ...NotificationConfig,
    content: () => <FlagBox {...flag} className="w-full bg-neutral-900 text-gray-200" />,
  });
};

export default FlagNotification;
