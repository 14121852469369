import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { LocalLoginType, IapIdentity } from '../types';
import { onWhitelist } from '../utils/auth-whitelist';
import { useAuth } from '../context/auth';

const clabApiBaseURL = process.env.REACT_APP_CLAB_API_URL;

// We will send along these headers with each request
const getAuthHeader = (): AxiosRequestHeaders => {
  const token = localStorage.getItem('jwt') || '';
  // return token ? { Authorization: `Bearer ${token}` } : {};
  return token ? { Authorization: `${token}` } : {};
};

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401 && !onWhitelist()) {
      // Perform a local logout, and force the application to redirect to login
      window.localStorage.removeItem('u');
      window.localStorage.removeItem('jwt');
      useAuth();
      // window.location.href = '/';
    } else {
      throw error;
    }
  }
);

const getRequestOptions = () => {
  return {
    headers: getAuthHeader(),
    withCredentials: true,
  };
};

const response = (response: AxiosResponse) => response?.data;

// Override defaults or headers with options
const requests = {
  get: (url: string) => axiosInstance.get(url, getRequestOptions()).then(response),
  post: (url: string, data: any) => axiosInstance.post(url, data, getRequestOptions()).then(response),
  put: (url: string, data: any) => axiosInstance.put(url, data, getRequestOptions()).then(response),
  patch: (url: string, data?: any) => axiosInstance.patch(url, data, getRequestOptions()).then(response),
  delete: (url: string) => axiosInstance.delete(url, getRequestOptions()).then(response),
};

// Export generic api request handler
export const getApiRequest = (route: string) => requests.get(`${clabApiBaseURL}${route}`);
export const postApiRequest = (route: string, data?: any) => requests.post(`${clabApiBaseURL}${route}`, data);
export const putApiRequest = (route: string, data?: any) => requests.put(`${clabApiBaseURL}${route}`, data);
export const deleteApiRequest = (route: string) => requests.delete(`${clabApiBaseURL}${route}`);

export const Auth = {
  localLogin: (data: LocalLoginType) => requests.post(`${clabApiBaseURL}/user/token`, data),
  iapLogin: (): Promise<IapIdentity> => requests.get(`${clabApiBaseURL}/?gcp-iap-mode=IDENTITY`),
};

export const Infra = {
  getTemplates: () => requests.get(`${clabApiBaseURL}/lab`),
  deleteLabTemplate: (labId: string | number) => requests.delete(`${clabApiBaseURL}/lab/${labId}`),
  getLabs: () => requests.get(`${clabApiBaseURL}/infra/lab`),
  getLabById: (labId: string | number | undefined) => requests.get(`${clabApiBaseURL}/infra/lab/${labId}`),
  startTemplate: (labId: string | number, sessionName = '', snapshotId = null) => {
    const params = snapshotId ? { sessionName, snapshotId } : { sessionName };
    return requests.post(`${clabApiBaseURL}/infra/lab/${labId}?${queryString.stringify(params)}`, {});
  },
  startLab: (labId: string | number) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/start`),
  stopLab: (labId: string | number) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/stop`),
  beginLab: (labId: string | number, query = '') => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/begin${query}`),
  restartLab: (labId: string | number) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/restart`),
  deleteLab: (labId: string | number) => requests.delete(`${clabApiBaseURL}/infra/lab/${labId}`),
  startResource: (labId: string | number, resourceId: string) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/resource/${resourceId}/start`),
  stopResource: (labId: string | number, resourceId: string) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/resource/${resourceId}/stop`),
  getResourceStatus: (labId: string | number) => requests.get(`${clabApiBaseURL}/infra/lab/${labId}/resource`),
  clearLabDefinitions: () => requests.delete(`${clabApiBaseURL}/lab/all`),
  reseedLabDefinitions: () => requests.put(`${clabApiBaseURL}/infra/seed`, {}),
  snapshot: (labId: string | number, snapshotName = '', deleteLabOnComplete = false) => {
    const params = { snapshotName, deleteLabOnComplete };
    return requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/snapshot?${queryString.stringify(params)}`, {});
  },
};

export const LabDefinition = {
  getDefinition: (id: string | number) => requests.get(`${clabApiBaseURL}/lab/${id}`),
  saveDefinition: (postData: any) => requests.post(`${clabApiBaseURL}/lab`, postData),
};

export const Event = {
  saveUserKeyEvent: (data: object) => requests.post(`${clabApiBaseURL}/event/key`, data),
};

export const Session = {
  getAllSessions: (query = '') => requests.get(`${clabApiBaseURL}/user/session${query}`),
  getSession: (sessionId: number) => requests.get(`${clabApiBaseURL}/user/session/${sessionId}`),
  getSessionByUserAndLabId: (userId: number | undefined, labId: string) => requests.get(`${clabApiBaseURL}/user/session/${userId}/${labId}`),
  clearUserSessions: () => requests.delete(`${clabApiBaseURL}/user/session/all`),
};

export const DashboardEvent = {
  getLabStatusHistory: (sessionId: string) => requests.get(`${clabApiBaseURL}/event/history/status?sessionId=${sessionId}`),
  getLabStateHistory: (sessionId: string) => requests.get(`${clabApiBaseURL}/event/history/state?sessionId=${sessionId}`),
  getResourceStatusHistory: (sessionId: string) => requests.get(`${clabApiBaseURL}/event/history/resource?sessionId=${sessionId}`),
  getKeyboardHistory: (sessionId: string) => requests.get(`${clabApiBaseURL}/event/history/key?sessionId=${sessionId}`),
};
