import StartStopButtons from '../Buttons/StartStopButtons';
import { statusNames } from '../../utils/constants/status';
import { Infra } from '../../agents';
import { Resource } from '../../types/labs';
import Notify from '../../utils/notifications';

interface ControlProps {
  labId: string;
  resource: Resource;
  connect: (resource: Resource) => void;
  disconnect: (resource: Resource) => void;
}

const Controls = ({ labId, resource, connect, disconnect }: ControlProps) => {
  const { name, connected, status } = resource;
  const running = statusNames.RUNNING === status;

  const runAction = (type: 'start' | 'stop') => {
    const resourceActions = {
      start: async () => {
        try {
          await Infra.startResource(labId, name);
        } catch (error) {
          Notify.handleErrorResponse(error);
        }
      },
      stop: async () => {
        try {
          await Infra.stopResource(labId, name);
        } catch (error) {
          Notify.handleErrorResponse(error);
        }
      },
    };
    resourceActions[type]();
  };

  return (
    <div className="my-1 flex items-center justify-end rounded-full">
      <StartStopButtons status={status || ''} runAction={runAction} />
      {!connected && running ? (
        <button type="button" className="rounded-r border bg-gray-100 hover:bg-gray-200" onClick={() => connect(resource)}>
          <div className="flex items-center rounded-full px-3 py-[3px]">
            <p className="text-xs">Connect</p>
          </div>
        </button>
      ) : null}
      {connected && running ? (
        <button type="button" className="rounded-r border bg-gray-100 hover:bg-gray-200" onClick={() => disconnect(resource)}>
          <div className="flex items-center rounded-full px-3 py-[3px]">
            <p className="text-xs">Disconnect</p>
          </div>
        </button>
      ) : null}
    </div>
  );
};

export default Controls;
