import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Session } from '../../agents';
import Notify from '../../utils/notifications';

const columns = [
  {
    name: 'Id',
    selector: (row: any) => row.id,
  },
  {
    name: 'Session Id',
    selector: (row: any) => row.sessionId,
  },
  {
    name: 'User Id',
    selector: (row: any) => row.userId,
  },
  {
    name: 'Lab Id',
    selector: (row: any) => row.labUuid,
  },
  {
    name: 'Template Id',
    selector: (row: any) => row.templateId,
  },
  {
    name: 'Created At',
    selector: (row: any) => row.created,
  },
  {
    name: 'Updated At',
    selector: (row: any) => row.updated,
  },
];

const UserSession = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const rowPerPage = 20;
  const totalRows = 100; // backend needs to send this

  const fetchSessions = async (page: any = 0) => {
    setLoading(true);
    try {
      const sessionResponse = await Session.getAllSessions(`?size=${rowPerPage}&page=${page}`);
      setData(sessionResponse);
    } catch (error) {
      Notify.handleErrorResponse(error);
      throw error;
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const handlePageChange = (page: any) => {
    fetchSessions(page);
  };

  const rowClicked = (row: any) => {
    navigate(`/dashboard/sessions/${row.sessionId}`);
  };

  return (
    <div className="py-5 px-8">
      <h1 className="mb-10 text-2xl">Dashboard</h1>
      <div className="mt-12">
        <div className="rounded-sm border">
          <DataTable
            title="User Sessions"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={rowPerPage}
            pointerOnHover
            highlightOnHover
            onRowClicked={rowClicked}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={[rowPerPage]}
          />
        </div>
      </div>
    </div>
  );
};
export default UserSession;
