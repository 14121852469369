interface BadgeProps {
  color?: string;
  rounded?: boolean;
  large?: boolean;
  text: string;
  className?: string;
}

function getColor(color?: string) {
  switch (color) {
    case 'red':
      return 'bg-red-100 text-red-800';

    case 'yellow':
      return 'bg-yellow-100 text-yellow-800';

    case 'green':
      return 'bg-green-100 text-green-800';

    case 'blue':
      return 'bg-blue-100 text-blue-800';

    case 'indigo':
      return 'bg-indigo-100 text-indigo-800';

    case 'purple':
      return 'bg-purple-100 text-purple-800';

    case 'pink':
      return 'bg-pink-100 text-pink-800';

    case 'gray':
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

function getSize(large?: boolean, rounded?: boolean) {
  if (rounded) {
    return large ? 'px-2.5 py-0.5 rounded-md text-sm' : 'px-2 py-0.5 rounded text-xs';
  }
  return large ? 'px-3 py-0.5 rounded-full text-sm' : 'px-2.5 py-0.5 rounded-full text-xs';
}

const Badge = ({ color, rounded, large, text, className = '' }: BadgeProps) => {
  const classes = `inline-flex items-center font-semibold ${className} ${getColor(color)} ${getSize(large, rounded)}`;
  return <span className={classes}>{text}</span>;
};

export default Badge;
