interface CheckboxProps {
  formik: any;
  name: string;
  id: string;
  hasError?: boolean;
  title: string;
  subtitle?: string;
}

const Checkbox = ({ formik, name, id, hasError, title, subtitle }: CheckboxProps) => {
  const titleClasses = hasError ? 'font-medium text-red-600' : 'font-medium text-gray-700';
  const ariaProps = subtitle ? { 'aria-describedby': `${id}-subtitle` } : { 'aria-describedby': id };
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          {...formik.getFieldProps(name)}
          {...ariaProps}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          id={id}
          name={name}
          checked={!!formik.values[name]}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className={titleClasses}>
          {title}
        </label>
        {!!subtitle && (
          <p id={`${id}-subtitle"`} className="text-gray-500">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
