import { Message } from '@stomp/stompjs';
import queryString from 'query-string';
import Lab, { LabOutput, Resource, LabResponse } from '../types/labs';
import { statusNames } from './constants/status';
import MessageConstant from './constants/message';
import { findById } from './helpers';

/**
 * Get ip connection protocol
 * @param ipData
 * @returns
 */
const getProtocol = (vnc: boolean, windowsOS: boolean): string => {
  let protocol = '';
  if (windowsOS) {
    protocol = 'rdp';
  } else if (vnc) {
    protocol = 'vnc';
  } else {
    protocol = 'ssh';
  }
  return protocol;
};

// Return the resources in order based on the order array of identifiers
export const orderResources = (resources: any[], order: string[], idKey = 'identifier') => {
  if (!order.length) {
    return resources;
  }
  const ordered = [];
  for (let i = 0; i < order.length; i++) {
    const identifier = order[i];
    const r = findById(resources, identifier, idKey);
    if (r) {
      ordered.push(r);
    }
  }
  return ordered.length ? ordered : resources;
};

/**
 * Get Resources from outputs object
 * @param outputs
 * @returns
 */
export const getResourceFromOutput = (outputs: LabOutput[] | undefined, resourcesInfo: any[] | undefined, labStatus: string): Resource[] => {
  if (!outputs || !outputs.length) {
    return [];
  }

  // Order the lab outputs before formatting
  const orderedLabOutputs = outputs.sort((a, b) => a.ordinal - b.ordinal);

  const resources: Resource[] = orderedLabOutputs.map((labOutput) => {
    const { name, status, data, instanceId, ordinal, labDefinitionResourceId } = labOutput;
    const { userPassword, vnc, windowsOS, networkInterfaces, connectionParams } = data;
    const { publicIp } = networkInterfaces[0];

    const resourceDefinition = resourcesInfo ? findById(resourcesInfo, labDefinitionResourceId, 'labDefinitionResourceId') : null;

    const { visible, customResourceName } = resourceDefinition || {};

    const isHidden = !visible;

    const connectData = connectionParams ? queryString.parse(connectionParams) : {};
    const ret = {
      name,
      customResourceName,
      labDefinitionResourceId,
      ip: publicIp,
      protocol: getProtocol(vnc, windowsOS),
      password: userPassword,
      status: labStatus !== 'running' && status === 'running' ? 'booting' : status,
      instanceId,
      ordinal,
      hidden: isHidden,
      connectable: false,
      ...connectData,
    };
    if (connectionParams) {
      // @ts-ignore
      ret['connect-string'] = connectionParams;
      ret.connectable = !isHidden;
    }
    return ret;
  });

  return resources;
};

/**
 * Print clab events to textarea
 * @param message
 */
export const handleClabEvent = (message: Message) => {
  try {
    console.log(JSON.parse(message.body), 'message body');
  } catch (e) {
    console.log(e, 'error parsing message');
  }
  const textarea = document.getElementById('clab-events') as HTMLInputElement;
  if (textarea) {
    let newText = '';
    try {
      const obj = JSON.parse(message.body);
      const pretty = JSON.stringify(obj, undefined, 2);
      newText = pretty;
    } catch (err) {
      newText = message.body;
    }
    textarea.value = `${newText}
------------------
${textarea.value}`;
  }
};

/**
 * Format lab details
 */
export const formatLabDetails = (labResponse: LabResponse, definition: any): Lab => {
  // console.log(labResponse, 'raw lab response');
  const { labInstanceIdentifier, labInstance, outputs, status: responseStatus, attributes } = labResponse || {};
  const { labId, identifier } = labInstance;
  const metaAttribute = findById(attributes || [], 'meta', 'key');
  const metaString = metaAttribute?.value || null;
  const meta = metaString ? JSON.parse(metaString) : {};
  const ttlAttribute = findById(attributes || [], 'ttl', 'key');
  const ttlString = ttlAttribute?.value || '60';
  const ttl = 1 * ttlString;
  const info = meta?.info || null;
  const hiddenResources = meta?.hiddenResources || [];
  const resourcesOrder = meta?.resourcesOrder || [];
  // console.log(labResponse, 'LAB RESPONSE');
  const { resourcesNew } = definition;
  const status = responseStatus || labInstance?.status;
  const { expires } = labInstance || {};
  return {
    labId,
    identifier,
    labInstanceIdentifier,
    status,
    info,
    expires,
    ttl,
    sessionId: '',
    resources: getResourceFromOutput(outputs, resourcesNew, status),
  } as Lab;
};

/**
 * Get Resources by lab id, move to resource util
 * @param id
 * @param labs
 * @returns
 */
export const getResourcesByLabId = (id: string, labs: Lab[]): Resource[] => {
  const lab = labs.find((lab) => lab.labId === id);
  return lab && lab.resources ? lab.resources : [];
};

/**
 * Get Lab from socket events
 * @param message
 * @param userLabs
 * @returns
 */
export const getLabFromSocketEvent = (message: Message) => {
  console.log('calling get lab from socket event');
  const messageBody = JSON.parse(message.body);
  const { payload } = messageBody;
  const { uuid, instanceData, labId } = payload;
  const { name, userPassword, vnc, windowsOS, networkInterfaces } = instanceData;
  const { publicIp } = networkInterfaces[0];
  const protocol = getProtocol(vnc, windowsOS);

  // TODO: Why are we assuming one resource?  And is that what instanceData will always be?
  const resourcesData: Resource[] = [
    {
      name,
      ip: publicIp,
      protocol,
      password: userPassword,
      status: statusNames.RUNNING,
    },
  ];

  return {
    labId,
    identifier: uuid,
    status: statusNames.READY,
    resources: resourcesData,
  };
};

export const getStatusFromSocketEvent = (message: Message) => {
  const parsed = JSON.parse(message.body);
  const { payload } = parsed || {};
  const { instanceName, currentState } = payload || {};
  return { name: instanceName, status: currentState, ip: 'loading...', protocol: '' };
};

export const getMessageType = (message: Message): string => {
  const parsedBody = JSON.parse(message.body);
  const { type } = parsedBody || {};
  console.log(type, 'message type', parsedBody);
  return type === 'resource_status_changed' ? MessageConstant.RESOURCE : MessageConstant.LAB;
};

export const padZeros = (num: number) => {
  return `${num}`.padStart(2, '0');
};

export const convertSecsToHHMMSS = (seconds: number) => {
  let secondsCopy = seconds;
  const hours = Math.floor(secondsCopy / 3600);
  secondsCopy -= hours * 3600;
  const mins = Math.floor(secondsCopy / 60);
  secondsCopy -= mins * 60;
  const secs = Math.floor(secondsCopy % 60);
  return `${padZeros(hours)}:${padZeros(mins)}:${padZeros(secs)}`;
};

export const getColorFromScore = (points: number, pointsEarned = 0) => {
  const percentage = (pointsEarned / points) * 100;
  if (!percentage) {
    return 'bg-red-700';
  }
  if (percentage <= 50) {
    return 'bg-orange-500';
  }
  if (percentage <= 99) {
    return 'bg-yellow-500';
  }
  return 'bg-green-600';
};

export default formatLabDetails;
