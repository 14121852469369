import { Outlet } from 'react-router-dom';
import LearnerNav from '../Nav/LearnerNav';
import PrimaryNav from '../Nav/Primary';

interface LayoutProps {
  learner?: boolean;
}

const Layout = ({ learner }: LayoutProps) => {
  return (
    <div className="min-h-screen bg-white font-inter text-sm leading-loose text-gray-600 transition-all duration-500 ease-linear">
      {learner ? <LearnerNav /> : <PrimaryNav />}
      <Outlet />
    </div>
  );
};

export default Layout;
