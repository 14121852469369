import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Form from './form';
import Notify from '../../utils/notifications';
import { getApiRequest, postApiRequest } from '../../agents';
import Alert from '../Alerts/alert';
import LoadSpinner from '../Loading/LoadSpinner';

interface AutoFormProps {
  fields: any[];
  type: string;
  submitLabel: string;
  successNotification: string;
  fetchErrorRedirect?: string;
  fetchErrorMessage?: string;
  fetchRoute?: string;
  fetchHydrate?: (response: any) => any;
  postRoute?: string;
  successRedirect?: string;
  successHandler?: (values?: any, actions?: any, result?: any) => void;
  noContainer?: boolean;
  beforePost?: (values?: any, actions?: any, entity?: any) => any;
}

interface PostFormProps {
  fields: any[];
  submitLabel: string;
  successNotification: string;
  fetchErrorRedirect?: string;
  fetchErrorMessage?: string;
  fetchRoute?: string;
  fetchHydrate?: (response: any) => any;
  postRoute: string;
  successRedirect?: string;
  successHandler?: (values?: any, actions?: any, result?: any) => void;
  noContainer?: boolean;
  beforePost?: (values?: any, actions?: any, entity?: any) => any;
}

interface GetFormProps {
  fields: any[];
  submitLabel: string;
  successRedirect?: string;
  successHandler?: (values?: any, actions?: any, result?: any) => void;
  noContainer?: boolean;
}

function defaultFromType(type: string) {
  switch (type) {
    case 'date':
      return null;
    case 'checkboxes':
      return [];
    default:
      return '';
  }
}

function initialValueFromType(type: string, name: string, entity: any) {
  return (entity && entity[name]) || defaultFromType(type);
}

function requiredMessage(label: string) {
  return `${label} is a required field.`;
}

function validationSchemaFromType(type: string, required: boolean, label: string) {
  switch (type) {
    case 'date':
      return required ? Yup.date().typeError('Must be a valid date.') : Yup.date().typeError('Must be a valid date.').nullable();
    case 'select':
    case 'select-lookup':
      return required ? Yup.mixed().required(requiredMessage(label)) : Yup.mixed();
    case 'checkbox':
      return required ? Yup.boolean().oneOf([true], 'You must agree to continue.') : Yup.boolean();
    case 'checkboxes':
      return required ? Yup.array().min(1, 'You must select at least one option.') : Yup.array();
    case 'email':
      return required ? Yup.string().email('Invalid email address.').required(requiredMessage(label)) : Yup.string().email('Invalid email address.');
    default:
      return required ? Yup.string().required(requiredMessage(label)) : Yup.string();
  }
}

// Loop over fields and build validations as well as initial values
function prepareSchemaAndValues(fields: any[], entity?: any) {
  const initialValues: any = {};
  const validationSchema: any = {};
  for (const field of fields) {
    const { name, type, label, validations, required, initialValue } = field;
    if (initialValue) {
      initialValues[name] = initialValue(entity);
    } else {
      initialValues[name] = initialValueFromType(type, name, entity);
    }
    if (validations) {
      validationSchema[name] = validations();
    } else {
      validationSchema[name] = validationSchemaFromType(type, required, label);
    }
  }

  return [initialValues, validationSchema];
}

const GetForm = ({ fields, successRedirect, successHandler, noContainer, submitLabel }: GetFormProps) => {
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);

  const onSubmit = (values?: any, actions?: any) => {
    actions.setSubmitting(false);
    if (successRedirect) {
      const newQuery = queryString.stringify(values);
      const newParams = newQuery ? `?${newQuery}` : '';
      // We are just going to redirect the browser by updating the query params...
      navigate(`${successRedirect}${newParams}`);
    } else if (successHandler) {
      successHandler(values, actions);
    }
  };

  const [initialValues, validationSchema] = prepareSchemaAndValues(fields, query);

  const form = {
    initialValues,
    // @ts-ignore
    validationSchema: Yup.object(validationSchema),
    onSubmit,
    fields,
    submit: {
      label: submitLabel || 'Submit',
    },
  };

  return <Form noContainer={noContainer} {...form} />;
};

const PostForm = ({
  fields,
  submitLabel,
  successNotification,
  fetchErrorRedirect,
  fetchRoute,
  fetchHydrate,
  postRoute,
  successRedirect,
  successHandler,
  noContainer,
  beforePost,
  fetchErrorMessage = 'There was an error loading the requested object.',
}: PostFormProps) => {
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState(null);
  const [formError, setFormError] = useState(null);
  const navigate = useNavigate();

  const performFetch = async (route: string) => {
    setLoading(true);
    try {
      const fetched = await getApiRequest(route);
      const hydrated = fetchHydrate ? fetchHydrate(fetched) : fetched;
      setEntity(hydrated);
    } catch (err: any) {
      if (err?.response?.status === 401) {
        return;
      }
      Notify.error(fetchErrorMessage);
      if (fetchErrorRedirect) {
        navigate(fetchErrorRedirect);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (fetchRoute) {
      performFetch(fetchRoute);
    }
  }, [fetchRoute]);

  const handleServerValidationError = (errorResponse: any, actions?: any) => {
    Notify.error('Please correct the errors to continue.');
    const { data: errorData } = errorResponse;
    const { errors, error: genericError } = errorData || {};
    const newErrors: any = {};
    if (errors) {
      const errorFieldNames = Object.keys(errors);
      for (const errorFieldName of errorFieldNames) {
        newErrors[errorFieldName] = errors[errorFieldName].join(' ');
      }
      actions.setErrors(newErrors);
    }
    if (genericError) {
      setFormError(genericError);
    }
  };

  const onSubmit = async (values?: any, actions?: any) => {
    setFormError(null);
    try {
      const valuesToSend = beforePost ? beforePost(values, actions, entity) : values;
      const result = await postApiRequest(postRoute, valuesToSend);
      Notify.success(successNotification);
      if (successRedirect) {
        navigate(successRedirect);
      } else if (successHandler) {
        successHandler(valuesToSend, actions, result);
      }
    } catch (err: any) {
      const { response: errorResponse } = err;
      if (errorResponse.status === 422) {
        handleServerValidationError(errorResponse);
      } else if (errorResponse.status !== 401) {
        Notify.error('There was an unknown error, please try again.');
      }
    }

    actions.setSubmitting(false);
  };

  const [initialValues, validationSchema] = prepareSchemaAndValues(fields, entity);

  const form = {
    initialValues,
    // @ts-ignore
    validationSchema: Yup.object(validationSchema),
    onSubmit,
    fields,
    submit: {
      label: submitLabel || 'Submit',
    },
  };

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <>
      {!!formError && <Alert type="error" body={formError} />}
      <Form noContainer={noContainer} {...form} />
    </>
  );
};

const AutoForm = ({
  fields,
  type,
  submitLabel,
  successNotification,
  fetchErrorRedirect,
  fetchErrorMessage,
  fetchRoute,
  fetchHydrate,
  postRoute,
  successRedirect,
  successHandler,
  noContainer,
  beforePost,
}: AutoFormProps) => {
  if (type === 'get') {
    return <GetForm fields={fields} successHandler={successHandler} successRedirect={successRedirect} noContainer={noContainer} submitLabel={submitLabel} />;
  }
  return (
    <PostForm
      fields={fields}
      submitLabel={submitLabel}
      successNotification={successNotification}
      fetchErrorRedirect={fetchErrorRedirect}
      fetchErrorMessage={fetchErrorMessage}
      fetchRoute={fetchRoute}
      fetchHydrate={fetchHydrate}
      postRoute={postRoute || '/'}
      successRedirect={successRedirect}
      successHandler={successHandler}
      noContainer={noContainer}
      beforePost={beforePost}
    />
  );
};

export default AutoForm;
