import { Store } from 'react-notifications-component';
import { ClockIcon } from '@heroicons/react/outline';
import Box from './box.js';
import NotificationConfig from './notifications-config.js';

const TimeNotification = (timeRemaining) => {
  Store.addNotification({
    ...NotificationConfig,
    content: () => (
      <Box className="flex items-center gap-x-4 w-full bg-neutral-900 text-gray-200 text-xl">
        <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
        {timeRemaining}
      </Box>
    ),
  });
};

export default TimeNotification;
