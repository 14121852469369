import React from 'react';
import Lab from '../../types/labs';
import ExternalLink from '../Icons/ExternalLink';
import Controls from './Controls';
import { StatusColors } from '../../utils/constants/status';
import StatusBadge from '../Badges';
import { findIndexById } from '../../utils/helpers';

interface LabCardProps {
  lab: Lab;
  listView?: boolean;
  small?: boolean;
  withControls?: boolean;
  withSnapshot?: boolean;
  onClick?: (id: string, sessionId: string, definitionId: string | number) => void;
  learnerLaunch?: (id: string, sessionId: string, viewType?: string) => void;
  deleteLab: (labId: string | number) => void;
  fullClick?: boolean;
}

/**
 * Primary Lab card
 * @param LabCardProps
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LabCard = ({ lab, listView, small, withControls, withSnapshot, onClick, deleteLab, learnerLaunch, fullClick = false }: LabCardProps) => {
  const { identifier, labId, labInstanceIdentifier, sessionId, status, clientStatus, name, attributes } = lab;
  const colors = StatusColors[status as keyof typeof StatusColors];
  const handleClick = () => {
    if (onClick && clientStatus !== 'deleted') {
      onClick(labInstanceIdentifier || identifier, sessionId || '', labId || '');
    }
  };
  // console.log(lab, 'lab');
  const handleLearnerLaunch = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (learnerLaunch && clientStatus !== 'deleted') {
      const viewTypeAttributeIndex = findIndexById(attributes || [], 'viewType', 'key');
      const viewTypeValue = attributes && viewTypeAttributeIndex !== -1 ? attributes[viewTypeAttributeIndex].value : 'simple';
      learnerLaunch(labInstanceIdentifier || identifier, sessionId || '', viewTypeValue);
    }
  };
  const idToUse = labInstanceIdentifier || identifier || labId;

  const bgClass = clientStatus === 'deleted' ? 'bg-red-50' : '';
  const hoverClass = fullClick ? 'hover:border-blue-400 cursor-pointer' : '';

  return (
    <div className={`w-full rounded border border-gray-300 ${bgClass} ${hoverClass}`} onClick={() => fullClick && handleClick()}>
      <div className="mb-4 px-5 pt-2">
        <div className="-mt-5 flex justify-end">
          {!clientStatus && <StatusBadge text={status} colors={colors} />}
          {!!clientStatus && <StatusBadge text="DELETING" colors={StatusColors.Deleting} />}
        </div>

        {!!name && (
          <>
            <span className="mr-2 text-gray-500">Name:</span> <span className="font-medium text-gray-800">{name}</span>
          </>
        )}
        {listView ? (
          <button type="button" onClick={handleClick} className="flex items-center pr-4 pb-2">
            <p className="mr-2 text-gray-500">LabId:</p> <p className="font-medium text-gray-800">{idToUse}</p> <ExternalLink className="ml-1 h-4 w-4 text-gray-600" />
          </button>
        ) : (
          <div className="flex items-center pr-4 pb-2">
            <p className="mr-2 text-gray-500">LabId:</p> <p className="font-medium text-gray-800">{idToUse}</p>
            {listView ? <ExternalLink className="ml-1 h-4 w-4 text-gray-600" /> : null}
          </div>
        )}
        {!!learnerLaunch && (
          <button type="button" onClick={handleLearnerLaunch} className="flex items-center pr-4 pb-2">
            <p className="mr-2 text-gray-600 font-bold hover:text-gray-900">Launch As Learner</p> <ExternalLink className="ml-1 h-4 w-4 text-gray-600" />
          </button>
        )}
      </div>

      {withControls && clientStatus !== 'deleted' ? (
        <div className="px-5 pt-1 pb-3">
          <Controls deleteLab={deleteLab} identifier={labInstanceIdentifier || identifier} labId={idToUse} status={status} withSnapshot={!!withSnapshot} />{' '}
        </div>
      ) : null}
    </div>
  );
};

export default LabCard;
