import Spinner from '../Icons/Spinner';

interface LoadSpinnerProps {
  text?: string;
  spinnerClass?: string;
}

const LoadSpinner = ({ text = '', spinnerClass = 'h-8 w-8 text-gray-500' }: LoadSpinnerProps) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center space-x-1">
        <Spinner className={`animate-spin ${spinnerClass}`} active={false} />
        {text ? <div>{text}</div> : null}
      </div>
    </div>
  );
};

export default LoadSpinner;
