import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest, LabDefinition, putApiRequest } from '../../agents';
import Form from '../../components/Form/form';
import { findIndexById, findById } from '../../utils/helpers';
import { orderResources } from '../../utils/labUtil';

const AdminDefinition = () => {
  const [loading, setLoading] = useState(true);
  const [definition, setDefinition] = useState(null);
  const [allResources, setAllResources] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  // const [configOptions, setConfigOptions] = useState([]);
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const findAttributeValue = (attributes, name, defaultValue = '') => {
    const val = findById(attributes, name, 'key', defaultValue);
    return val?.value || defaultValue;
  };

  const loadDefinition = async () => {
    setLoading(true);
    if (id) {
      try {
        const result = await getApiRequest(`/lab/${id}`);
        setDefinition(result);
      } catch (error) {
        Notify.handleErrorResponse(error);
      }
    }
    try {
      const resourcesResult = await getApiRequest(`/lab/resource?page=0&size=1000`);
      const newResourceOptions = resourcesResult.map((res) => {
        return {
          value: res.labResourceId,
          label: `${res.name} - ${res.description}`,
        };
      });
      // console.log(resourcesResult, 'turn these into resource options');
      // const configResult = await getApiRequest(`/lab/resource/configuration?page=0&size=100`);
      // const newConfigOptions = configResult.map((res) => {
      //   return {
      //     value: res.labResourceConfigurationId,
      //     label: `${res.protocol} - ${res.ami}`,
      //   };
      // });
      // console.log(configResult, 'turn these into config options');
      setAllResources(resourcesResult);
      setResourceOptions([
        {
          value: '',
          label: 'Select...',
        },
        ...newResourceOptions,
      ]);
      // setAllConfigs(configResult);
      // setConfigOptions([
      //   {
      //     value: '',
      //     label: 'Select...',
      //   },
      //   ...newConfigOptions,
      // ]);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the definitions
   */
  useEffect(() => {
    loadDefinition();
  }, [id]);

  // console.log(definition, 'the defintiion');

  // Grab the default values for our form
  const attributes = definition?.attributes || [];
  const name = definition?.name || '';
  const type = definition?.type || 'SOLO';
  const status = definition?.status || 'DISABLED';
  const description = definition?.description || '';
  const resources = definition?.resourcesNew || [];
  const viewType = findAttributeValue(attributes, 'viewType', 'simple');
  const standby = findAttributeValue(attributes, 'min-standby', '0');
  const ttl = findAttributeValue(attributes, 'ttl', '180');

  const meta = findAttributeValue(attributes, 'meta', null);
  const metaJson = meta ? JSON.parse(meta) : {};
  const { info } = metaJson || {};

  const defaultResources = [];
  const orderedResources = resources ? resources.sort((a, b) => a.ordinal - b.ordinal) : [];
  for (let i = 0; i < orderedResources.length; i++) {
    const r = orderedResources[i];
    // const { configuration } = r;
    defaultResources.push({
      id: uuidv4(),
      value: {
        name: r.customResourceName,
        // description: r.description,
        // visible: !hiddenResources || hiddenResources.indexOf(r.identifier) === -1 ? 'visible' : 'hidden',
        visible: r.visible ? 'visible' : 'hidden',
        resource: r.labResource.labResourceId,
        resourceIdentifier: r.labResource.identifier,
        definitionResource: r.labDefinitionResourceId,
        identifier: r.identifier,

        // configuration: configuration.labResourceConfigurationId,
      },
    });
  }
  const definitionForm = {
    initialValues: {
      name,
      description,
      standby,
      type,
      info: info || '',
      status,
      ttl,
      viewType,
      resources: defaultResources,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('You must name this lab definition.'),
      description: Yup.string().required('Please provide a brief description.'),
      info: Yup.string().required('You must provide information for the info tab.'),
      type: Yup.string().required('You must select a type.'),
      status: Yup.string().required('You must choose a status.'),
      standby: Yup.string().required('Please choose a standby value.'),
      ttl: Yup.string().required('Please choose a TTL.'),
      viewType: Yup.string().required('You must select a view type.'),
      resources: Yup.array().nullable(),
    }),
    fields: [
      {
        type: 'text',
        label: 'Name',
        name: 'name',
        placeholder: 'Provide a unique name to help identify this definition in lists.',
      },
      {
        type: 'text',
        label: 'Description',
        name: 'description',
        placeholder: 'Provide a short description.',
      },
      {
        type: 'markdown',
        label: 'Information Tab',
        name: 'info',
      },
      {
        type: 'select',
        label: 'Lab Type',
        name: 'type',
        options: [
          {
            value: 'SOLO',
            label: 'SOLO',
          },
        ],
      },
      {
        type: 'select',
        label: 'Status',
        name: 'status',
        options: [
          {
            value: 'DISABLED',
            label: 'DISABLED',
          },
          {
            value: 'AVAILABLE',
            label: 'AVAILABLE',
          },
          {
            value: 'IMAGING',
            label: 'IMAGING',
          },
        ],
        helpText: 'To create a lab, but keep it private while it is in development, choose DISABLED.',
      },
      {
        type: 'select',
        label: 'Minimum Standby',
        name: 'standby',
        options: [
          {
            value: '0',
            label: '0',
          },
          {
            value: '1',
            label: '1',
          },
          {
            value: '2',
            label: '2',
          },
          {
            value: '3',
            label: '3',
          },
          {
            value: '4',
            label: '4',
          },
          {
            value: '5',
            label: '5',
          },
        ],
        helpText: 'Choose the minimum number of machines that should stay on standby.',
      },
      {
        type: 'select',
        label: 'TTL',
        name: 'ttl',
        options: [
          {
            value: '60',
            label: '60',
          },
          {
            value: '120',
            label: '120',
          },
          {
            value: '180',
            label: '180',
          },
          {
            value: '240',
            label: '240',
          },
          {
            value: '300',
            label: '300',
          },
          {
            value: '9999999',
            label: '9999999',
          },
        ],
      },
      {
        type: 'select',
        label: 'View Type',
        name: 'viewType',
        options: [
          {
            value: 'learner',
            label: 'Learner View',
          },
          {
            value: 'simple',
            label: 'Simple View',
          },
        ],
        helpText: 'Choose the type of view used to display this lab.  Learner is the default, which has all controls including resources. Simple is a slimmed down view.',
      },
      {
        name: 'resources',
        label: 'Resource(s)',
        helpText: 'Provide and configure the resources for this definition.',
        type: 'form-list',
        addLabel: 'Add Resource',
        initialValues: {
          name: '',
          // description: '',
          resource: '',
          visible: 'visible',
          // configuration: '',
        },
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
          },
          // {
          //   type: 'text',
          //   label: 'Description',
          //   name: 'description',
          // },
          {
            type: 'select',
            label: 'Resource',
            name: 'resource',
            options: resourceOptions,
          },
          {
            name: 'visible',
            type: 'select',
            label: 'Is this resource visible?',
            options: [
              {
                value: 'hidden',
                label: 'No, This Resource Is Hidden',
              },
              {
                value: 'visible',
                label: 'Yes, This Resource Is Visible',
              },
            ],
          },
          // {
          //   type: 'select',
          //   label: 'Configuration',
          //   name: 'configuration',
          //   options: configOptions,
          // },
        ],
        reorder: true,
      },
    ],
    noContainer: false,
    centerButton: false,
    observeChanges: true,
    onSubmit: async (values) => {
      const { viewType, name, resources: resourcesValue, standby, description, ttl, type, status, info } = values;

      // Prepare our attributes
      const attributes = definition?.attributes || [];
      const viewTypeIndex = findIndexById(attributes, 'viewType', 'key');
      if (viewTypeIndex === -1) {
        attributes.push({
          key: 'viewType',
          value: viewType,
        });
      } else {
        attributes[viewTypeIndex].value = viewType;
      }
      const standbyIndex = findIndexById(attributes, 'min-standby', 'key');
      if (standbyIndex === -1) {
        attributes.push({
          key: 'min-standby',
          value: standby,
        });
      } else {
        attributes[standbyIndex].value = standby;
      }
      const ttlIndex = findIndexById(attributes, 'ttl', 'key');
      if (ttlIndex === -1) {
        attributes.push({
          key: 'ttl',
          value: ttl,
        });
      } else {
        attributes[ttlIndex].value = ttl;
      }

      // Really just a resource object as identified by the ID (check the resource options)
      // And a nested configuration which was selected as well
      const transformedResources = [];
      // const hiddenResources = [];
      // const resourcesOrder = [];
      for (let i = 0; i < resourcesValue.length; i++) {
        const toTransform = resourcesValue[i];
        const { value: resourceData } = toTransform;
        const { resource, visible, name, identifier, definitionResource } = resourceData;

        const resourceTemplate = findById(allResources, parseInt(resource, 10), 'labResourceId');
        const transformedResource = {
          labResource: {
            labResourceId: resourceTemplate.labResourceId,
            identifier: resourceTemplate.identifier,
          },
          customResourceName: name || '',
          visible: visible === 'visible',
          ordinal: i,
        };
        if (definitionResource) {
          transformedResource.labDefinitionResourceId = definitionResource;
        }
        transformedResource.identifier = identifier || uuidv4();

        // if (visible !== 'visible') {
        //   hiddenResources.push(`${transformedResource.identifier}`);
        // }
        // resourcesOrder.push(`${transformedResource.identifier}`);
        transformedResources.push(transformedResource);
      }

      const metaIndex = findIndexById(attributes, 'meta', 'key');
      if (metaIndex === -1) {
        attributes.push({
          key: 'meta',
          value: JSON.stringify({
            info,
            // hiddenResources,
            // resourcesOrder,
          }),
        });
      } else {
        const existingMeta = attributes[metaIndex].value ? JSON.parse(attributes[metaIndex].value) : {};
        const newMeta = {
          ...existingMeta,
          info,
          // hiddenResources,
          // resourcesOrder,
        };
        attributes[metaIndex].value = JSON.stringify(newMeta);
      }

      const toPost = {
        ...definition,
        type,
        status,
        attributes,
        name,
        description,
        resourcesNew: transformedResources,
      };

      // Determine if there are any existing resources that need to be detached
      const toDetach = [];
      if (resources) {
        resources.forEach((res) => {
          if (findIndexById(transformedResources, res.labDefinitionResourceId, 'labDefinitionResourceId') === -1) {
            toDetach.push(res.labDefinitionResourceId);
          }
        });
      }

      if (!toPost.identifier) {
        toPost.identifier = uuidv4();
      }
      try {
        // Detach any resources that were removed
        if (toDetach.length) {
          const detachRequests = [];
          for (let i = 0; i < toDetach.length; i++) {
            detachRequests.push(putApiRequest(`/lab/resource/${toDetach[i]}/detach`, {}));
          }
          await Promise.all(detachRequests);
        }
        // console.log(toPost, 'would save');
        const result = await LabDefinition.saveDefinition(toPost);
        // console.log(result, 'this is the result');
        if (!id) {
          navigate(`/admin/definition/${result.labId}`);
        } else {
          loadDefinition();
        }
        Notify.success('The lab definition was saved.');
      } catch (err) {
        Notify.handleErrorResponse(err);
      }
    },
    submit: {
      label: 'Save Lab Definition',
    },
  };

  const isCreating = !id;

  return (
    <LoadingOrError loading={loading} error={null}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">{isCreating ? 'Create' : 'Update'} Definition</h1>
              <p className="text-sm text-gray-400">{isCreating ? 'Configure and create a new' : 'Update this existing'} lab definition.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              <div className="mb-3">
                <Form {...definitionForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminDefinition;
