import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { TemplateType } from '../../types/labs';
import Confirm from '../Overlays/confirm';

interface TemplateCardProps {
  template: TemplateType;
  handleSelect: (id: string | number) => Promise<void>;
  deleteLabTemplate?: (id: string | number) => Promise<void>;
}

const Template = ({ template, handleSelect, deleteLabTemplate }: TemplateCardProps) => {
  const { labId, clientStatus } = template;
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClick = () => {
    if (clientStatus === 'deleted') {
      return;
    }
    handleSelect(labId);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConfirm(true);
  };

  const handleDelete = () => {
    if (!deleteLabTemplate || clientStatus === 'deleted') {
      return;
    }
    deleteLabTemplate(labId);
  };

  const cursorClass = clientStatus === 'deleted' ? 'cursor-not-allowed' : 'cursor-pointer';
  const bgClass = clientStatus === 'deleted' ? 'bg-red-50' : 'bg-white';

  const btnClass =
    'shadow-sm rounded-md items-center px-2 py-1 border border-gray-300 text-xs font-medium ' +
    'text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500';
  return (
    <>
      <div className={`w-full rounded border border-gray-300 hover:border-blue-400 ${bgClass}`}>
        <div className={`block w-full ${cursorClass}`} onClick={handleClick}>
          <div className="mb-2 px-5 pt-2 text-left">
            <div className="flex flex-row">
              <div className="grow">
                <p className="font-medium">{template.name}</p>
                <p>{template.description}</p>
              </div>
              <div className="shrink">
                {!!deleteLabTemplate && (
                  <button title="Delete this lab template" type="button" onClick={handleDeleteClick} className={`${btnClass} ${cursorClass}`}>
                    <TrashIcon className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* <div className="flex flex-row gap-2 px-5 pb-3">
            <p className="mr-1">Resources:</p>
            {resourcesNew.map((resource) => (
              <div key={resource.labDefinitionResourceId} className="flex items-center rounded bg-gray-100 px-2 text-xs">
                {resource.customResourceName}
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <Confirm
        open={showConfirm}
        cancel={() => setShowConfirm(false)}
        action={handleDelete}
        title="Confirm delete"
        body="Are you sure you want to delete this Lab? This cannot be undone."
        actionText="Delete"
      />
    </>
  );
};

export default Template;
