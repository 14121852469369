// Columns look like: [key, label, hideLabel, isBold, isRight (default false)]
// Rows are just objects with the values matching the appropriate key in columns
// keyCol is the designated key for a given row, defaults to id

const Table = ({ columns, rows, keyCol = undefined }) => {
  if (!rows || !rows.length) {
    return null;
  }
  const realKeyCol = keyCol || 'id';
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column) => {
                      const { key, label, hideLabel } = column;
                      if (hideLabel) {
                        return (
                          <th key={key} scope="col" className="relative px-6 py-3">
                            <span className="sr-only">{label}</span>
                          </th>
                        );
                      }
                      return (
                        <th key={key} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, i) => (
                    <tr key={row[realKeyCol]} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      {columns.map((column) => {
                        const { key, isBold, isRight, render } = column;
                        const cellKey = `${row[realKeyCol]}_${key}`;
                        return (
                          <td key={cellKey} className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${isBold ? 'font-semibold' : ''} ${isRight ? 'text-right' : ''}`}>
                            {!!render && render(row)}
                            {!render && row[key]}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
