import { useState, useEffect } from 'react';
import moment from 'moment';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest } from '../../agents';
import SimplePaginator from '../../components/Common/simple-paginator.js';
import Table from '../../components/Common/table.js';
import { cleanSessionName } from '../../utils/helpers';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [rows, setRows] = useState(null);

  const limit = 10;

  const columns = [
    {
      key: 'lab',
      label: 'Lab',
    },
    {
      key: 'user',
      label: 'User',
    },
    {
      key: 'start',
      label: 'Start',
    },
    {
      key: 'end',
      label: 'End',
    },
  ];

  const load = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getApiRequest(`/user/session?nonDeletedOnly=true&page=${page}&size=${limit}`);
      setSessions(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the definitions
   */
  useEffect(() => {
    load();
  }, [page]);

  // Set rows based on sessions
  useEffect(() => {
    if (!sessions || !sessions.length) {
      setRows(null);
      return;
    }
    const newRows = [];
    sessions.forEach((session) => {
      const { name, userAccount, userSessionId, created, expires } = session;
      const { accountName, email } = userAccount || {};
      const cleanName = cleanSessionName(name);
      const rowKey = userSessionId;
      const createdAsSeconds = created / 1000;
      const expiresAsSeconds = expires / 1000;
      const formattedDate = moment.unix(createdAsSeconds).format('lll');
      const formattedDateExpires = moment.unix(expiresAsSeconds).format('lll');
      newRows.push({
        id: rowKey,
        lab: cleanName,
        user: (
          <>
            {accountName || '--'}
            <br />
            {email || '--'}
          </>
        ),
        start: formattedDate,
        end: formattedDateExpires,
      });
    });
    setRows(newRows);
  }, [sessions]);

  const from = sessions?.length === 0 && page === 0 ? 0 : page * limit + 1;
  const to = sessions?.length === 0 && page === 0 ? 0 : from + sessions.length - 1;

  // console.log(sessions, 'sessions');

  return (
    <LoadingOrError loading={loading} error={error}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">Dashboard</h1>
              <p className="text-sm text-gray-400">A quick overview of what is going on.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              <h1 className="text-2xl font-bold mb-1">Active User Sessions</h1>
              {!sessions.length && <p className="text-sm text-gray-700">There are no sessions to display.</p>}
              {!!sessions.length && <Table columns={columns} rows={rows} />}
              {/* {!!sessions.length &&
                sessions.map((session) => {
                  const { name, userAccount, userSessionId, created, expires, labInstanceIdentifier } = session;
                  const { accountName, accountSubscriptions, email } = userAccount || {};
                  const createdAsSeconds = created / 1000;
                  const expiresAsSeconds = expires / 1000;
                  const formattedDate = moment.unix(createdAsSeconds).format('lll');
                  const formattedDateExpires = moment.unix(expiresAsSeconds).format('lll');
                  return (
                    <div key={userSessionId} className="my-1 w-full rounded border border-gray-300 py-2 px-5 relative">
                      <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">Lab:</p>
                          <p className="mr-2 font-medium">
                            {name} ({labInstanceIdentifier})
                          </p>
                        </div>
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">Started:</p>
                          <p className="font-medium">{formattedDate}</p>
                        </div>
                        <div className="flex items-center text-sm">
                          <p className="mr-2 text-gray-500">Ends:</p>
                          <p className="font-medium">{formattedDateExpires}</p>
                        </div>
                        {!!userAccount && (
                          <div className="flex items-center text-sm">
                            <p className="mr-2 text-gray-500">User:</p>
                            <p className="font-medium">
                              {accountName} ({email})
                            </p>
                          </div>
                        )}
                        {!!accountSubscriptions && !!accountSubscriptions.length && (
                          <div className="flex items-center text-sm">
                            <p className="mr-2 text-gray-500">Role:</p>
                            <p className="font-medium">{accountSubscriptions[0]}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })} */}
            </div>
            <SimplePaginator
              from={from}
              to={to}
              previousUrl={page === 0 ? null : () => setPage(page - 1)}
              nextUrl={sessions.length < limit ? null : () => setPage(page + 1)}
              useButtons
            />
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminDashboard;
