const ConnectionError = ({ msg = '' }) => {
  const reload = () => {
    window.location.reload();
  };
  return (
    <div className="h-full w-full pt-48">
      <p className="text-center text-gray-100 my-8">An error was detected with your lab connection: {msg || 'Network connection lost.'}</p>
      {/* <p className="text-center my-2 text-gray-100">Most errors can be resolved by reloading your lab environment.</p> */}
      <p className="flex items-center justify-center my-8">
        <button className="bg-pink-700 text-white py-2 px-4 text-right rounded" onClick={reload} type="button">
          Reconnect
        </button>
      </p>
    </div>
  );
};

export default ConnectionError;
