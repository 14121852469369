import { v4 as uuidv4 } from 'uuid';
import { PlusCircleIcon, XCircleIcon, SelectorIcon } from '@heroicons/react/outline';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { findIndexById } from '../../../utils/helpers';

interface TextListProps {
  formik: any;
  name: string;
  placeholder?: string;
  hasError?: boolean;
  addLabel?: string;
  reorder?: boolean;
}

interface LocalValueType {
  id: string;
  value: string;
}

interface AddValueButtonProps {
  add: () => void;
  addLabel: string;
}

interface SingleTextProps {
  id: string;
  value: string;
  onChange: (id: string | number, value: string) => void;
  placeholder?: string;
  remove: (id: string | number) => void;
  reorder?: boolean;
}

const AddValueButton = ({ add, addLabel }: AddValueButtonProps) => {
  const c1 = 'inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm';
  const c2 = 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100';

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        add();
      }}
      type="button"
      className={`${c1} ${c2}`}
    >
      <PlusCircleIcon className="mr-2 inline h-5 w-5 text-gray-500" />
      {addLabel}
    </button>
  );
};

const SingleText = ({ id, value, onChange, placeholder, remove, reorder }: SingleTextProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const classes = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  return (
    <div ref={setNodeRef} style={style} className="flex flex-row items-center">
      {!!reorder && (
        <div className="shrink-0" {...attributes} {...listeners}>
          <SelectorIcon className="mr-2 h-5 w-5 cursor-move" aria-hidden="true" />
        </div>
      )}
      <div className="flex-1">
        <input
          className={`block w-full rounded-md sm:text-sm ${classes}`}
          id={id}
          name={id}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(id, e.target.value)}
        />
      </div>
      <div className="shrink">
        <button
          onClick={(e) => {
            e.preventDefault();
            remove(id);
          }}
          type="button"
          className="ml-2 mt-2"
        >
          <XCircleIcon className="h-6 w-6 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

const TextList = ({ formik, name, placeholder, hasError, addLabel = 'Add', reorder = false }: TextListProps) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';

  // Local copy of our formik values
  const localValues = formik.values[name];

  const updateLocalValues = (newLocalValues: LocalValueType[]) => {
    formik.setFieldValue(name, newLocalValues);
  };

  const add = () => {
    const newLocalValues = [
      ...localValues,
      {
        id: uuidv4(),
        value: '',
      },
    ];
    updateLocalValues(newLocalValues);
  };

  const remove = (id: string | number) => {
    const index = findIndexById(localValues, id);
    const newLocalValues = [...localValues];
    newLocalValues.splice(index, 1);
    updateLocalValues(newLocalValues);
  };

  const onChangeSingleText = (id: string | number, value: string | number) => {
    const index = findIndexById(localValues, id);
    const newLocalValues = [...localValues];
    newLocalValues[index] = { id, value };
    updateLocalValues(newLocalValues);
  };

  const movedLocalValues = (existingLocalValues: LocalValueType[], activeId: number | string, overId: number | string) => {
    const oldIndex = findIndexById(existingLocalValues, activeId);
    const newIndex = findIndexById(existingLocalValues, overId);
    return arrayMove(existingLocalValues, oldIndex, newIndex);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const newLocalValues = movedLocalValues(localValues, active.id, over.id);
      updateLocalValues(newLocalValues);
    }
  };

  return (
    <div className={`${wrapperClasses} space-y-4`}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={localValues} strategy={verticalListSortingStrategy}>
          {localValues.map((localValue: LocalValueType) => {
            const { id, value } = localValue;
            return <SingleText reorder={reorder} id={id} key={id} value={value} onChange={onChangeSingleText} placeholder={placeholder} remove={remove} />;
          })}
        </SortableContext>
      </DndContext>
      <div className="py-4">
        <AddValueButton add={add} addLabel={addLabel} />
      </div>
    </div>
  );
};

export default TextList;
