import Box from './box.js';
import ReportModal from './report-modal.js';
// import UserInfo from './user-info.js';
import ScoreSummary from './score-summary.js';
import SkillsSummary from './skills-summary.js';
import FlagsSummary from './flags-summary.js';

const Report = ({ open, close, summary, title, subtitle }) => {
  if (!summary) {
    return null;
  }

  // When we get points available back, and fix the elapsed spelling error below, update this
  const { skills, flags, 'time-seconds-elapsed': elapsedTime, 'points-earned': points, 'points-available': pointsAvailable } = summary;
  // console.log(summary, 'SUMMARY');

  return (
    <ReportModal open={open} cancel={close}>
      <div className="pt-4 px-4 text-neutral-100">
        <div className="grid grid-cols-2 space-x-4 mb-4">
          {/* <Box>
            <UserInfo />
          </Box> */}
          <Box>
            <h2 className="text-4xl text-center font-semibold mb-4">{title}</h2>
            {!!subtitle && <h4 className="text-xl text-center font-normal">{subtitle}</h4>}
          </Box>
          <Box>
            <ScoreSummary pointsEarned={points} pointsAvailable={pointsAvailable} timeInSeconds={elapsedTime} />
          </Box>
        </div>

        {!!skills && (
          <div className="mb-4">
            <Box>
              <SkillsSummary graphContainerClassName="max-w-xl mx-auto" skills={skills} />
            </Box>
          </div>
        )}

        <div className="mb-4">
          <Box>
            <FlagsSummary flags={flags} />
          </Box>
        </div>
      </div>
    </ReportModal>
  );
};

export default Report;
