/* eslint-disable react/jsx-filename-extension */
import { useState } from 'react';
import Modal from '../Modal/modal.js';

const StartTimer = ({ handleStart, minutes }) => {
  const [showModal, setShowModal] = useState(false);

  const startTimer = () => {
    setShowModal(false);
    handleStart();
  };

  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)} className="max-w-sm">
        <h3 className="text-gray-200 text-2xl font-bold mb-8">Ready to begin?</h3>
        <p className="text-gray-200 mb-6">You will have {minutes ? `${minutes} minutes` : 'a limited time'} to complete the assessment.</p>
        <div className="flex justify-between">
          <button className="bg-white h-10 px-8 text-xs rounded font-semibold" type="button" onClick={() => setShowModal(false)}>
            I&apos;m not ready yet
          </button>
          <button className="text-white h-10 px-8 text-xs bg-pink-700 rounded font-semibold" type="button" onClick={startTimer}>
            Let&apos;s get started
          </button>
        </div>
      </Modal>
      <button className="text-white h-10 px-8 text-xs bg-pink-700 rounded" type="button" onClick={() => setShowModal(true)}>
        Start
      </button>
    </>
  );
};

export default StartTimer;
