import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import clabSocket from '../../services/SocketConnection';
import useLabs from '../../hooks/useLabs';
import { handleClabEvent } from '../../utils/labUtil';
import Notify from '../../utils/notifications';
import LoadSpinner from '../../components/Loading/LoadSpinner';
import TerminalList from '../../components/Terminal';
import LabEvents from '../../components/Events/LabEvents';
import StatusBadge from '../../components/Badges';
import Lab from '../../types/labs';
import { StatusColors, statusNames } from '../../utils/constants/status';
import getConnected from '../../utils/resourceUtil';
import { useAuth } from '../../context/auth';

interface LabStartingProps {
  activeLab: Lab | null;
}
const LabStarting = ({ activeLab }: LabStartingProps) => {
  const { status } = activeLab || {};
  const colors = StatusColors[status as keyof typeof StatusColors];

  return (
    <div className="mx-auto my-20 text-center">
      <LoadSpinner />
      Lab is starting... This could take a few minutes.
      <div className="flex justify-center">
        <StatusBadge text={status} colors={colors} />
      </div>
      <LabEvents />
    </div>
  );
};

const LearnerLab = () => {
  const { getLab, activeLab, addActiveLab, connect } = useLabs();
  const auth = useAuth();
  const { user } = auth;
  const params = useParams();
  const { labId, sessionId } = params;
  const { status, resources } = activeLab || {};
  const isRunning = [statusNames.RUNNING, statusNames.READY].includes(status || '');

  useEffect(() => {
    const fetchLab = async () => {
      if (!auth.getJwt()) {
        return;
      }
      try {
        await getLab(labId, sessionId);

        clabSocket.subscribe('/user/topic/lab', (message) => {
          handleClabEvent(message);
          addActiveLab(message);
        });
        clabSocket.connect(sessionId, auth.getJwt(), auth.account?.id);
      } catch (error) {
        Notify.handleErrorResponse(error);
      }
    };

    fetchLab();
    return () => clabSocket.disconnect();
  }, [labId, user]);

  /** connect first resource for the learner */
  useEffect(() => {
    const connected = getConnected(resources);
    if (isRunning && resources?.length && !connected.length) {
      connect(resources[0]);
    }
  }, [status, resources]);

  if (!activeLab || !isRunning) {
    return <LabStarting activeLab={activeLab} />;
  }

  return (
    <div className="w-full md:min-h-[calc(100vh-56px)]">
      <div className="relative hidden w-full flex-col md:fixed md:flex md:flex-row">
        <div className="w-full overflow-y-scroll border p-8 md:max-h-[calc(100vh-56px)] md:min-h-[calc(100vh-56px)] md:w-2/5 lg:w-2/5 ">
          <p className="mb-3 text-lg font-medium">Course Content</p>
        </div>
        <div className="relative grid w-full grid-cols-1 gap-1 bg-gray-800 md:w-3/5 lg:w-3/5">
          <TerminalList activeLab={activeLab} />
        </div>
      </div>
    </div>
  );
};

export default LearnerLab;
