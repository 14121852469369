import { Routes, Route } from 'react-router-dom';
import LabCreator from './pages/LabCreator';
import Dashboard from './pages/Dashboard';
import Layout from './components/Layout';
import AdminContainer from './components/Layout/Admin/container.js';
import Labs from './pages/LabCreator/Labs';
import LearnerLab from './pages/LearnerLab';
import UserSession from './pages/UserSession';
import AdminLogin from './pages/AdminLogin';
import NewLearnerView from './pages/LearnerLab/NewLearnerView';
import LearnerReport from './pages/LearnerLab/LearnerReport.js';
import AdminResources from './pages/Admin/resources.js';
import AdminDefinitions from './pages/Admin/definitions.js';
import AdminDefinition from './pages/Admin/definition.js';
import AdminUsers from './pages/Admin/users.js';
import AdminShowDefinition from './pages/Admin/show-definition.js';
import AdminCreator from './pages/Admin/creator.js';
import AdminUser from './pages/Admin/user.js';
import AdminDashboard from './pages/Admin/dashboard.js';
import AdminResource from './pages/Admin/resource.js';
import AdminUserSessions from './pages/Admin/user-sessions.js';
import AdminDefinitionSessions from './pages/Admin/definition-sessions.js';
import AdminLogout from './pages/AdminLogout';
import AdminSettings from './pages/Admin/settings.js';
import AdminSetting from './pages/Admin/setting.js';

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Layout />}> */}
      <Route path="/" element={<AdminContainer />}>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/settings" element={<AdminSettings />} />
        <Route path="/admin/settings/:id" element={<AdminSetting />} />
        <Route path="/admin/resources" element={<AdminResources />} />
        <Route path="/admin/resource" element={<AdminResource />} />
        <Route path="/admin/resource/:id" element={<AdminResource />} />
        <Route path="/admin/definitions" element={<AdminDefinitions />} />
        <Route path="/admin/definition" element={<AdminDefinition />} />
        <Route path="/admin/definition/:id" element={<AdminDefinition />} />
        <Route path="/admin/definition/:id/show" element={<AdminShowDefinition />} />
        <Route path="/admin/definition/:id/sessions" element={<AdminDefinitionSessions />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/user" element={<AdminUser />} />
        <Route path="/admin/user/:id" element={<AdminUser />} />
        <Route path="/admin/user/:id/sessions" element={<AdminUserSessions />} />
        <Route path="/admin/creator/:identifier/session/:sessionId/:labId" element={<AdminCreator />} />
        <Route path="/" element={<LabCreator />} />
        <Route path="labs" element={<LabCreator />} />
        <Route path="lab/:labId/session/:sessionId/:definitionId" element={<Labs />} />
        <Route path="dashboard/sessions" element={<UserSession />} />
        <Route path="dashboard/sessions/:sessionId" element={<Dashboard />} />
      </Route>
      <Route path="/" element={<Layout learner />}>
        <Route path="learner-lab/:labId" element={<LearnerLab />} />
      </Route>
      <Route path="learner/new" element={<NewLearnerView />} />
      <Route path="learner/report" element={<LearnerReport />} />
      {/* <Route path="/local-login" element={<AdminLogin />} /> */}
      <Route path="/new-login" element={<AdminLogin />} />
      <Route path="/logout" element={<AdminLogout />} />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <h1 className="flex min-h-screen w-full items-center justify-center text-4xl">404 Nothing Here!</h1>
          </main>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
