import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DashboardEvent } from '../../agents';

interface KeyEventProps {
  sessionId: string | undefined;
}

const columns = [
  {
    name: 'Id',
    selector: (row: any) => row.labId,
  },
  {
    name: 'Session Id',
    selector: (row: any) => row.sessionId,
  },
  {
    name: 'Key Code',
    selector: (row: any) => row.keyCode,
  },
  {
    name: 'Key Value',
    selector: (row: any) => row.keyValue,
  },
  {
    name: 'Time',
    selector: (row: any) => row.timestamp,
  },
];

const KeyEvent = ({ sessionId }: KeyEventProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchKeyEventResponse = async () => {
      const keyEventResponse = await DashboardEvent.getKeyboardHistory(sessionId || '');
      setData(keyEventResponse);
    };
    fetchKeyEventResponse();
  }, [sessionId]);

  return (
    <div className="mt-12">
      <div className="rounded-sm border">
        <DataTable title="Key Event History" columns={columns} data={data} />
      </div>
    </div>
  );
};

export default KeyEvent;
