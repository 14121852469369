import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DashboardEvent } from '../../agents';

interface LabStateProps {
  sessionId: string | undefined;
}

const columns = [
  {
    name: 'Id',
    selector: (row: any) => row.labId,
  },
  {
    name: 'Session Id',
    selector: (row: any) => row.sessionId,
  },
  {
    name: 'New State',
    selector: (row: any) => row.newState,
  },
  {
    name: 'Old State',
    selector: (row: any) => row.oldState,
  },
  {
    name: 'Time',
    selector: (row: any) => row.timestamp,
  },
];

const LabState = ({ sessionId }: LabStateProps) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchLabState = async () => {
      const labStateResponse = await DashboardEvent.getLabStateHistory(sessionId || '');
      setData(labStateResponse);
    };
    fetchLabState();
  }, [sessionId]);

  return (
    <div>
      <div className="rounded-sm border">
        <DataTable title="Lab State" columns={columns} data={data} />
      </div>
    </div>
  );
};

export default LabState;
