/* eslint-disable react/jsx-filename-extension */
import Modal from '../Modal/modal.js';

const CreatorExitModal = ({ open, close, handleExit, handleExitAndSave }) => {
  const title = 'Exit Creator View';
  const body = `You're about to end your creator session. Would you like to save your changes? Choose Save & Exit to save your changes as a new snapshot.`;
  return (
    <Modal open={open} onClose={close} className="max-w-sm">
      <h3 className="text-gray-200 text-2xl font-bold mb-8">{title}</h3>
      <p className="text-gray-200 mb-6">{body}</p>
      <div className="flex justify-between">
        <button className="bg-white h-10 px-4 text-xs rounded font-semibold" type="button" onClick={close}>
          Cancel
        </button>
        <button className="text-white h-10 px-4 text-xs bg-pink-500 rounded font-semibold" type="button" onClick={handleExit}>
          Exit Without Saving
        </button>
        <button className="text-white h-10 px-4 text-xs bg-pink-700 rounded font-semibold" type="button" onClick={handleExitAndSave}>
          Save & Exit
        </button>
      </div>
    </Modal>
  );
};

export default CreatorExitModal;
