import MDEditor from '@uiw/react-md-editor';

interface MarkdownProps {
  formik: any;
  name: string;
  hasError?: boolean;
}

const Markdown = ({ formik, name, hasError }: MarkdownProps) => {
  const classes = hasError
    ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    : 'focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300';

  const updateValue = (val?: string) => {
    formik.setFieldValue(name, val);
  };

  const value = formik.values[name];

  const commandsBlacklist = ['image', 'hr', 'strikethrough', 'checked-list', 'live', 'fullscreen'];

  return (
    <div className={`block w-full rounded-md border shadow-sm sm:text-sm ${classes}`} data-color-mode="light">
      <MDEditor
        value={value}
        onChange={updateValue}
        preview="edit"
        visibleDragbar={false}
        commandsFilter={(command) => {
          return command && command.name && commandsBlacklist.indexOf(command.name) === -1 ? command : false;
        }}
      />
      {/* <MDEditor.Markdown source={value} /> */}
    </div>
  );
};

export default Markdown;
