import Guacamole from 'guacamole-common-js';
import { Event } from '../agents';

// What is id? the backend requires it
interface UserData {
  id?: number | string;
  sessionId?: string;
  jwt?: string;
}

export default class GuacConnection {
  public client;

  public tunnel;

  private connectionString = '';

  public mouse: any;

  public keyboard: any;

  public input = '';

  public userData: UserData;

  public error: any;

  private withCredentials: boolean;

  ONENTER = 65293;

  constructor(url: string, userData: UserData) {
    this.withCredentials = process.env.REACT_APP_BASE_URL !== process.env.REACT_APP_CLAB_API_URL;
    // const tunnel = new Guacamole.WebSocketTunnel(url, this.withCredentials, { Authorization: `Bearer ${userData.jwt}` });
    const tunnel = new Guacamole.WebSocketTunnel(url);
    this.tunnel = tunnel;
    this.client = new Guacamole.Client(tunnel);
    this.userData = userData;
  }

  /**
   * Convert param object to string
   * @param params
   */
  // private paramsToString(params: ConnectionParams) {
  private paramsToString(params: string) {
    this.connectionString = params;
    // this.connectionString = queryString.stringify(params);
    // this.connectionString = Object.keys(params)
    //   .map((e) => `${encodeURIComponent(e)}=${encodeURIComponent(params[e as keyof ConnectionParams])}`)
    //   .join('&');
  }

  /**
   * Connect to Guac and set the mouse and keyboard
   * @param params
   */
  // public connect(params: ConnectionParams) {
  public connect(params: string, ip: string, errorHandler?: (error: string) => void) {
    console.log(new Date(), 'CALLING CONNECT', params, ip);
    this.paramsToString(params);
    this.client.connect(this.connectionString);

    const displayElement = this.client.getDisplay().getElement();
    this.mouse = new Guacamole.Mouse(displayElement);

    const el = document.getElementById(ip);
    this.keyboard = new Guacamole.Keyboard(el);

    this.tunnel.onerror = (error: { code: number; message: string; isError: () => void }) => {
      // console.log(error, 'there was an error on the tunnel, did we detect it?');
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onerror = (error: { code: number; message: string; isError: () => void }) => {
      // console.log(error, 'there was an error, did we detect it?');
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onclipboard = (stream: any, type: string) => {
      // console.log(stream, type, 'this is a inside on clipboard');
      if (type === 'text/plain') {
        const reader = new Guacamole.StringReader(stream);
        let str = '';
        reader.ontext = (newText: string) => {
          str = `${str}${newText}`;
        };
        reader.onend = () => {
          // console.log(str, 'str should now be complete and should be written');
        };
      }
    };
  }

  /**
   * Start mouse event listener
   */
  public startMouseListener() {
    this.mouse.onmousedown = (mouseState: any) => {
      this.client.sendMouseState(mouseState);
    };
    this.mouse.onmouseup = (mouseState: any) => {
      this.client.sendMouseState(mouseState);
    };
    this.mouse.onmousemove = (mouseState: any) => {
      this.client.sendMouseState(mouseState);
    };
  }

  private saveKeyBoardEvents() {
    const keyEvent = { ...this.userData, keyValue: this.input, created: Date.now(), keyCode: this.ONENTER };
    Event.saveUserKeyEvent(keyEvent);
    this.input = '';
  }

  /**
   * Start keyboard event listener
   */
  public startKeyboardListener() {
    this.keyboard.onkeydown = (keysym: any) => {
      this.client.sendKeyEvent(1, keysym);
    };

    // On enter, save user input @TODO on backspace update input
    this.keyboard.onkeyup = (keysym: number) => {
      if (keysym === this.ONENTER) {
        this.saveKeyBoardEvents();
      } else {
        this.input += String.fromCharCode(keysym);
      }
      this.client.sendKeyEvent(0, keysym);
    };
  }

  /**
   * Resize guacamole terminal
   * @param containerRef
   * @returns
   */
  public resizeTerminal(containerElement: HTMLDivElement) {
    if (!containerElement) {
      return;
    }
    const width = Math.round(containerElement.clientWidth);
    const height = Math.round(containerElement.clientHeight);
    this.client.sendSize(width, height);
  }

  /**
   * Send text to the guac clipboard
   */
  public sendToClipboard(value: string) {
    const stream = this.client.createClipboardStream('text/plain');
    const writer = new Guacamole.StringWriter(stream);
    writer.sendText(value);
    writer.sendEnd();
  }
}
