const LabEvents = () => {
  return (
    <div>
      <h2 className="mt-8 mb-1">Clab events</h2>
      <textarea id="clab-events" className="h-64 w-full border border-gray-400 bg-white p-4 text-black" />
    </div>
  );
};

export default LabEvents;
