import { ClockIcon } from '@heroicons/react/outline';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import { convertSecsToHHMMSS } from '../../utils/labUtil';

const ScoreSummary = ({ pointsEarned, pointsAvailable, timeInSeconds }) => {
  const percent = pointsAvailable ? Math.round((pointsEarned / pointsAvailable) * 100) : 0;
  const percentToDisplay = `${percent}%`;
  const score = pointsEarned;
  const time = convertSecsToHHMMSS(timeInSeconds);

  const circleProps = {
    percent: percent > 100 ? 100 : percent,
    colorSlice: '#ffffff',
    // colorCircle: '#cccccc',
    colorCircle: '#383034',
    // fontColor: '#365b74',
    // fontSize: '1.6rem',
    // fontWeight: 400,
    size: 100,
    stroke: 12,
    strokeBottom: 12,
    speed: 70,
    // cut: 0,
    // rotation: 127,
    rotation: 180,
    fill: 'transparent',
    unit: '',
    textPosition: '0.35em',
    animationOff: false,
    // strokeDasharray: '10,1',
    inverse: false,
    round: true,
    number: false,
    // cut: 20,
    // Choose the gradient you want for the line
    // Pink gradient
    linearGradient: ['#fc58ae', '#cf287f'],
    // Green graident
    // linearGradient: ['#51d135', '#84fb5e'],

    // styles: {
    //   borderRadius: '50%',
    //   boxShadow: 'inset 0 0 25px 10px #a2caff'
    // }
  };
  return (
    <div className="flex flex-row justify-center">
      <div className="relative">
        <CircularProgressBar {...circleProps}>
          <div className="absolute top-8 pt-1 text-darkblue w-full">
            <p className="text-xl font-semibold text-center ">{percentToDisplay}</p>
          </div>
        </CircularProgressBar>
      </div>
      <div className="pl-4 pt-3 font-light">
        <p className="text-2xl mb-1">
          Score:
          <span className="ml-2 font-bold">{score}</span>
        </p>
        <p className="text-2xl">
          <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
          {time}
        </p>
      </div>
    </div>
  );
};

export default ScoreSummary;
