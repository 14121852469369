import { SearchIcon } from '@heroicons/react/solid';
import { useState } from 'react';

const Search = ({ label = '', placeholder = 'Search...', handleSearch }) => {
  const [query, setQuery] = useState('');

  const performSearch = (e) => {
    e.preventDefault();
    handleSearch(query);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setQuery('');
    handleSearch();
  };

  const btn1 = `relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-blue-300 bg-blue-500 px-4`;
  const btn2 = `${btn1} py-2 text-sm font-medium text-gray-50 hover:bg-blue-600 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500`;

  return (
    <div>
      {!!label && (
        <label htmlFor="search" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <form onSubmit={performSearch}>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              type="text"
              name="search"
              onChange={(e) => setQuery(e.target.value)}
              id="search"
              value={query}
              className="block w-full rounded-none rounded-l-md border-gray-300 pl-2 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder={placeholder}
            />
          </div>
          <button type="button" onClick={performSearch} className={btn2}>
            <SearchIcon className="h-5 w-5 text-gray-50" aria-hidden="true" />
            <span>Search</span>
          </button>
        </div>
      </form>
      <div className="h-4 mt-1">
        {!!query && (
          <button type="button" onClick={clearSearch} className="text-gray-700 text-sm underline hover:text-gray-800">
            Clear search query
          </button>
        )}
      </div>
    </div>
  );
};

export default Search;
