import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { PageLoadingProp } from '../../types/labs';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import useTemplate from '../../hooks/useTemplate';
import useLabs from '../../hooks/useLabs';
import LabCard from '../../components/Labs';
import Notify from '../../utils/notifications';
import { statusNames } from '../../utils/constants/status';
import TemplateCard from '../../components/Templates';
import { Infra } from '../../agents';
import AdminTools from './AdminTools';
import FormModal from '../../components/Overlays/form-modal';

const LabCreator = () => {
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);
  const [definitionId, setDefinitionId] = useState<number | string | null>(null);
  const { fetchTemplates, templates, handleTemplateSelect, updateTemplateClientStatus } = useTemplate();
  const { fetchUserLabs, userLabs, deleteLab } = useLabs();
  const [pageLoading, setPageLoading] = useState<PageLoadingProp>({ loading: true, error: null });
  const navigate = useNavigate();

  /**
   * Fetch Templates andUser Labs
   */
  useEffect(() => {
    const getTemplatesAndLabs = async () => {
      try {
        await fetchTemplates();
        await fetchUserLabs();
      } catch (error) {
        Notify.handleErrorResponse(error);
        setPageLoading({ ...pageLoading, error });
      }
      setPageLoading({ ...pageLoading, loading: false });
    };

    getTemplatesAndLabs();
  }, []);

  /** Handle template select */
  const startDefinitionSelect = (id: string | number) => {
    setDefinitionId(id);
    setShowDefinitionModal(true);
    return Promise.resolve();
  };

  const handleSelect = async (name?: string) => {
    if (!definitionId) {
      // Should not be possible, but just in case...
      Notify.error('No lab defition selected, please try again');
      return;
    }
    try {
      await handleTemplateSelect(definitionId, name);
    } catch (error) {
      Notify.handleErrorResponse(error);
      return;
    }

    // Refresh the labs available
    await fetchUserLabs();
  };

  const handleUserLabClick = (labId: string, sessionId: string, id: string | number) => {
    navigate(`/lab/${labId}/session/${sessionId}/${id}`);
  };

  const handleLearnerLaunch = (labId: string, sessionId: string, viewType = 'simple') => {
    const simpleToggle = viewType === 'learner' ? '' : '&s=true';
    navigate(`/learner/new?labInstanceIdentifier=${labId}&session=${sessionId}&token=${localStorage.getItem('jwt')}&c=true${simpleToggle}`);
  };

  const handleLabTemplateDelete = async (labId: string | number) => {
    try {
      await Infra.deleteLabTemplate(labId);
      // Optimistically mark this as deleted
      updateTemplateClientStatus(labId, 'deleted');
      Notify.success('The Lab Template has been deleted.');
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
  };

  const labDefinitionNameForm = {
    initialValues: {
      sessionName: '',
    },
    validationSchema: Yup.object({
      // sessionName: Yup.string().required('You must provide a name.'),
      sessionName: Yup.string().nullable(),
    }),
    fields: [
      {
        type: 'slug',
        name: 'sessionName',
        placeholder: 'Enter a unique name',
        helpText: 'You will need to use characters, dashes, and numbers only.',
      },
    ],
    noContainer: true,
    centerButton: true,
    onSubmit: (values: any) => {
      const { sessionName } = values;
      handleSelect(sessionName);
      setShowDefinitionModal(false);
    },
    submit: {
      label: 'Create Lab Instance',
    },
  };

  const readyLabs = userLabs.filter((lab) => lab.status !== statusNames.DELETING);
  return (
    <LoadingOrError loading={pageLoading.loading} error={pageLoading.error}>
      <>
        <div className="px-4 lg:px-8">
          <div className="mx-auto flex w-full flex-col md:w-2/3 lg:w-1/2">
            <div className="mt-10">
              <h1 className="text-3xl font-bold">Create a New Lab</h1>
            </div>
            <div className="py-5">
              <div className="mb-2">
                <p className="mb-1 text-lg font-medium">Lab Definitions</p>
                <p className="text-xs text-gray-400">Select a Lab Definition to launch a new Lab Instance in Creator mode.</p>
              </div>
              <div className="flex flex-col gap-3">
                {templates.map((template) => (
                  <TemplateCard key={template.labId} template={template} handleSelect={startDefinitionSelect} deleteLabTemplate={handleLabTemplateDelete} />
                ))}
              </div>
            </div>

            <div className="mt-10">
              <h1 className="text-3xl font-bold">Start or Join an Existing Lab</h1>
            </div>
            <div className="pb-12 pt-5">
              <div className="mb-2">
                <p className="mb-1 text-lg font-medium">Lab Instances</p>
                <p className="text-xs text-gray-400">Select a Lab Instances to connect and continue working.</p>
              </div>
              {readyLabs.map((lab) => (
                <div key={lab.labId} className="flex flex-col py-3">
                  <LabCard fullClick withControls lab={lab} listView onClick={handleUserLabClick} deleteLab={deleteLab} learnerLaunch={handleLearnerLaunch} />
                </div>
              ))}
            </div>
            <AdminTools />
          </div>
        </div>
        <FormModal
          open={!!showDefinitionModal}
          form={labDefinitionNameForm}
          autoForm={false}
          title="Create New Lab"
          body="Optionally provide a name for your new lab to make it easier to identify in lists."
          cancel={() => setShowDefinitionModal(false)}
        />
      </>
    </LoadingOrError>
  );
};

export default LabCreator;
