import { useState, useEffect } from 'react';
import queryString from 'query-string';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { deleteApiRequest, getApiRequest } from '../../agents';
import SimplePaginator from '../../components/Common/simple-paginator.js';
import ResourceCard from '../../components/Resources/resource-card.js';
import Confirm from '../../components/Overlays/confirm';
import Search from '../../components/Common/search.js';

const AdminResources = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [resources, setResources] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const limit = 10;

  const getResources = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getApiRequest(`/lab/resource?page=${page}&size=${limit}`);
      setResources(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  const searchResources = async (q) => {
    console.log(q);
    if (!q) {
      await getResources();
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const searchString = queryString.stringify({ query: q, page, size: limit });
      console.log(searchString);
      const result = await getApiRequest(`/lab/resource?${searchString}`);
      setResources(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the resources
   */
  useEffect(() => {
    getResources();
  }, [page]);

  const deleteResource = async () => {
    if (!toDelete) {
      return;
    }
    try {
      setLoading(true);
      await deleteApiRequest(`/lab/resource/${toDelete}`, {});
      Notify.success('The resource was deleted.');
      await getResources();
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setShowConfirm(false);
    setToDelete(null);
    setLoading(false);
  };

  const showDelete = (id) => {
    setToDelete(id);
    setShowConfirm(true);
  };
  const cancelDelete = () => {
    setToDelete(null);
    setShowConfirm(false);
  };

  const from = page * limit + 1;
  const to = from + resources.length - 1;

  return (
    <LoadingOrError loading={loading} error={error}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">Resources</h1>
              <p className="text-sm text-gray-400">A list of available lab resources.</p>
            </div>
            <div>
              <a href="/admin/resource" className="bg-indigo-600 hover:bg-indigo-700 text-white inline-flex rounded-md border px-4 py-2 text-sm font-medium shadow-sm">
                Create New Resource
              </a>
            </div>
          </div>

          <div className="py-5 w-96">
            <Search placeholder="Search resources..." handleSearch={searchResources} />
          </div>

          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              {!resources.length && <p className="text-sm text-gray-700">There are results to display.</p>}
              {!!resources.length &&
                resources.map((resource) => <ResourceCard showDelete={() => showDelete(resource.labResourceId)} showLinks resource={resource} key={resource.labResourceId} />)}
            </div>
            <SimplePaginator
              from={from}
              to={to}
              previousUrl={page === 0 ? null : () => setPage(page - 1)}
              nextUrl={resources.length < limit ? null : () => setPage(page + 1)}
              useButtons
            />
          </div>
        </div>
      </div>
      <Confirm
        open={showConfirm}
        cancel={cancelDelete}
        action={deleteResource}
        title="Confirm delete"
        body="Are you sure you want to delete this Resource? This cannot be undone."
        actionText="Delete"
      />
    </LoadingOrError>
  );
};

export default AdminResources;
