/* eslint-disable react/jsx-filename-extension */
import ReactTooltip from 'react-tooltip';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import StatusBadge from '../Badges';
import { StatusColors } from '../../utils/constants/status';
import SmallButton from '../Common/small-button.js';

const ResourceBox = ({ resource, isActive, connect, disconnect, alwaysConnect = false }) => {
  const copyText = (txt) => {
    const event = new CustomEvent('copyToRemote', { detail: { str: txt } });
    window.dispatchEvent(event);
  };
  // console.log(resource, 'this is what I have on a resource...');
  const { connectable, name, password, 'user-name': userName, status, customResourceName } = resource;
  const isAllowedToConnect = alwaysConnect || connectable;
  let canConnectAsLearner = connectable && !!connect && !!disconnect && !alwaysConnect;
  let hasConnectButtons = !canConnectAsLearner && isAllowedToConnect && !!connect && !!disconnect;
  let statusColors = StatusColors[isActive ? 'running' : 'stopped'];
  let statusMessage = isActive ? 'Connected' : 'Ready';
  // If we are pending, imaging or booting, update our status color and message appropriately
  if (status === 'pending') {
    statusColors = StatusColors.pending;
    statusMessage = 'Pending';
    hasConnectButtons = false;
    canConnectAsLearner = false;
  } else if (status === 'imaging') {
    statusColors = StatusColors.imaging;
    statusMessage = 'Imaging';
    hasConnectButtons = false;
    canConnectAsLearner = false;
  } else if (status === 'booting') {
    statusColors = StatusColors.imaging;
    statusMessage = 'Loading';
    hasConnectButtons = false;
    canConnectAsLearner = false;
  }
  const handleConnectClick = () => {
    if (!isActive) {
      connect(resource);
      return;
    }
    disconnect(resource);
  };

  let statusBg = canConnectAsLearner ? 'hover:bg-blue-600 cursor-pointer' : '';
  if (canConnectAsLearner && isActive) {
    statusBg = 'bg-blue-600';
  }
  const handleBoxClick = () => {
    if (canConnectAsLearner && !isActive) {
      connect(resource);
    }
  };

  return (
    <div onClick={handleBoxClick} className={`border border-blue-400 cursor rounded-lg py-6 px-4 mb-4 relative ${statusBg}`}>
      {isAllowedToConnect && (
        <div className="absolute -top-3 right-3">
          <StatusBadge text={statusMessage} colors={statusColors} />
        </div>
      )}
      <div className="flex justify-between">
        <div>
          <p className="text-base font-semibold">{customResourceName || name}</p>
          {userName && password && (
            <div className="mt-2">
              <p className="text-xs mb-1">
                {`username: ${userName}`}
                <CopyToClipboard text={userName} onCopy={() => copyText(userName)}>
                  <span data-tip="Click to copy to remote clipboard">
                    <ClipboardCopyIcon className="inline ml-2 -mt-1 h-4 w-4 cursor-pointer" />
                  </span>
                </CopyToClipboard>
              </p>
              <p className="text-xs">
                {`password: ${password}`}
                <CopyToClipboard text={password} onCopy={() => copyText(password)}>
                  <span data-tip="Click to copy to remote clipboard">
                    <ClipboardCopyIcon className="inline ml-2 -mt-1 h-4 w-4 cursor-pointer" />
                  </span>
                </CopyToClipboard>
              </p>
            </div>
          )}
        </div>
        {hasConnectButtons && <SmallButton onClick={handleConnectClick}>{isActive ? 'Disconnect' : 'Connect'}</SmallButton>}
      </div>
    </div>
  );
};

const ResourcesTab = ({ activeResource, resources = [], connect = null, disconnect = null, alwaysConnect = false }) => {
  const vms = [];
  const apps = [];
  resources.forEach((resource) => {
    if (alwaysConnect || resource.connectable) {
      vms.push(resource);
    } else if (!resource.hidden) {
      apps.push(resource);
    }
  });

  return (
    <div>
      {!!vms.length && (
        <div className="mb-8">
          <h3 className="mb-4">Virtual Machines</h3>
          {vms.map((resource) => {
            const indexName = resource.customResourceName || resource.name;
            const isActive = activeResource === indexName;
            return (
              <ResourceBox key={resource.labDefinitionResourceId} resource={resource} isActive={isActive} connect={connect} disconnect={disconnect} alwaysConnect={alwaysConnect} />
            );
          })}
        </div>
      )}
      {!!apps.length && (
        <div>
          <h3 className="mb-4">Applications</h3>
          {apps.map((resource) => {
            const indexName = resource.customResourceName || resource.name;
            const isActive = activeResource === indexName;
            return <ResourceBox key={resource.labDefinitionResourceId} resource={resource} isActive={isActive} />;
          })}
        </div>
      )}
      <ReactTooltip place="top" type="dark" effect="solid" />
    </div>
  );
};

export default ResourcesTab;
