import { useState, useEffect } from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import LoadingOrError from '../../components/Loading/LoadingOrError';
import Notify from '../../utils/notifications';
import { getApiRequest } from '../../agents';
import SimplePaginator from '../../components/Common/simple-paginator.js';
import SmallButton from '../../components/Common/small-button.js';

const SettingCard = ({ setting }) => {
  return (
    <div className="my-1 w-full rounded border border-gray-300 py-2 px-5 relative">
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
        <div className="flex items-center text-sm">
          <p className="mr-2 text-gray-500">Name:</p>
          <p className="mr-2 font-medium">{setting.name}</p>
        </div>
        <div className="flex items-center text-sm">
          <p className="mr-2 text-gray-500">Value:</p>
          <p className="font-medium">{setting.value}</p>
        </div>
        <div className="flex items-center text-sm">
          <p className="mr-2 text-gray-500">Description:</p>
          <p className="font-medium">{setting.description}</p>
        </div>
      </div>
      <div className="absolute top-2 right-2 space-x-2">
        <SmallButton href={`/admin/settings/${setting.configurationId}`}>
          <PencilIcon className="h-4" />
        </SmallButton>
      </div>
    </div>
  );
};

const AdminSettings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [settings, setSettings] = useState([]);

  const limit = 10;

  const getSettings = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getApiRequest(`/config/all?page=${page}&size=${limit}`);
      setSettings(result);
    } catch (error) {
      Notify.handleErrorResponse(error);
    }
    setLoading(false);
  };

  /**
   * Load the settings
   */
  useEffect(() => {
    getSettings();
  }, [page]);

  const from = page * limit + 1;
  const to = from + settings.length - 1;

  return (
    <LoadingOrError loading={loading} error={error}>
      <div className="px-4 lg:px-8">
        <div className="mx-auto flex w-full flex-col 2xl:w-3/4">
          <div className="mt-10 flex justify-between">
            <div>
              <h1 className="text-3xl font-bold mb-1">Global Settings</h1>
              <p className="text-sm text-gray-400">A list of configurable global settings.</p>
            </div>
          </div>
          <div className="py-5">
            <div className="flex flex-col gap-3 mb-4">
              {!settings.length && <p className="text-sm text-gray-700">There are results to display.</p>}
              {!!settings.length && settings.map((setting) => <SettingCard showLinks setting={setting} key={setting.configurationId} />)}
            </div>
            <SimplePaginator
              from={from}
              to={to}
              previousUrl={page === 0 ? null : () => setPage(page - 1)}
              nextUrl={settings.length < limit ? null : () => setPage(page + 1)}
              useButtons
            />
          </div>
        </div>
      </div>
    </LoadingOrError>
  );
};

export default AdminSettings;
