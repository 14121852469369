import { Resource } from '../../types/labs';
import { StatusColors, statusNames } from '../../utils/constants/status';
import StatusBadge from '../Badges';
import Controls from './Controls';

interface ResourceCardProp {
  labId: string;
  labStatus: string;
  resources: Resource[];
  connect: (resource: Resource) => void;
  disconnect: (resource: Resource) => void;
}

const ResourceCards = ({ labId, labStatus, resources, connect, disconnect }: ResourceCardProp) => {
  // console.log(resources, 'resources');
  if (![statusNames.RUNNING, statusNames.READY].includes(labStatus)) {
    return null;
  }
  if (!resources || !resources.length) {
    console.log(labStatus, 'lab status...');
    return (
      <div>
        <p>No Resources!</p>
      </div>
    );
  }
  const getStatusColors = (status: string | undefined) => StatusColors[status as keyof typeof StatusColors];
  return (
    <>
      {resources.map((resource) => (
        <div key={resource.ip} className="my-5 w-full rounded border border-gray-300 py-2 px-5">
          <div className="-mt-5 flex justify-end">
            <StatusBadge text={resource.connected ? 'Connected' : resource.status} colors={getStatusColors(resource.status)} />
          </div>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-1">
            <div className="flex items-center text-sm">
              <p className="mr-2 text-gray-500">Name:</p>
              <p className="mr-2 font-medium">{resource.name}</p>
            </div>
            <div className="flex items-center text-sm">
              <p className="mr-2 text-gray-500">IP:</p>
              <p className="font-medium">{resource.ip}</p>
            </div>
            {resource.password ? (
              <div className="flex items-center text-sm">
                <p className="mr-2 text-gray-500">Password:</p>
                <p className="font-medium">{resource.password}</p>
              </div>
            ) : null}
          </div>
          <Controls labId={labId} resource={resource} connect={connect} disconnect={disconnect} />
        </div>
      ))}
    </>
  );
};

export default ResourceCards;
